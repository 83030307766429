import React, { Component } from 'react'
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Table, Media, Form, FormGroup, Label, Input, Button, UncontrolledTooltip, Container } from "reactstrap";
import Swal from 'sweetalert2'
import moment from 'moment-timezone';


export default class TrendsTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected_period: { value: 'custom', selected_period_start: '', selected_period_end: '' },
            view: 'month'
        }
    };

    submitHandle(value) {
        switch (value) {
            case "view":
                this.props.callback(value, this.state.view)
                break;
            default:
                return
        }
    }

    componentDidMount() {
        let startOfMonth = moment().startOf('year').format('YYYY-MM-DD');
        let endOfMonth = moment().endOf('month').format('YYYY-MM-DD');
        let obj = this.state.selected_period
        obj.selected_period_start = startOfMonth;
        obj.selected_period_end = endOfMonth;
        this.setState({
            selected_period: obj
        }, () => {
            this.props.callback('view', this.state.view)
            this.props.callback('date', this.state.selected_period)
        })
    }

    render() {
        return (
            <Row className="mb-3">
                <Col xl={2} lg={2} md={2} sm={12} style={{ display: 'inline-flex', alignSelf: 'center' }}>
                    <span style={{ fontSize: 16 }}>วันที่เข้าออกประเทศไทย : </span>
                </Col>
                <Col xl={5} lg={5} md={5} sm={12}>
                    <Row>
                        <Col xl={5} lg={5} md={5} sm={12}>
                            <Row>
                                <Col xl={9} lg={9} md={9} sm={9}><input type="date" value={this.state.selected_period.selected_period_start} onChange={(e) => {
                                    console.log(e)
                                    let obj = this.state.selected_period
                                    obj.selected_period_start = e.target.value
                                    this.setState({
                                        selected_period: obj
                                    }, () => {
                                        console.log("เริ่มต้น ", this.state.selected_period)
                                        this.props.callback('date', this.state.selected_period)
                                    })
                                }} placeholder="เริ่มต้น" className="form-control" ></input></Col>


                            </Row>

                        </Col>
                        <Col xl={2} lg={2} md={2} sm={12} style={{ display: 'inline-flex', alignSelf: 'center' }}>-</Col>
                        <Col xl={5} lg={5} md={5} sm={12}>
                            <Row>
                                <Col xl={9} lg={9} md={9} sm={9}><input type="date" value={this.state.selected_period.selected_period_end} onChange={(e) => {
                                    let obj = this.state.selected_period
                                    obj.selected_period_end = e.target.value
                                    this.setState({
                                        selected_period: obj
                                    }, () => {
                                        console.log("สิ้นสุด ", this.state.selected_period)
                                        this.props.callback('date', this.state.selected_period)
                                    })
                                }} placeholder="สิ้นสุด" className="form-control" ></input></Col>


                            </Row>
                        </Col>

                    </Row>

                </Col>
                <Col xl={2} lg={2} md={2} sm={12} style={{ display: 'inline-flex', alignSelf: 'center' }}>
                    <span style={{ fontSize: 16 }}>การแสดงผลของกราฟ : </span>
                </Col>
                <Col xl={3} lg={3} md={3} sm={3} style={{ display: 'inline-flex', alignSelf: 'center' }}>
                    <button className={this.state.view === 'month' ? 'btn btn-outline-primary' : 'btn btn-outline-secondary'}
                        style={{ whiteSpace: 'nowrap', textAlign: 'center' }}
                        onClick={() => {
                            this.setState({
                                view: 'month',
                                // fetch_report: true
                            }, () => {
                                console.log('เลือกเดือน', this.state.view)
                                this.submitHandle('view')
                            })
                        }}>
                        เดือน
                    </button>
                    <button className={this.state.view === 'year' ? 'btn btn-outline-primary' : 'btn btn-outline-secondary'}
                        style={{ marginLeft: 20, whiteSpace: 'nowrap', textAlign: 'center' }}
                        onClick={() => {
                            this.setState({
                                view: 'year',
                                // fetch_report: true
                            }, () => {
                                console.log('เลือกปี', this.state.view)
                                this.submitHandle('view')
                            })
                        }}>
                        ปี
                    </button>
                </Col>
            </Row>
        )
    }
}
