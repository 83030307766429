import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Modal, Container, Button, Media, Input, Label, CardImg, CardText } from "reactstrap";
import { Link } from "react-router-dom";
import Nouislider from "nouislider-react";



import img1 from "../../../../assets/images/small/img-1.jpg";

export default class Modaladd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            modal_standard: false,
            modal_large: false,
            modal_xlarge: this.props.value,
            modal_small: false,
            modal_center: false,
            modal_scroll: false
        };
        this.tog_standard = this.tog_standard.bind(this);
        this.tog_xlarge = this.tog_xlarge.bind(this);
        this.tog_large = this.tog_large.bind(this);
        this.tog_small = this.tog_small.bind(this);
        this.tog_center = this.tog_center.bind(this);
        this.tog_scroll = this.tog_scroll.bind(this);
    }
    tog_standard() {
        this.setState(prevState => ({
            modal_standard: !prevState.modal_standard
        }));
        this.removeBodyCss();
    }
    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    tog_large() {
        this.setState(prevState => ({
            modal_large: !prevState.modal_large
        }));
        this.removeBodyCss();
    }
    tog_xlarge() {

        this.setState(prevState => ({
            modal_xlarge: !prevState.modal_xlarge
        }), () => { this.props.modalCallback('undefine', this.state.modal_xlarge) });
        this.removeBodyCss();
    }
    tog_small() {
        this.setState(prevState => ({
            modal_small: !prevState.modal_small
        }));
        this.removeBodyCss();
    }
    tog_center() {
        this.setState(prevState => ({
            modal_center: !prevState.modal_center
        }));
        this.removeBodyCss();
    }
    tog_scroll() {
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll
        }));
        this.removeBodyCss();
    }
    show() {
        this.setState({ visible: true });
    }
    hide() {
        this.setState({ visible: false });
    }




    render() {
        console.log(this.props)
        return (
            <React.Fragment>


                <Modal
                    size="xl"
                    isOpen={this.state.modal_xlarge}
                    toggle={this.tog_xlarge}
                >
                    <Row>
                        <Col sm={3} md={3} lg={3}>
                            <Card className="email-leftbar">
                                <Button type="button" color="primary" className="waves-effect waves-light" onClick={event => window.location.href = '/Joborderadd'}>
                                    เพิ่มสถานที่
                    </Button>


                                <form className="app-search d-none d-lg-block">
                                    <div className="position-relative">
                                        <input type="text" className="form-control" placeholder={"Search" + "..."} />
                                        <span className="bx bx-search-alt"></span>
                                    </div>
                                </form>
                                <div >
                                    <h5 className="font-size-14 mb-3">หมวดหมู่สถานที่</h5>
                                    <div className="custom-control custom-checkbox mt-2">
                                        <Input type="checkbox" value="0" className="custom-control-input" id="productdiscountCheck1" />
                                        <Label className="custom-control-label" htmlFor="productdiscountCheck1">ท่องเที่ยวธรรมขาติ</Label>
                                    </div>
                                    <div className="custom-control custom-checkbox mt-2">
                                        <Input type="checkbox" value="1" className="custom-control-input" id="productdiscountCheck2" />
                                        <Label className="custom-control-label" htmlFor="productdiscountCheck2">ร้านอาหาร</Label>
                                    </div>
                                    <div className="custom-control custom-checkbox mt-2">
                                        <Input type="checkbox" value="2" className="custom-control-input" id="productdiscountCheck3" defaultChecked />
                                        <Label className="custom-control-label" htmlFor="productdiscountCheck3">ท่องเที่ยวสายบุญ</Label>
                                    </div>

                                </div>
                                <hr></hr>
                                <div>
                                    <h5 className="font-size-14 mb-4">รองรับนักท่องเที่ยว</h5>
                                    <br />
                                    <Nouislider range={{ min: 0, max: 600 }} tooltips={true} start={[100, 500]} connect />
                                </div>

                            </Card>
                        </Col>
                        <Col sm={9} md={9} lg={9}>
                            <Label>{this.props.modalType}</Label>
                            <Row>
                                <Col sm={4} md={4} lg={4} style={{ marginTop: 15 }}>
                                    <Card>
                                        <CardImg top className="img-fluid" src={img1} alt="Skote" />
                                        <CardBody>
                                            <CardTitle className="mt-0">เกาะสเม็ด</CardTitle>
                                            <Row>
                                                <Col sm={6} md={6} lg={6}>

                                                    <h6>ssssssss</h6>


                                                </Col>
                                                <Col sm={6} md={6} lg={6}>

                                                    <h6>ssssssss</h6>

                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={6} md={6} lg={6}>

                                                    <h6>ssssssss</h6>


                                                </Col>
                                                <Col sm={6} md={6} lg={6}>

                                                    <h6>ssssssss</h6>

                                                </Col>
                                            </Row>
                                            <Link to="#" className="btn btn-primary waves-effect waves-light">Button</Link>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col sm={4} md={4} lg={4} style={{ marginTop: 15 }}>
                                    <Card>
                                        <CardImg top className="img-fluid" src={img1} alt="Skote" />
                                        <CardBody>
                                            <CardTitle className="mt-0">เกาะสเม็ด</CardTitle>
                                            <Row>
                                                <Col sm={6} md={6} lg={6}>

                                                    <h6>ssssssss</h6>


                                                </Col>
                                                <Col sm={6} md={6} lg={6}>

                                                    <h6>ssssssss</h6>

                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={6} md={6} lg={6}>

                                                    <h6>ssssssss</h6>


                                                </Col>
                                                <Col sm={6} md={6} lg={6}>

                                                    <h6>ssssssss</h6>

                                                </Col>
                                            </Row>
                                            <Link to="#" className="btn btn-primary waves-effect waves-light">Button</Link>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col sm={4} md={4} lg={4} style={{ marginTop: 15 }}>
                                    <Card>
                                        <CardImg top className="img-fluid" src={img1} alt="Skote" />
                                        <CardBody>
                                            <CardTitle className="mt-0">เกาะล้าง</CardTitle>
                                            <Row>
                                                <Col sm={6} md={6} lg={6}>

                                                    <h6>ssssssss</h6>


                                                </Col>
                                                <Col sm={6} md={6} lg={6}>

                                                    <h6>ssssssss</h6>

                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={6} md={6} lg={6}>

                                                    <h6>ssssssss</h6>


                                                </Col>
                                                <Col sm={6} md={6} lg={6}>

                                                    <h6>ssssssss</h6>

                                                </Col>
                                            </Row>
                                            <Link to="#" className="btn btn-primary waves-effect waves-light">Button</Link>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>




                        </Col>
                    </Row>

                </Modal>

            </React.Fragment >
        )
    }
}
