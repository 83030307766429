import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Input,
  Button,
  Card,
  CardBody,
  NavItem,
  NavLink,
  Table,
  Label,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
  Pagination,
  PaginationItem,
  PaginationLink,
  FormGroup,
} from "reactstrap";
import { connect } from "react-redux";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import classnames from "classnames";
import img4 from "../../../assets/images/product/img-4.png";
import img7 from "../../../assets/images/product/img-7.png";
import { withTranslation } from "react-i18next";

import { API_ENDPOINT } from "../../../assets/api";
import Lottie from "react-lottie";
import * as loadingData from "../../Joborder/men-component/loading.json";

import Swal from "sweetalert2";
import TableUsers from "./TableUser/TableUsers";
import DataGrid, {
  Column,
  SearchPanel,
  FilterRow,
  HeaderFilter,
  GroupPanel,
  Pager,
  Paging,
  FilterPanel,
  FilterBuilderPopup,
  Scrolling, RemoteOperations, Grouping, Export
} from "devextreme-react/data-grid";
import CustomStore from 'devextreme/data/custom_store';
import UsersGrid from "./UsersGrid";
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loadingData.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};
class TableUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: "",
      filter: {
        name: "",
        license: "",
        type: "",
        expired: "0",
        submit: false
      },
      modal: false,
      isLoading: false,
      loadingData: false
    };
    this.togglemodal.bind(this);
    this.getUser.bind(this);
    this.auth = JSON.parse(localStorage.getItem("authToken"));
    this.Operation = this.Operation.bind(this);
    this.renderMyCommand = this.renderMyCommand.bind(this);
  }

  togglemodal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  async set_delete_users(users_id) {
    Swal.fire({
      title: "ต้องการลบ ?",
      text: "จะไม่สามารถยกเลิกการทำงานได้!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน!",
      cancelButtonText: "ยกเลิก",
    }).then(async (result) => {
      if (result.isConfirmed) {
        console.log(users_id);
        var token = localStorage.getItem("authToken");
        token = JSON.parse(token);
        var response = await fetch(API_ENDPOINT + "api/v2/web/users/delete", {
          method: "POST", // *GET, POST, PUT, DELETE, etc.
          headers: {
            "Content-Type": "application/json",
            Authorization: token.data.type + " " + token.data.token,
          },
          body: JSON.stringify({
            users_id: users_id,
          }),
        });
        response = await response.json();
        if (response.status == "success") {
          Swal.fire("สำเร็จ!", "เปลี่ยนสถานะผู้ใช้งานแล้ว", "success").then(
            () => {
              window.location.reload();
            }
          );
        } else {
          Swal.fire("ผิดพลาด!", "ไม่สามารถลบได้ ติดต่อผู้ดูแลระบบ", "error");
        }
      }
    });
  }

  async getUser() {
    var path = "devexpress/users?expired=" + this.state.filter.expired
    if (this.state.filter.type !== '') {
      path = path + "&&user_type=" + this.state.filter.type
    }
    if (this.state.filter.name !== "" || this.state.filter.license !== "") {
      path = path + "&&name=" + this.state.filter.name + '&&license=' + this.state.filter.license + '&&user_type=' + this.state.filter.type
    }
    var response = await fetch(API_ENDPOINT + path, {
      method: "GET"
    });
    var responseJson = await response.json();
    console.log(responseJson);
    this.setState({
      data: responseJson.data,
      isLoading: false,
    });
  }

  componentDidMount() {
    // this.getUser();
  }

  determine_status(data) {
    switch (data.data.status) {
      case 1:
        return (
          <Badge
            className={"font-size-12 badge-soft-success"}
            color={"success"}
            pill
          >
            ปกติ
          </Badge>
        );
      case 2:
        return (
          <Badge
            className={"font-size-12 badge-soft-warning"}
            color={"warning"}
            pill
          >
            ระงับใช้งาน
          </Badge>
        );
      case 3:
        return (
          <Badge
            className={"font-size-12 badge-soft-danger"}
            color={"danger"}
            pill
          >
            ลบ
          </Badge>
        );
    }
  }

  Operation(data) {
    return (
      <div style={{ alignSelf: "center", textAlign: "center" }}>
        <i
          onClick={() => {
            this.props.history.push({
              pathname: "/Userdetail/" + data.data.id,
              search: "?mode=write",
              // userDetail: data.data,
              // userData: this.auth,
              // mode: 'write'
            });
          }}
          style={{ color: "gray", cursor: "pointer" }}
          className="mdi mdi-pencil font-size-18 "
        ></i>

        {data.data.status != 3 ? (
          <i
            onClick={() => {
              this.set_delete_users(data.data.id);
            }}
            style={{ color: "gray", cursor: "pointer" }}
            className="mdi mdi-delete font-size-18 "
          ></i>
        ) : (
          ""
        )}
      </div>
    );
  }

  renderMyCommand(data) {
    return (
      <Link
        to={{
          pathname: "/Userdetail/" + data.data.id + "?" + "mode=read",
          // userDetail: data.data,
          // userData: this.auth,
          // mode: 'read'
        }}
      >
        <Button type="button" color="primary" className="btn-sm btn-rounded">
          View Details
        </Button>
      </Link>
    );
  }

  filterHandle(target, value) {
    let obj = this.state.filter
    if (target === 'clear') {
      obj.name = value
      obj.license = value
      obj.type = value
      obj.expired = 0
      document.getElementById('name').value = ""
      document.getElementById('license').value = ""
      this.setState({
        filter: obj
      }, () => {
        // this.getUser()
      })
    }
    else {

      obj[target] = value
      this.setState({
        filter: obj,
        loadingData: false
      }, () => {
        // console.log(this.state.filter)
      })
    }

  }



  render() {
    if (this.state.isLoading == true) {
      return <React.Fragment></React.Fragment>;
    } else {
      return (
        <React.Fragment>
          <div className="page-content">
            <Container fluid>
              <Row>
                <Col xs="12">
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Breadcrumbs title="User Management" breadcrumbItem={this.props.t("User Management")} />
                    <Link to={{ pathname: "/User/add" }} style={{ display: 'flex', alignItems: 'center' }}>
                      <button type="button" className="btn btn-primary waves-effect waves-light" style={{ width: '100%', backgroundColor: '#224AAC', borderColor: '#224AAC' }}>
                        <i class="fas fa-plus-circle mr-3"></i>
                        <span className="font-style-16"> {this.props.t("Add New User")}</span>
                      </button>
                    </Link>
                  </div>
                </Col>
              </Row>
              {/*Card Search*/}
              <Card className="card-shadow" style={{ marginTop: '1.5rem' }}>
                <CardBody>
                  <Row className="mb-4">
                    <Col xl={3} lg={6} md={6} sm={12} xs={12}>
                      <FormGroup className="mt-3 mb-0">
                        <Label>ค้นหาจากชื่อ / นามสกุล :</Label>
                        <Input type="text" id="name" className="form-control" placeholder="ชื่อ / นามสกุล"
                          onChange={(e) => {
                            if (typeof this.timeout !== 'undefined') {
                              try {
                                clearTimeout(this.timeout);
                              } catch (err) {

                              }
                            }
                            this.timeout = setTimeout(async () => {
                              this.filterHandle('name', e.target.value)
                            }, 2000);
                          }} />
                      </FormGroup>
                    </Col>
                    <Col xl={3} lg={6} md={6} sm={12} xs={12}>
                      <FormGroup className="mt-3 mb-0">
                        <Label>ค้นหาจากหมายเลขประจำตัว :</Label>
                        <Input type="text" id="license" className="form-control" placeholder="หมายเลขประจำตัว"
                          onChange={(e) => {
                            if (typeof this.timeout !== 'undefined') {
                              try {
                                clearTimeout(this.timeout);
                              } catch (err) {

                              }
                            }
                            this.timeout = setTimeout(async () => {
                              this.filterHandle('license', e.target.value)
                            }, 2000);
                          }} />
                      </FormGroup>
                    </Col>
                    <Col xl={3} lg={6} md={6} sm={12} xs={12}>
                      <FormGroup className="mt-3 mb-0">
                        <Label for="type">ประเภทผู้ใช้</Label>
                        <select className="custom-select"
                          id='type'
                          onChange={(e) => { this.filterHandle('type', e.target.value) }}
                          value={this.state.filter.type}
                        >
                          <option value="" >ทั้งหมด</option>
                          <option value="1">ผู้ดูแลระบบ</option>
                          <option value="2">มัคคุเทศก์</option>
                          <option value="3">ธุรกิจนำเที่ยว</option>
                          <option value="4">เจ้าหน้าที่กรมการท่องเที่ยว</option>
                          <option value="5">ตำรวจท่องเที่ยว</option>
                        </select>
                      </FormGroup>
                    </Col>
                    <Col xl={3} lg={6} md={6} sm={12} xs={12}>
                      <FormGroup className="mt-3 mb-0">
                        <Label for="kycselectcity-input">วันหมดอายุ</Label>
                        <select className="custom-select"
                          onChange={(e) => { this.filterHandle('expired', e.target.value) }}
                          value={this.state.filter.expired}
                        >
                          <option value="0">ไม่ระบุ</option>
                          <option value="1">ภายใน 1 เดือน</option>
                          <option value="3">ภายใน 3 เดือน</option>
                          <option value="6">ภายใน 6 เดือน</option>
                        </select>
                      </FormGroup>
                    </Col>
                  </Row>
                  <hr></hr>
                  <Row style={{ justifyContent: 'flex-end' }}>
                    <Col xl={2} lg={3} md={6} sm={12} xs={12} style={{ display: 'inline-flex', overflow: 'auto' }}>
                      {/* <FormGroup className="mb-0">
                            <Button className="" onClick={() => {
                              this.filterHandle('submit', '')
                            }} style={{ width: '100%', backgroundColor: '#224AAC', borderColor: '#224AAC' }}>{this.props.t('Search')}</Button>
                          </FormGroup> */}
                      {this.state.loadingData === true &&
                        <Row style={{ alignSelf: 'center' }}>
                          <Lottie options={defaultOptions} height={35} width={35} style={{ marginRight: 10 }} />
                          <span style={{ alignSelf: 'center' }}>กำลังค้นหา</span></Row>
                      }
                    </Col>
                    <Col xl={2} lg={3} md={6} sm={12} xs={12} >
                      <FormGroup className="mb-0">
                        <button className="mt-1r btn btn-primary waves-effect waves-light" onClick={() => {
                          this.filterHandle('clear', '')
                        }} style={{ width: '100%', background: 'transparent', borderColor: '#c2c2c2' }}>
                          <span className="font-style-16 cl-gray">{this.props.t('Clear')}</span></button>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Row>
                <Col xs="12">
                  <Card className="card-shadow">
                    <CardBody>
                      {/* <Row className="mb-2">
                                                <Col sm="12">
                                                    <Button type="button" color="success" className="btn-rounded waves-effect waves-light mb-2 mr-2"> ทั้งหมด </Button>
                                                    <Button type="button" color="secondary" className="btn-rounded waves-effect waves-light mb-2 mr-2"> ผู้่ดูแลระบบ</Button>
                                                    <Button type="button" color="secondary" className="btn-rounded waves-effect waves-light mb-2 mr-2"> ผู้นำเที่ยว</Button>
                                                    <Button type="button" color="secondary" className="btn-rounded waves-effect waves-light mb-2 mr-2"> บริษัทนำเที่ยว</Button>
                                                </Col>
                                            </Row> */}
                      {/* <Row className="mt-24">
                        <Col xl={10} lg={9} md={9} sm={8}></Col>
                        <Col xl={2} lg={3} md={3} sm={4}>
                          <Link
                            to={{
                              pathname: "/User/add",
                            }}
                          >
                            <Button
                              style={{
                                width: "100%",
                                backgroundColor: "#224AAC",
                                borderColor: "#224AAC",
                              }}
                            >
                              <i class="fas fa-plus-circle mr-3"></i>
                              {this.props.t("Add New User")}
                            </Button>
                          </Link>
                        </Col>
                      </Row> */}
                      <Row className="mb-4">
                        {/* 
                                    <div className="col-xl col-sm-6">
                                        <FormGroup className="mt-3 mb-0">
                                            <Label>Date :</Label>
                                            <DatePicker
                                                selected={this.state.startDate}
                                                onChange={this.handleChange}
                                                className="form-control"
                                                placeholderText="Select date"
                                            />
                                        </FormGroup>
                                    </div> */}

                        {/* <Col xl={3} lg={3} md={6} sm={12} xs={12}>
                                                    <FormGroup className="mt-3 mb-0">
                                                        <Label>FlightCode</Label>
                                                        <select className="form-control select2-search-disable">
                                                            <option value="BU" defaultValue>TG101</option>
                                                            <option value="SE">TG111</option>
                                                        </select>
                                                    </FormGroup>
                                                </Col> */}

                        <Col xl={3} lg={3} md={6} sm={12} xs={12}>
                          {/* <FormGroup className="mt-3 mb-0">
                                                        <Label>Status</Label>
                                                        <select className="form-control select2-search-disable">
                                                            <option value="CO" defaultValue>Completed</option>
                                                            <option value="PE">Pending</option>
                                                        </select>
                                                    </FormGroup> */}
                        </Col>
                        <Col xl={3} lg={3} md={6} sm={12} xs={12}></Col>
                      </Row>
                      {/* <Row>
                                                <Col sm="4">
                                                    <div className="search-box mr-2 mb-2 d-inline-block">
                                                        <div className="position-relative">
                                                            <Input type="text" className="form-control" placeholder="Search..." />
                                                            <i className="bx bx-search-alt search-icon"></i>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col sm="8">
                                                    <div className="text-sm-right">
                                                        <Link to={{ pathname: "/User/add" }} className="mr-3 text-primary">
                                                            <Button type="button" color="success" className="btn-rounded waves-effect waves-light mb-2 mr-2"><i class="fas fa-plus-circle mr-3"></i> Add New User</Button>
                                                        </Link>
                                                    </div>
                                                </Col>
                                            </Row> */}

                      <div>
                        <UsersGrid filter={this.state.filter}></UsersGrid>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>

          {/* <Modal isOpen={this.state.modal} role="dialog" autoFocus={true} centered={true} className="exampleModal" tabindex="-1" toggle={this.togglemodal}>
                        <div className="modal-content">
                            <ModalHeader toggle={this.togglemodal}>
                                Order Details
                                </ModalHeader >
                            <ModalBody>
                                <p className="mb-2">Product id: <span className="text-primary">#SK2540</span></p>
                                <p className="mb-4">Billing Name: <span className="text-primary">Neal Matthews</span></p>

                                <div className="table-responsive">
                                    <Table className="table table-centered table-nowrap">
                                        <thead>
                                            <tr>
                                                <th scope="col">Product</th>
                                                <th scope="col">Product Name</th>
                                                <th scope="col">Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">
                                                    <div>
                                                        <img src={img7} alt="" className="avatar-sm" />
                                                    </div>
                                                </th>
                                                <td>
                                                    <div>
                                                        <h5 className="text-truncate font-size-14">Wireless Headphone (Black)</h5>
                                                        <p className="text-muted mb-0">$ 225 x 1</p>
                                                    </div>
                                                </td>
                                                <td>$ 255</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">
                                                    <div>
                                                        <img src={img4} alt="" className="avatar-sm" />
                                                    </div>
                                                </th>
                                                <td>
                                                    <div>
                                                        <h5 className="text-truncate font-size-14">Hoodie (Blue)</h5>
                                                        <p className="text-muted mb-0">$ 145 x 1</p>
                                                    </div>
                                                </td>
                                                <td>$ 145</td>
                                            </tr>
                                            <tr>
                                                <td colspan="2">
                                                    <h6 className="m-0 text-right">Sub Total:</h6>
                                                </td>
                                                <td>
                                                    $ 400
                                                    </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2">
                                                    <h6 className="m-0 text-right">Shipping:</h6>
                                                </td>
                                                <td>
                                                    Free
                                                    </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2">
                                                    <h6 className="m-0 text-right">Total:</h6>
                                                </td>
                                                <td>
                                                    $ 400
                                                    </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button type="button" color="secondary" onClick={this.togglemodal}>Close</Button>

                            </ModalFooter>
                        </div>
                    </Modal> */}
        </React.Fragment>
      );
    }
  }
}
const mapStatetoProps = (state) => {
  return {
    ...state.Layout,
    login: state.Login,
  };
};
export default connect(mapStatetoProps, {})(withTranslation()(TableUser));
