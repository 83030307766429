import React, { Component } from 'react';
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Table, Button, CardImg, CardText, Modal, CardDeck, CardImgOverlay, Image } from "reactstrap";
import { Link } from "react-router-dom";
import ReactReadMoreReadLess from "react-read-more-read-less";
import img5 from "../../../../assets/images/small/img-17.jpg";
import tarad from "../../../../assets/images/small/tarad.jpg"
import StarRatings from 'react-star-ratings';
import Grouptrips from "../../Grouptrips";
// import temple from "../../../../assets/images/small/Group233.svg"
// import shop from "../../../../assets/images/small/Group232.svg"
// import spa from "../../../../assets/images/small/Group231.svg"
// import river from "../../../../assets/images/small/005-river.svg"
// import food from "../../../../assets/images/small/food.svg"
import RatingTooltip from "react-rating-tooltip";

// import iconfood from "../../../../assets/images/icon/food.svg"
// import iconriver from "../../../../assets/images/icon/river.svg"
// import iconshop from "../../../../assets/images/icon/shop.svg"
// import icontemple from "../../../../assets/images/icon/temple.svg"
import IMAGE_ENDPOINT from '../../../../assets/api'


class TripModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tripInfo: this.props.tripInfo,
            modal_scroll: this.props.show,
            default: "",
        }
        console.log(this.props)
        this.tog_scroll = this.tog_scroll.bind(this);
    }

    tog_scroll() {
        console.log(this.state.modal_scroll)
        this.setState({
            modal_scroll: !this.state.modal_scroll
        });
        this.removeBodyCss();
    }

    removeBodyCss() {
        document.body.classList.add("no_padding");
    }


    render() {
        console.log(this.state.tripInfo)
        console.log(this.state.tripInfo.tripInfo[0])
        return (
            <React.Fragment>

                <Modal
                    style={{ maxWidth: '80%' }}
                    isOpen={this.state.modal_scroll}
                    toggle={this.tog_scroll}
                    scrollable={true}
                >


                    <Row>
                        <Col >
                            <CardImg style={{ height: '14rem', width: '100%', objectFit: 'cover' }} src={tarad} />

                            <CardImgOverlay style={{ background: 'rgba(0, 0, 0, 0.4)' }}>

                                <Row>
                                    <Col xl={12} lg={12} md={12} sm={12} xs={12} style={{ textAlign: 'end' }}>
                                        {/* <h1 className="text-white mt-12" >{this.state.tripInfo.name}</h1> */}
                                    </Col>
                                </Row>
                                <Row style={{ paddingTop: '10rem', display: 'flex', padding: '1rem' }}>
                                    <Col xl={12} lg={12} md={12} sm={12} xs={12} >
                                        <h1 className="text-white mt-12" >{this.state.tripInfo.name}</h1>
                                    </Col>
                                </Row>

                                {/* <button
                                            type="button"
                                            onClick={() =>
                                                this.setState({ modal_scroll: false })
                                            }
                                            className="close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button> */}

                            </CardImgOverlay>

                        </Col>


                    </Row>
                    <div className="modal-body">
                        <Row style={{ display: 'flex', alignItems: 'center', padding: '1rem' }}>
                            <Col xl={2} lg={2} sm={4} md={2} xs={12} style={{ borderRight: 'solid 1px rgba(0, 0, 0, 0.1)' }} >
                                <Row>
                                    <Col xl={4} lg={4} sm={4} md={4} xs={4} style={{ alignSelf: 'center' }}>
                                        <div style={{ color: '#224AAC' }}>
                                            <i className="fas fa-calendar-week fa-3x" ></i>
                                        </div>
                                    </Col>
                                    <Col xl={8} lg={8} sm={8} md={8} xs={8} >
                                        <Row>
                                            <h5 style={{ color: 'black', fontWeight: 'bold' }}>ระยะเวลา</h5>
                                        </Row>
                                        <Row>
                                            <h6 style={{ color: '#224AAC' }}> {this.state.tripInfo.tripInfo.length} วัน</h6>
                                        </Row>
                                    </Col>
                                </Row>


                            </Col>
                            <Col xl={2} lg={2} sm={4} md={2} xs={12} style={{ borderRight: 'solid 1px rgba(0, 0, 0, 0.1)' }}>
                                {/* <CardText style={{ color: '#224AAC' }}><i className="fas fa-map-marker-alt"></i> {this.state.tripInfo.tripProvinceCount} จังหวัด</CardText> */}
                                <Row >
                                    <Col xl={4} lg={4} sm={4} md={4} xs={4} style={{ alignSelf: 'center' }} >
                                        <div style={{ color: '#224AAC' }}>
                                            <i className="fas fa-map-marker-alt fa-3x"></i>
                                        </div>
                                    </Col>
                                    <Col xl={8} lg={8} sm={8} md={8} xs={8} >
                                        <Row>
                                            <h5 style={{ color: 'black', fontWeight: 'bold' }}>จังหวัด</h5>
                                        </Row>
                                        <Row>
                                            <h6 style={{ color: '#224AAC' }}>{this.state.tripInfo.province}</h6>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xl={3} lg={3} sm={4} md={3} xs={12} >
                                {/* <CardText style={{ color: '#224AAC' }}><i className="fas fa-user-friends"></i> {this.state.tripInfo.tripMinMax.split("|")[0]}-{this.state.tripInfo.tripMinMax.split("|")[1]} คน</CardText> */}
                                {/* <Row style={{ borderRight: 'solid 1px rgba(0, 0, 0, 0.1)' }}>
                                    <Col xl={4} lg={4} sm={4} md={4} xs={4} style={{ alignSelf: 'center', fontWeight: 'bold' }}>
                                        <div style={{ color: '#224AAC' }}>
                                            <i className="fas fa-user-friends fa-3x"></i>
                                        </div>
                                    </Col>
                                    <Col xl={8} lg={8} sm={8} md={8} xs={8} >
                                        <Row>
                                            <h5 style={{ color: 'black', fontWeight: 'bold' }}>นักท่องเที่ยวแนะนำ</h5>
                                        </Row>
                                        <Row>
                                            <h6 style={{ color: '#224AAC' }}>{this.state.tripInfo.tripMinMax.split("|")[0]}-{this.state.tripInfo.tripMinMax.split("|")[1]} คน</h6>
                                        </Row>
                                    </Col>
                                </Row> */}
                            </Col>
                        </Row>

                        <hr></hr>
                        {/* <Row style={{ padding: '1rem' }}>
                            <Col xl={3} lg={4} sm={6} md={4} xs={5} >
                                <h4 style={{ fontWeight: 'bold' }}>กิจกรรมการท่องเที่ยว</h4>
                            </Col>
                            <Col xl={4} lg={8} sm={6} md={8} xs={7}>
                                <Row className='text-center'>
                                    <Col xl={2} lg={2} sm={3} md={2} xs={3} >  <CardImg src={iconfood} alt="" className="" style={{ width: '2rem' }} /></Col>
                                    <Col xl={2} lg={2} sm={3} md={2} xs={3} >  <CardImg src={iconriver} alt="" className="" style={{ width: '2rem' }} /></Col>
                                    <Col xl={2} lg={2} sm={3} md={2} xs={3} >  <CardImg src={iconshop} alt="" className="" style={{ width: '2rem' }} /></Col>
                                    <Col xl={2} lg={2} sm={3} md={2} xs={3} >  <CardImg src={icontemple} alt="" className="" style={{ width: '2rem' }} /></Col>
                                </Row>
                            </Col>

                        </Row> */}
                        <Row style={{ borderTop: 'solid 6px #224AAC', padding: '1rem' }} >
                            <Col xl={12}>

                                <Row>
                                    <Col xl={1} lg={1} md={1} sm={1} xs={2} >
                                        <div style={{ textAlign: 'center' }}><i class="far fa-file-alt text-primary fa-2x" ></i></div>
                                    </Col>
                                    <Col xl={2} lg={2} md={3} sm={4} xs={5} style={{ marginLeft: '0rem' }}>
                                        <h3 className="mb-2" style={{ fontWeight: 'bold' }}> รายละเอียด</h3>
                                    </Col>
                                    <Col xl={9} lg={9} md={8} sm={7} xs={5}>
                                        <hr style={{ borderTop: '2px solid rgba(0, 0, 0, 0.1)' }}></hr>
                                    </Col>
                                </Row>
                                <Row style={{ paddingTop: '1rem' }}>
                                    <Col xl={1} lg={1} md={1} sm={1} xs={1}></Col>
                                    <Col xl={10} lg={10} md={10} sm={10} xs={10}>
                                        <h5 className="mb-2" style={{ textAlign: 'justify' }}>
                                            <ReactReadMoreReadLess
                                                charLimit={1000}
                                                readMoreText={
                                                    <Row>
                                                        <Col xl={12} lg={12} md={12} sm={12} xs={12} style={{ textAlign: 'center' }}>
                                                            Read more ▼
                                                        </Col>
                                                    </Row>}
                                                readLessText={
                                                    <Row>
                                                        <Col xl={12} lg={12} md={12} sm={12} xs={12} style={{ textAlign: 'center' }}>
                                                            Read less ▲
                                                        </Col>
                                                    </Row>}
                                                readMoreClassName="read-more-less--more"
                                                readLessClassName="read-more-less--less"
                                            >
                                                {this.state.tripInfo.trip_details}
                                            </ReactReadMoreReadLess>
                                        </h5>
                                    </Col>
                                    <Col xl={1} lg={1} md={1} sm={1} xs={1}></Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row style={{ padding: '1rem', borderTop: 'solid 6px #224AAC' }} >
                            <Col xl={12}>

                                <Row className="mb-3">
                                    <Col xl={1} lg={1} md={1} sm={1} xs={2}>
                                        <div style={{ textAlign: 'center' }}><i class="far fa-file-alt text-primary fa-2x" ></i></div>
                                    </Col>
                                    <Col xl={2} lg={2} md={3} sm={4} xs={5} style={{ marginLeft: '0rem' }}>
                                        <h3 className="mb-2" style={{ fontWeight: 'bold' }}> ข้อมูลการท่องเที่ยว</h3>
                                    </Col>
                                    <Col xl={9} lg={9} md={8} sm={7} xs={5}>
                                        <hr style={{ borderTop: '2px solid rgba(0, 0, 0, 0.1)' }}></hr>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <Grouptrips tripInfo={this.state.tripInfo.tripInfo} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={() => this.setState({ modal_scroll: false })}>ปิดหน้าต่าง</button>
                            {/* <Link to={{
                                pathname: '/Joborderaddjb',
                                tripInfo: this.state.tripInfo
                            }}>
                                <button type="button" className="btn btn-primary">เลือกแผนการท่องเที่ยวนี้สำหรับสร้าง Job Order</button>
                            </Link> */}

                        </div>

                    </div>
                </Modal>
            </React.Fragment>
        );
    }
}

export default TripModal;