import React, { Component, useEffect } from 'react';
import AnyChart from 'anychart-react'

const DoughnutChart = (props) => {
    useEffect(() => {
        console.log(props)
    }, [props]);



    return (
         
            <AnyChart
            
                width={'100%'}
                height={200}
                type='pie'
                innerRadius={'75%'}
                // insideLabelsOffset={"180%"}
                labels={{
                    position:'inside',
                    fontColor:"#000000"
                }}
                interactivity={{
                    selectionMode:'none'
                }}
              
                overlapMode={true}
                legend={false}
                data={
                   props.data
                }

                // title={''}
            />
      
    )
}

export default DoughnutChart;