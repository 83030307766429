import React, { Component } from 'react'
import classnames from 'classnames';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardImg,
    Modal,
    ModalHeader,
    ModalBody,
    TabContent,
    TabPane,
    NavItem,
    NavLink,
    Label,
    Button,
    Input,
    Form,
    FormGroup,
    Progress,
    Table,
    CardTitle,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Collapse,
    CardHeader,
    ModalFooter
} from "reactstrap";
import moment from 'moment-timezone';
import Swal from 'sweetalert2'
import { Link } from "react-router-dom";
import { API_ENDPOINT } from "../../../assets/api";
import PlacesKanban from "./PlacesKanban";
import Dropzone from 'react-dropzone';
// import sun from "../../../assets/images/icon/sun.svg"
// import night from "../../../assets/images/icon/night.svg"
// import cloudy from "../../../assets/images/icon/cloudy.svg"
// import pin from "../../../assets/images/icon/pin-drop.svg"
// import file from "../../../assets/images/icon/file-text.svg"
import { IMAGE_ENDPOINT } from "../../../assets/api";
import * as loadingData from "./loading.json";
import FadeIn from "react-fade-in";

import Lottie from "react-lottie";

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingData.default,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};


export default class AddJoborder extends Component {
    constructor(props) {
        super(props)

        this.state = {

            //initial 
            mode: "",
            joborder_code: "",
            joborder_id: -1,
            tour_id: 0,
            tour_fetch_ready: false,
            tour_detail: {},
            agency_license: "",
            agency_name: "",

            step: 1,
            progress: 25,
            step_1: {
                created_at: moment().format('YYYY-MM-DD'),
                external_agencys: '',
                external_agencys_code: '',
                joborder_start: '',
                joborder_stop: '',
                // flights_external: [],
                flights_internal: [],
                inbound_type: '',
                inbound_no: '',
                inbound_date: '',
                inbound_country: '',
                outbound_type: '',
                outbound_no: '',
                outbound_date: '',
                outbound_country: '',
            },
            step_2: {
                days: [],
                places_modal_is_open: false,
                places_period_modal_is_open: false,
                guides_modal_is_open: false,
                fetch_guides: false,
                fetch_places: true,
                name_filter: true,
                guides_fee: 0,
                guides: [],
                guides_skip: 0,
                guides_take: 10,
                guides_name_filter: '',
                guides_selected_day: 0,
                searching: false,
                places: [],
                places_name_filter: '',
                places_selected: {},
                places_selected_day: 0,
                places_selected_daypart: [],
                places_selected_category: [],
                places_categorys: [],
                pageSkip: 0,
                pageTake: 4,
            },
            step_3: {
                tourists_adults: 0,
                tourists_children: 0,
                tourists_followers: 0,
                tourists_all: 0,
                tourists: []
            },
            summary: {}
        }
        document.title = "เพิ่มใบสั่งงาน"
        console.log(this.state.step_2)

        console.log("========his.state.step_1")
        console.log(this.state.step_1)
    }
    async componentDidMount() {

        document.getElementById("joborder_navagation").classList.add("active");
        // if (this.props.location.tripInfo == undefined) {
        //     this.props.history.push('/Trip')
        // }
        // if (tour_id === null) {
        //     this.props.history.push('/Trip')
        // }

        const joborder_code = new URLSearchParams(this.props.location.search).get("joborder_code")
        console.log("======joborder_code=====")
        console.log(joborder_code)
        await this.setState({
            joborder_code: joborder_code
        })
        if (this.state.joborder_code == null) {
            const tour_id = new URLSearchParams(this.props.location.search).get("tour_id")
            console.log("======tour_id=====")
            console.log(tour_id)
            await this.setState({
                mode: "สร้าง",
                tour_id: tour_id
            })

            this.get_tour_detail(this.state.tour_id)
            this.get_places_category()
            this.get_agency_detail()
        } else {
            await this.setState({
                mode: "แก้ไข"
            })

            this.get_joborder_detail(this.state.joborder_code)
            this.get_places_category()
        }
    }

    async get_joborder_detail(joborder_code) {
        var auth = JSON.parse(await localStorage.getItem('authToken'))
        var lang = 'th'
        var response = await fetch(API_ENDPOINT + 'api/v2/web/joborder/edit/prepare', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: auth.data.type + ' ' + auth.data.token,
            },
            body: JSON.stringify({
                joborder_code: joborder_code,
                lang: lang
            })
        });
        var responseJson = await response.json();
        console.log(responseJson)
        var state = this.state
        state.joborder_id = responseJson.joborder.id
        state.agency_name = responseJson.joborder.agencys[0].name
        state.agency_license = responseJson.joborder.agencys[0].license

        state.step_1.code = responseJson.joborder.code
        state.step_1.external_agencys = responseJson.joborder.external_agencys
        state.step_1.external_agencys_code = responseJson.joborder.external_agencys_code
        state.step_1.joborder_start = moment(new Date(responseJson.joborder.joborder_start_date)).format('YYYY-MM-DD')
        state.step_1.joborder_stop = moment(new Date(responseJson.joborder.joborder_stop_date)).format('YYYY-MM-DD')
        state.step_1.agency_name = responseJson.joborder.agencys[0].name
        state.step_1.agency_license = responseJson.joborder.agencys[0].license
        state.step_1.created_at = moment(new Date(responseJson.joborder.created_at)).format('YYYY-MM-DD')

        state.step_1.inbound_type = responseJson.joborder.inbound_type
        state.step_1.inbound_no = (responseJson.joborder.inbound_type === '5') ? '' : responseJson.joborder.inbound_no
        state.step_1.inbound_date = (responseJson.joborder.inbound_type === '5') ? '' : moment(new Date(responseJson.joborder.inbound_date)).format('YYYY-MM-DD')
        state.step_1.inbound_country = (responseJson.joborder.inbound_type === '5') ? '' : responseJson.joborder.inbound_country
        state.step_1.outbound_type = responseJson.joborder.outbound_type
        state.step_1.outbound_no = (responseJson.joborder.outbound_type === '5') ? '' : responseJson.joborder.outbound_no
        state.step_1.outbound_date = (responseJson.joborder.outbound_type === '5') ? '' : moment(new Date(responseJson.joborder.outbound_date)).format('YYYY-MM-DD')
        state.step_1.outbound_country = (responseJson.joborder.outbound_type === '5') ? '' : responseJson.joborder.outbound_country

        for (var flight of responseJson.joborder.flights_internal) {
            state.step_1.flights_internal.push({
                flight_departure_date: moment(new Date(flight.flight_depart)).format('YYYY-MM-DD'),
                flight_no: flight.flight_no,
                flight_from: flight.flight_from,
                flight_departure_datetime: new Date(flight.flight_depart).toISOString().slice(0, 16),
                flight_to: flight.flight_to,
                flight_arrival_datetime: new Date(flight.flight_arrival).toISOString().slice(0, 16)
            })
        }

        state.step_2.guides_fee = responseJson.joborder.guides_fee

        state.step_3.tourists_adults = responseJson.joborder.tourists_adults
        state.step_3.tourists_children = responseJson.joborder.tourists_children
        state.step_3.tourists_followers = responseJson.joborder.tourists_followers
        state.step_3.tourists_all = responseJson.joborder.tourists_all

        var days = []
        var placeordering = 1
        for (var response_day of responseJson.joborder.days) {
            days.push({
                day: response_day.dayno,
                dayparts: [],
            })
            let dayindex = days.map(day => day.day).indexOf(response_day.dayno)
            for (var response_daypart of response_day.day_details) {
                let daypartindex = days[dayindex].dayparts.map(daypart => daypart.daypart).indexOf(response_daypart.daypart)
                if (daypartindex == -1) {
                    days[dayindex].dayparts.push({
                        daypart: response_daypart.daypart,
                        places: []
                    })
                    daypartindex = days[dayindex].dayparts.map(daypart => daypart.daypart).indexOf(response_daypart.daypart)
                }
                days[dayindex].dayparts[daypartindex].places.push({
                    place_id: response_daypart.place_id,
                    place_img: response_daypart.place.photo,
                    place_name: response_daypart.place.name,
                    place_detail: response_daypart.place.detail,
                    place_category_name: response_daypart.place.category_name,
                    place_ordering: placeordering,
                    place_category_icon: response_daypart.place.category_icon

                })
                placeordering++
                if (typeof days[dayindex].guides == 'undefined') {
                    days[dayindex].guides = []
                }
                if (response_daypart.guides.length > 0) {
                    console.log(response_daypart.guides.length)
                    for (var tmp_guide of response_daypart.guides) {
                        let guideindex = days[dayindex].guides.map(guide => guide.guide_id).indexOf(tmp_guide.guide_id)
                        if (guideindex == -1) {
                            days[dayindex].guides.push({
                                guide_id: tmp_guide.guide_id,
                                fname: tmp_guide.guide.guide_info.fname,
                                lname: tmp_guide.guide.guide_info.lname,
                                license: tmp_guide.guide.license,
                                address: tmp_guide.guide.address,
                                photo: tmp_guide.guide.photo
                            })
                        }
                    }
                }

            }
        }

        for (var tourist of responseJson.joborder.tourists) {
            state.step_3.tourists.push({
                passport: tourist.passport,
                passport_file: tourist.passport_file,
                prefix: tourist.prefix,
                firstname: tourist.fname,
                lastname: tourist.lname,
                date_of_birth: moment(new Date(tourist.dob)).format('YYYY-MM-DD'),
                nation: tourist.origincountry,
                flight: tourist.arrivalflight,
                flight_no: tourist.arrivalflight
            })
        }
        this.setState({
            ...state,
            fetch_joborder: false,
            step_2: {
                ...state.step_2,
                days: days,
            }
        }, () => {
            console.log(days)
            console.log('finish load')
        })
    }
    async get_tour_detail(tour_id) {
        var auth = JSON.parse(await localStorage.getItem('authToken'))
        let obj = {
            tour_id: tour_id,
            lang: 'th'
        }

        var response = await fetch(API_ENDPOINT + 'api/v2/web/joborder/tour/get', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: auth.data.type + ' ' + auth.data.token,

            },
            body: JSON.stringify(obj)

        });
        var responseJson = await response.json();
        for (var day of responseJson.detail.days) {
            day.collapse_open = false
        }
        responseJson.detail.days.sort((a, b) => (a.day > b.day) ? 1 : -1)
        console.log(responseJson)
        for (var day of responseJson.detail.days) {
            day.dayparts.sort((a, b) => (a.daypart > b.daypart) ? 1 : -1)
            for (var daypart of day.dayparts) {
                daypart.places.sort((a, b) => (a.place_ordering > b.place_ordering) ? 1 : -1)
            }
        }

        // for(var )
        this.setState({
            ...this.state,
            step_2: {
                ...this.state.step_2,
                days: responseJson.detail.days
            }
        }, () => {
            console.log(this.state)
        })
    }

    async get_places_category() {
        var auth = JSON.parse(await localStorage.getItem('authToken'))
        var lang = 'th'
        var response = await fetch(API_ENDPOINT + 'api/v2/web/place/category/get?lang=' + lang, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: auth.data.type + ' ' + auth.data.token,
            }

        });
        var responseJson = await response.json();
        var places_categorys = responseJson
        this.setState({
            ...this.state,
            step_2: {
                ...this.state.step_2,
                places_categorys: places_categorys
            }
        })
        console.log(responseJson)
    }

    async get_agency_detail() {
        var auth = JSON.parse(await localStorage.getItem('authToken'))
        var lang = 'th'
        var response = await fetch(API_ENDPOINT + 'api/v2/web/agency/get/' + auth.users.agency_id, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: auth.data.type + ' ' + auth.data.token,
            }
        });
        var responseJson = await response.json();
        var agency = responseJson
        this.setState({
            agency_license: agency.license,
            agency_name: agency.name
        })
        console.log(responseJson)
    }

    validate_step_1() {
        // if (this.state.step_1.external_agencys == "") {
        //     Swal.fire(
        //         'เตือน !',
        //         'ไม่พบข้อมูลเอเจนซี่ต่างประเทศ กรุณาระบุข้อมูลเอเจนซี่ต่างประเทศ',
        //         'error'
        //     )
        //     return false
        // }
        // if (this.state.step_1.external_agencys_code == "") {
        //     Swal.fire(
        //         'เตือน !',
        //         'ไม่พบข้อมูลรหัสทัวร์ กรุณาระบุข้อมูลรหัสทัวร์',
        //         'error'
        //     )
        //     return false
        // }
        if (this.state.step_1.joborder_start == "") {
            Swal.fire(
                'เตือน !',
                'ไม่พบข้อมูลวันที่เริ่มใบงาน กรุณาระบุข้อมูลวันที่เริ่มใบงาน',
                'error'
            )
            return false
        }
        if (this.state.step_1.joborder_stop == "") {
            Swal.fire(
                'เตือน !',
                'ไม่พบข้อมูลวันที่สิ้นสุดใบงาน กรุณาระบุข้อมูลวันที่สิ้นสุดใบงาน',
                'error'
            )
            return false
        }


        // if (this.state.step_1.flights_external.length == 0) {
        //     Swal.fire(
        //         'เตือน !',
        //         'ไม่พบข้อมูลเที่ยวบิน กรุณาระบุเที่ยวบินเข้าประเทศก่อน',
        //         'error'
        //     )
        // }
        // for (var i = 0; i < this.state.step_1.flights_external.length; i++) {
        //     if (this.state.step_1.flights_external[i].flight_departure_date == '') {
        //         Swal.fire(
        //             'เตือน !',
        //             'ไม่พบข้อมูลวันที่เดินทางออกจากประเทศต้นทาง กรุณาระบุข้อมูลวันที่เดินทางออกจากประเทศต้นทาง',
        //             'error'
        //         )
        //         return false
        //     }

        //     if (this.state.step_1.flights_external[i].flight_no == '') {
        //         Swal.fire(
        //             'เตือน !',
        //             'ไม่พบข้อมูลหมายเลขเที่ยวบิน กรุณาระบุข้อมูลหมายเลขเที่ยวบิน',
        //             'error'
        //         )
        //         return false
        //     }
        //     if (this.state.step_1.flights_external[i].flight_from == '') {
        //         Swal.fire(
        //             'เตือน !',
        //             'ไม่พบข้อมูลเที่ยวบินจากประเทศต้นทาง กรุณาระบุข้อมูลเที่ยวบินจากประเทศต้นทาง',
        //             'error'
        //         )
        //         return false
        //     }
        //     if (this.state.step_1.flights_external[i].flight_departure_datetime == '') {
        //         Swal.fire(
        //             'เตือน !',
        //             'ไม่พบข้อมูลวันเวลาที่เดินทางออกจากประเทศต้นทาง กรุณาระบุข้อมูลวันเวลาที่เดินทางออกจากประเทศต้นทาง',
        //             'error'
        //         )
        //         return false
        //     }
        //     if (this.state.step_1.flights_external[i].flight_to == '') {
        //         Swal.fire(
        //             'เตือน !',
        //             'ไม่พบข้อมูลปลายทางของเที่ยวบินเข้าประเทศ กรุณาระบุข้อมูลปลายทางของเที่ยวบินเข้าประเทศ ',
        //             'error'
        //         )
        //         return false
        //     }
        //     if (this.state.step_1.flights_external[i].flight_arrival_datetime == '') {
        //         Swal.fire(
        //             'เตือน !',
        //             'ไม่พบข้อมูลวันเวลาที่เดินทางเข้าสู่ประเทศปลายทาง กรุณาระบุข้อมูลวันเวลาที่เดินทางเข้าสู่ประเทศปลายทาง',
        //             'error'
        //         )
        //         return false
        //     }
        // }
        return true
    }
    validate_step_3() {
        if (this.state.step_3.tourists.length == 0) {
            Swal.fire(
                'เตือน !',
                'ไม่พบข้อมูลนักท่องเที่ยว กรุณาใส่ข้อมูลนักท่องเที่ยว ',
                'error'
            )
            return false
        }
        return true
    }
    validate_step_2() {
        var days = this.state.step_2.days
        var found_guides = false
        for (var day of days) {
            if (typeof day.guides != 'undefined' && day.guides.length > 0) {
                found_guides = true
            }
        }

        if (found_guides == false) {
            Swal.fire(
                'เตือน !',
                'ไม่พบข้อมูลไกด์ กรุณาเพิ่มไกด์อย่างน้อย 1 วัน',
                'error'
            )
            return false
        }

        for (var day of days) {
            var found_places = false
            for (var daypart of day.dayparts) {
                if (daypart.places.length > 0) {
                    found_places = true
                }
            }
            if (found_places == false) {
                Swal.fire(
                    'เตือน !',
                    'ไม่พบข้อมูลสถานที่ท่องเที่ยวในวันที่ ' + day.day + ' กรุณาเพิ่มสถานที่ท่องเที่ยว',
                    'error'
                )
                return false
            }
        }

        return true
    }
    set_step(step) {
        switch (step) {
            case 1:
                this.setState({
                    progress: 25,
                    step: step
                })
                break;
            case 2:
                if (this.validate_step_1()) {
                    this.setState({
                        progress: 50,
                        step: step
                    })
                }
                break;
            case 3:

                if (this.validate_step_2()) {
                    this.setState({
                        progress: 75,
                        step: step
                    })
                }

                break;
            case 4:
                if (this.validate_step_1() && this.validate_step_2() && this.validate_step_3()) {
                    let obj = this.state.step_3
                    obj.tourists_children = this.state.step_3.tourists.filter(item => moment().diff(item.date_of_birth, 'years') < 15).length
                    obj.tourists_adults = this.state.step_3.tourists.filter(item => moment().diff(item.date_of_birth, 'years') > 15).length
                    obj.tourists_all = this.state.step_3.tourists.length
                    this.setState({
                        progress: 100,
                        step: step
                    }, () => {

                    })
                }
                break;
        }
    }
    async summary_joborder() {

        // var flights_external = this.state.step_1.flights_external
        var flights_internal = this.state.step_1.flights_internal
        var joborder_start = this.state.step_1.joborder_start
        var joborder_stop = this.state.step_1.joborder_stop
        var tourists = this.state.step_3.tourists

        joborder_start = moment(joborder_start, 'YYYY-MM-DD').tz("Asia/Bangkok").unix()
        joborder_stop = moment(joborder_stop, 'YYYY-MM-DD').tz("Asia/Bangkok").unix()

        for (var tourist of tourists) {
            tourist.before_date_of_birth = tourist.date_of_birth
            tourist.date_of_birth = moment(joborder_start, 'YYYY-MM-DD').tz("Asia/Bangkok").unix()
        }

        // if(this.state.step_1.inbound_type === '5'){
        //     this.setState({ step_1: { ...this.state.step_1, inbound_no: '' } })
        //     this.setState({ step_1: { ...this.state.step_1, inbound_date: '' } })
        //     this.setState({ step_1: { ...this.state.step_1, inbound_country: '' } })
        // }

        // if(this.state.step_1.outbound_type === '5'){
        //     this.setState({ step_1: { ...this.state.step_1, outbound_no: '' } })
        //     this.setState({ step_1: { ...this.state.step_1, outbound_date: '' } })
        //     this.setState({ step_1: { ...this.state.step_1, outbound_country: '' } })
        // }

        // for (var flights of flights_external) {
        //     flights.before_flight_arrival_datetime = flights.flight_arrival_datetime
        //     flights.flight_arrival_datetime = parseInt(new Date(flights.flight_arrival_datetime).getTime() / 1000)
        //     flights.before_flight_departure_datetime = flights.flight_departure_datetime
        //     flights.flight_departure_datetime = parseInt(new Date(flights.flight_departure_datetime).getTime() / 1000)
        // }

        for (var flights of flights_internal) {
            flights.before_flight_arrival_datetime = flights.flight_arrival_datetime
            flights.flight_arrival_datetime = parseInt(new Date(flights.flight_arrival_datetime).getTime() / 1000)
            flights.before_flight_departure_datetime = flights.flight_departure_datetime
            flights.flight_departure_datetime = parseInt(new Date(flights.flight_departure_datetime).getTime() / 1000)
        }


        var summary = {
            joborder_id: this.state.joborder_id,
            joborder_code: this.state.joborder_code,
            external_agencys: this.state.step_1.external_agencys,
            external_agencys_code: this.state.step_1.external_agencys_code,
            // agency_id เก็ทจาก ระบบหลังบ้านเอา
            // flights_external: flights_external,
            flights_internal: flights_internal,
            //

            joborder_start: joborder_start,
            joborder_stop: joborder_stop,

            inbound_type: this.state.step_1.inbound_type,
            inbound_no: (this.state.step_1.inbound_type === '5') ? '' : this.state.step_1.inbound_no,
            inbound_date: (this.state.step_1.inbound_type === '5') ? '' : this.state.step_1.inbound_date,
            inbound_country: (this.state.step_1.inbound_type === '5') ? '' : this.state.step_1.inbound_country,
            outbound_type: this.state.step_1.outbound_type,
            outbound_no: (this.state.step_1.outbound_type === '5') ? '' : this.state.step_1.outbound_no,
            outbound_date: (this.state.step_1.outbound_type === '5') ? '' : this.state.step_1.outbound_date,
            outbound_country: (this.state.step_1.outbound_type === '5') ? '' : this.state.step_1.outbound_country,
            created_at: moment(this.state.step_1.created_at).format('YYYY-MM-DD'),

            guides_fee: this.state.step_2.guides_fee,

            days: this.state.step_2.days,
            tourists: this.state.step_3.tourists,
            tourists_adults: this.state.step_3.tourists_adults,
            tourists_children: this.state.step_3.tourists_children,
            tourists_followers: this.state.step_3.tourists_followers,
            tourists_all: this.state.step_3.tourists_all,
        }

        console.log(summary)

        return summary
    }
    async submit_joborder(body) {
        console.log(body)

        var auth = JSON.parse(await localStorage.getItem('authToken'))
        var response = await fetch(API_ENDPOINT + 'api/v2/web/joborder/new/save', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: auth.data.type + ' ' + auth.data.token,
            },
            body: JSON.stringify(body)

        });
        var responseJson = await response.json();
        console.log(responseJson)
        if (responseJson.status == 'success') {
            Swal.fire(
                'สำเร็จ !',
                'เพิ่มใบงานเรียบร้อยแล้ว',
                'success'
            ).then(() => {
                window.location = "/Joborder"
            })
        } else {
            return Swal.fire(
                'ล้มเหลว !',
                'เกิดข้อผิดพลาด กรุณาติดต่อผุ้ดูแลระบบ',
                'error'
            )
        }
    }
    change_step(action) {
        var current_step = this.state.step
        var next_step = action == 1 ? current_step + 1 : current_step - 1
        switch (next_step) {
            case 1:
                this.setState({
                    progress: 25,
                    step: next_step
                })
                break;
            case 2:
                if (this.validate_step_1()) {
                    this.setState({
                        progress: 50,
                        step: next_step
                    })
                }
                break;
            case 3:
                if (this.validate_step_2()) {
                    this.setState({
                        progress: 75,
                        step: next_step
                    })
                }

                break;
            case 4:
                if (this.validate_step_1() && this.validate_step_2() && this.validate_step_3()) {
                    let obj = this.state.step_3
                    obj.tourists_children = this.state.step_3.tourists.filter(item => moment().diff(item.date_of_birth, 'years') < 15).length
                    obj.tourists_adults = this.state.step_3.tourists.filter(item => moment().diff(item.date_of_birth, 'years') > 15).length
                    obj.tourists_all = this.state.step_3.tourists.length
                    this.setState({
                        progress: 100,
                        step_3: obj,
                        step: next_step
                    }, () => {

                    })

                }
                break;
        }
    }

    handle_change(e, step, name) {
        console.log(e, step, name)
        var state = this.state
        state[step][name] = e.target.value
        this.setState({
            ...state
        }, () => {
            console.log(this.state)
        })
    }

    handle_change_inbound(e) {
        if (e.target.value === '5') {
            this.setState({ step_1: { ...this.state.step_1, inbound_type: e.target.value, inbound_no: '', inbound_date: '', inbound_country: '' } })
        }
        else {
            this.setState({ step_1: { ...this.state.step_1, inbound_type: e.target.value } })
        }
    }

    handle_change_outbound(e) {
        if (e.target.value === '5') {
            this.setState({ step_1: { ...this.state.step_1, outbound_type: e.target.value, outbound_no: '', outbound_date: '', outbound_country: '' } })
        }
        else {
            this.setState({ step_1: { ...this.state.step_1, outbound_type: e.target.value } })
        }
    }
    // add_external_flight() {
    //     var flights_external = this.state.step_1.flights_external
    //     flights_external.push({
    //         flight_departure_date: '',
    //         flight_no: '',
    //         flight_from: '',
    //         flight_departure_datetime: '',
    //         flight_to: '',
    //         flight_arrival_datetime: ''
    //     })
    //     this.setState({
    //         step_1: {
    //             ...this.state.step_1,
    //             flights_external: flights_external
    //         }
    //     })
    // }
    add_internal_flight() {
        var flights_internal = this.state.step_1.flights_internal
        flights_internal.push({
            flight_departure_date: '',
            flight_no: '',
            flight_from: '',
            flight_departure_datetime: '',
            flight_to: '',
            flight_arrival_datetime: ''
        })
        this.setState({
            step_1: {
                ...this.state.step_1,
                flights_internal: flights_internal
            }
        })
    }
    // delete_external_flight(i) {
    //     Swal.fire({
    //         title: 'ยืนยันหรือไม่ ?',
    //         text: "คุณต้องการลบรายการเที่ยวบินที่ " + (i + 1) + " หรือไม่",
    //         icon: 'warning',
    //         showCancelButton: true,
    //         confirmButtonColor: '#3085d6',
    //         cancelButtonColor: '#d33',
    //         cancelButtonText: 'ยกเลิก',
    //         confirmButtonText: 'ยืนยัน!'
    //     }).then((result) => {
    //         if (result.isConfirmed) {
    //             var flights_external = this.state.step_1.flights_external
    //             flights_external.splice(i, 1);
    //             this.setState({
    //                 step_1: {
    //                     ...this.state.step_1,
    //                     flights_external: flights_external
    //                 }
    //             }, () => {
    //                 Swal.fire(
    //                     'ลบแล้ว !',
    //                     'รายการเที่ยวบินยกเลิกแล้ว',
    //                     'success'
    //                 )
    //             })

    //         }
    //     })
    // }
    delete_internal_flight(i) {
        Swal.fire({
            title: 'ยืนยันหรือไม่ ?',
            text: "คุณต้องการลบรายการเที่ยวบินที่ " + (i + 1) + " หรือไม่",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'ยกเลิก',
            confirmButtonText: 'ยืนยัน!'
        }).then((result) => {
            if (result.isConfirmed) {
                var flights_internal = this.state.step_1.flights_internal
                flights_internal.splice(i, 1);
                this.setState({
                    step_1: {
                        ...this.state.step_1,
                        flights_internal: flights_internal
                    }
                }, () => {
                    Swal.fire(
                        'ลบแล้ว !',
                        'รายการเที่ยวบินยกเลิกแล้ว',
                        'success'
                    )
                })

            }
        })
    }
    // handle_external_flight_change(e, i, name) {
    //     var flights_external = this.state.step_1.flights_external
    //     flights_external[i][name] = e.target.value
    //     this.setState({
    //         step_1: {
    //             ...this.state.step_1,
    //             flights_external: flights_external
    //         }
    //     })
    // }
    handle_internal_flight_change(e, i, name) {
        var flights_internal = this.state.step_1.flights_internal
        flights_internal[i][name] = e.target.value
        this.setState({
            step_1: {
                ...this.state.step_1,
                flights_internal: flights_internal
            }
        })
    }

    delete_tourlist(i) {
        Swal.fire({
            title: 'ยืนยันหรือไม่ ?',
            text: "คุณต้องการลบรายการนักท่องเที่ยวที่ " + (i + 1) + " หรือไม่",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'ยกเลิก',
            confirmButtonText: 'ยืนยัน!'
        }).then((result) => {
            if (result.isConfirmed) {
                var tourists = this.state.step_3.tourists
                tourists.splice(i, 1);
                this.setState({
                    step_3: {
                        ...this.state.step_3,
                        tourists: tourists
                    }
                }, () => {

                    Swal.fire(
                        'ลบแล้ว !',
                        'รายการเที่ยวบินยกเลิกแล้ว',
                        'success'
                    )
                })

            }
        })
    }

    async upload_joborderfile(e) {
        if (e.target.files[0] != undefined) {
            var formData = new FormData()
            formData.append('files', e.target.files[0], e.target.files[0].name);
            console.log(e.target.files[0])
            if (e.target.files[0].size > 800000) {
                Swal.fire(
                    'อัพโหลดไม่สำเร็จ',
                    'ไฟล์ควรมีขนาดไม่เกิน 800 kB',
                    'error'
                )
            }
            var auth = JSON.parse(await localStorage.getItem('authToken'))
            var response = await fetch(API_ENDPOINT + 'api/v2/web/joborder/upload', {
                method: 'POST',
                headers: {
                    Authorization: auth.data.type + ' ' + auth.data.token,
                },
                body: formData
            });
            var responseJson = await response.json();
            console.log('+++this.state.step_1')
            console.log(this.state.step_1)
            console.log(responseJson)
            if (responseJson.external_agencys) {
                var new_joborder_start = responseJson.joborder_start.split('/')
                var new_joborder_stop = responseJson.joborder_stop.split('/')
                var new_inbound_date = responseJson.inbound_date.split('/')
                var new_outbound_date = responseJson.outbound_date.split('/')
                this.setState({
                    step_1: {
                        ...this.state.step_1,
                        // ...responseJson
                        'external_agencys': responseJson.external_agencys,
                        'external_agencys_code': responseJson.external_agencys_code,
                        'inbound_country': responseJson.inbound_country,
                        'flights_internal': ((typeof responseJson.flights_internal) === 'undefined') ? [] : responseJson.flights_internal,
                        'inbound_country': responseJson.inbound_country,
                        'inbound_date': moment(new Date(new_inbound_date[2] + '-' + new_inbound_date[1] + '-' + new_inbound_date[0])).format('YYYY-MM-DD'),
                        'inbound_no': responseJson.inbound_no,
                        'inbound_type': responseJson.inbound_type,
                        'joborder_start': moment(new Date(new_joborder_start[2] + '-' + new_joborder_start[1] + '-' + new_joborder_start[0])).format('YYYY-MM-DD'),
                        'joborder_stop': moment(new Date(new_joborder_stop[2] + '-' + new_joborder_stop[1] + '-' + new_joborder_stop[0])).format('YYYY-MM-DD'),
                        'outbound_country': responseJson.outbound_country,
                        'outbound_date': moment(new Date(new_outbound_date[2] + '-' + new_outbound_date[1] + '-' + new_outbound_date[0])).format('YYYY-MM-DD'),
                        'outbound_no': responseJson.outbound_no,
                        'outbound_type': responseJson.outbound_type
                    }
                })

                // var obj = {
                //     step_1: [
                //         responseJson
                //     ]
                // }
                // this.setState({
                //     ...this.state.step_1,
                //     step_1: responseJson
                // })                
                console.log('this.state.step_1')
                console.log(this.state.step_1)
            }
            else {
                Swal.fire(
                    'อัพโหลดไม่สำเร็จ',
                    responseJson,
                    'error'
                )
            }
        }
    }

    async upload_file(e, i) {
        if (e.target.files[0] != undefined) {
            var formData = new FormData()
            console.log(e.target.files[0])

            formData.append('file', e.target.files[0], e.target.files[0].name);
            console.log(e.target.files[0])
            if (e.target.files[0].size > 800000) {
                Swal.fire(
                    'อัพโหลดไม่สำเร็จ',
                    'ไฟล์ควรมีขนาดไม่เกิน 800 kB',
                    'error'
                )
            }
            var auth = JSON.parse(await localStorage.getItem('authToken'))
            var response = await fetch(API_ENDPOINT + 'api/v2/web/file/upload_file', {
                method: 'POST',
                headers: {
                    Authorization: auth.data.type + ' ' + auth.data.token,
                },
                body: formData
            });
            var responseJson = await response.json();
            console.log(responseJson)
            if (responseJson.status === 'success') {
                var tourists = this.state.step_3.tourists;
                tourists[i].passport_file = responseJson.filepath;
                this.setState({
                    ...this.state,
                    step_3: {
                        ...this.state.step_3,
                        tourists: tourists
                    }
                }, () => {
                    console.log(this.state.step_3)
                })
            }
            else {
                Swal.fire(
                    'อัพโหลดไม่สำเร็จ',
                    'ไฟล์ควรมีขนาดไม่เกิน 800 kB',
                    'error'
                )
            }
        }
    }

    remove_file(i) {
        var tourists = this.state.step_3.tourists;
        tourists[i].passport_file = undefined;
        this.setState({
            ...this.state,
            step_3: {
                ...this.state.step_3,
                tourists: tourists
            }
        }, () => {
            console.log(this.state.step_3)
        })
    }

    kanban_remove_places(day, daypart, place_index) {
        Swal.fire({
            title: 'ยืนยันหรือไม่ ?',
            text: "คุณต้องการลบรายการสถานที่ท่องเที่ยว ใช่หรือไม่",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'ยกเลิก',
            confirmButtonText: 'ยืนยัน!'
        }).then((result) => {
            if (result.isConfirmed) {
                let dayindex = this.state.step_2.days.map(e => e.day).indexOf(day)
                let daypartindex = this.state.step_2.days[dayindex].dayparts.map(e => e.daypart).indexOf(daypart)
                var days = this.state.step_2.days
                days[dayindex].dayparts[daypartindex].places.splice(place_index, 1)
                this.setState({
                    step_2: {
                        ...this.state.step_2,
                        days: days
                    }
                }, () => {
                    Swal.fire(
                        'ลบแล้ว !',
                        'ลบสถานที่ท่องเที่ยวออกจากรายการแล้ว',
                        'success'
                    )
                })
            }
        })
    }
    reordering_places(days) {
        var places_ordering = 1
        for (var day of days) {
            for (var daypart of day.dayparts) {
                for (var place of daypart.places) {
                    place.place_ordering = places_ordering
                    places_ordering++
                }
            }
        }
        return days
    }
    kanban_move_places(from, to) {
        var days = this.state.step_2.days
        let dayfromindex = days.map(e => e.day).indexOf(from.day)
        let daypartfromindex = days[dayfromindex].dayparts.map(e => e.daypart).indexOf(from.daypart)
        let daytoindex = days.map(e => e.day).indexOf(to.day)
        let dayparttoindex = days[dayfromindex].dayparts.map(e => e.daypart).indexOf(to.daypart)

        var tmpplace = days[dayfromindex].dayparts[daypartfromindex].places[from.place_index]


        var existindex = days[daytoindex].dayparts[dayparttoindex].places.map(place => place.place_id).indexOf(tmpplace.place_id)
        if (existindex != -1) {
            Swal.fire(
                'ล้มเหลว !',
                'ช่วงเวลาที่เลือกมีสถานที่ ที่เลือกอยู่แล้ว',
                'error'
            )
            return
        }


        days[dayfromindex].dayparts[daypartfromindex].places.splice(from.place_index, 1)
        var form_ordering = 1
        for (var place of days[dayfromindex].dayparts[daypartfromindex].places) {
            place.place_ordering = form_ordering
            form_ordering++
        }

        days[daytoindex].dayparts[dayparttoindex].places.splice(to.place_index, 0, tmpplace)
        var to_ordering = 1
        for (var place of days[daytoindex].dayparts[dayparttoindex].places) {
            place.place_ordering = to_ordering
            to_ordering++
        }
        this.setState({
            step_2: {
                ...this.state.step_2,
                days: days
            }
        })
    }
    async fetchs_guides(skip = 0, take = 10, more = false) {
        //var name = this.state.step_2.guides_name_filter
        var path = 'api/v2/web/joborder/guides/get'
        if (this.state.step_2.name_filter === false) {
            path = 'api/v2/web/joborder/guides/license/get'
        }
        var name = this.state.step_2.guides_name_filter
        var auth = JSON.parse(await localStorage.getItem('authToken'))
        var object = {
            name: name
        }
        if (this.state.step_2.name_filter === false) {
            object = {
                license: name
            }
        }
        var response = await fetch(API_ENDPOINT + path, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: auth.data.type + ' ' + auth.data.token,

            },
            body: JSON.stringify(object)

        });
        var responseJson = await response.json();
        console.log(responseJson)
        this.setState({
            ...this.state,
            step_2: {
                ...this.state.step_2,
                fetch_guides: false,
                searching: true,
                guides: responseJson
            }
        }, () => {
            console.log(this.state.step_2.guides)
        })


    }

    async fetch_places(more = false) {
        this.setState({
            ...this.state,
            step_2: {
                ...this.state.step_2,
                fetch_places: true
            }
        }, async () => {
            var name = this.state.step_2.places_name_filter
            var places_selected_category = this.state.step_2.places_selected_category
            var auth = JSON.parse(await localStorage.getItem('authToken'))
            if (more) {
                var object = {
                    name: name,
                    take: 10,
                    skip: this.state.step_2.places.length,
                    places_categorys: places_selected_category
                }
            } else {
                var object = {
                    name: name,
                    take: 10,
                    skip: 0,
                    places_categorys: places_selected_category
                }
            }
            var response = await fetch(API_ENDPOINT + 'api/v2/web/joborder/place/get', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: auth.data.type + ' ' + auth.data.token,

                },
                body: JSON.stringify(object)

            });
            var responseJson = await response.json();
            if (more) {
                var places = this.state.step_2.places
                places = places.concat(responseJson)
                console.log('in more')
                console.log(places)
                this.setState({
                    ...this.state,
                    step_2: {
                        ...this.state.step_2,
                        fetch_places: false,
                        places: places
                    }
                })
            } else {
                console.log('in more false ')
                this.setState({
                    ...this.state,
                    step_2: {
                        ...this.state.step_2,
                        fetch_places: false,
                        places: responseJson
                    }
                })
            }
        })

    }
    add_guides_to_day(guide, day) {
        var days = this.state.step_2.days
        let dayindex = days.map(e => e.day).indexOf(day)

        if (typeof days[dayindex].guides == 'undefined') {
            days[dayindex].guides = []
        }

        let guideindex = days[dayindex].guides.map(e => e.guide_id).indexOf(guide.guide_id)
        if (guideindex == -1) {
            days[dayindex].guides.push(guide)
            this.setState({
                ...this.state,
                step_2: {
                    ...this.state.step_2,
                    days: days,
                    guides_modal_is_open: !this.state.step_2.guides_modal_is_open,
                    fetch_guides: true,
                    guides: [],
                    guides_selected_day: 0
                }
            }, () => {
                Swal.fire(
                    'สำเร็จ !',
                    'เพิ่มไกด์ในวันที่เลือกเรียบร้อยแล้ว',
                    'success'
                )
            })
        } else {
            Swal.fire(
                'ล้มเหลว !',
                'มีไกด์ทำงานในวันที่เลือกอยู่แล้ว',
                'error'
            )
        }
    }
    add_places_to_daypart() {
        var days = this.state.step_2.days

        var daysindex = days.map(day => day.day).indexOf(this.state.step_2.places_selected_day)
        var places_selected_dayparts = this.state.step_2.places_selected_daypart
        var error = 0
        for (var places_selected_daypart of places_selected_dayparts) {
            var daypartindex = days[daysindex].dayparts.map(day => day.daypart).indexOf(places_selected_daypart)
            if (daypartindex == -1) {
                days[daysindex].dayparts.push({
                    daypart: places_selected_daypart,
                    places: [],


                })
                daypartindex = days[daysindex].dayparts.map(day => day.daypart).indexOf(places_selected_daypart)
            }
            var placesindex = days[daysindex].dayparts[daypartindex].places.map(place => place.place_id).indexOf(this.state.step_2.places_selected.id)
            console.log(this.state.step_2.places_selected_category)
            if (placesindex == -1) {
                days[daysindex].dayparts[daypartindex].places.push({
                    place_id: this.state.step_2.places_selected.id,
                    place_detail: this.state.step_2.places_selected.detail,
                    place_img: this.state.step_2.places_selected.photo,
                    place_name: this.state.step_2.places_selected.name,
                    place_ordering: 0,

                })


            } else {
                error++
            }
        }
        if (error > 0) {
            Swal.fire(
                'ล้มเหลว !',
                'มีสถานที่ท่องเที่ยวในช่วงเวลาที่เลือกแล้วกรุณาเลือกใหม่อีกครั้ง',
                'error'
            )
        }
        days = this.reordering_places(days)
        this.setState({
            ...this.state,
            step_2: {
                ...this.state.step_2,
                days: days,
                places: [],
                places_name_filter: '',
                places_selected: {},
                places_selected_day: 0,
                places_selected_daypart: [],
                places_selected_category: [],
                fetch_places: false,
                places_modal_is_open: false,
                places_period_modal_is_open: false
            }
        }, () => {
            console.log(this.state.step_2.days)
            Swal.fire(
                'สำเร็จ !',
                'เพิ่มสถานที่ท่องเที่ยวในวันที่เลือกแล้ว',
                'success'
            )
        })

    }
    delete_guides_from_day(guide, day) {
        Swal.fire({
            title: 'ยืนยันหรือไม่ ?',
            text: "ต้องการลบไกด์ออกจากวันที่เลือก ใช่หรือไม่",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'ยกเลิก',
            confirmButtonText: 'ยืนยัน!'
        }).then((result) => {
            if (result.isConfirmed) {
                var days = this.state.step_2.days
                let dayindex = days.map(e => e.day).indexOf(day)
                let guideindex = days[dayindex].guides.map(e => e.guide_id).indexOf(guide.guide_id)
                days[dayindex].guides.splice(guideindex, 1);
                this.setState({
                    ...this.state,
                    step_2: {
                        ...this.state.step_2,
                        days: days,
                    }
                }, () => {
                    Swal.fire(
                        'สำเร็จ !',
                        'ลบไกด์ออกจากวันที่เลือกแล้ว',
                        'success'
                    )
                })
            }
        })

    }
    async dropFile(files) {
        var formData = new FormData();
        var state_tourists = this.state.step_3.tourists
        // var external_flight = this.state.step_1.flights_external
        var auth = JSON.parse(await localStorage.getItem('authToken'))
        formData.append('files', files[0], files[0].name);
        var response = await fetch(API_ENDPOINT + 'api/v2/web/joborder/tourist/upload', {
            method: 'POST',
            headers: {
                Authorization: auth.data.type + ' ' + auth.data.token,
            },
            body: formData
        });
        var tourists = await response.json()
        for (var i = 0; i < tourists.length - 1; i++) {
            for (var j = i + 1; j < tourists.length; j++) {
                if (tourists[i].passport == tourists[j].passport) {
                    return Swal.fire(
                        'ล้มเหลว !',
                        'มีนักท่องเที่ยวที่ข้อมูลซ้ำในไฟล์ กรุณาตรวจสอบความถูกต้องของไฟล์',
                        'error'
                    )
                }
            }
            // for (var tourist of state_tourists) {
            //     if (tourists[i].passport == tourist.password) {
            //         return Swal.fire(
            //             'ล้มเหลว !',
            //             'มีนักท่องเที่ยวที่ข้อมูลซ้ำในไฟล์ กรุณาตรวจสอบความถูกต้องของไฟล์',
            //             'error'
            //         )
            //     }
            // }
        }

        for (var i = 0; i < tourists.length; i++) {
            var new_dob = tourists[i].date_of_birth.split('/')
            tourists[i].date_of_birth = moment(new Date(new_dob[2] + '-' + new_dob[1] + '-' + new_dob[0])).format('YYYY-MM-DD')

            var index = state_tourists.map(tourist => tourist.passport).indexOf(tourists[i].passport)
            if (index == -1) {
                console.log(index)
                state_tourists.push(tourists[i])
            }

            // var flightindex = external_flight.map(flight => flight.flight_no).indexOf(tourists[i].flight_no)
            // if (flightindex == -1) {
            //     return Swal.fire(
            //         'ล้มเหลว !',
            //         'ข้อมูลเที่ยวบินเข้าประเทศในไฟล์ไม่ตรงกับที่ระบุในหน้าใบสั่งงาน',
            //         'error'
            //     )
            // }
        }

        this.setState({
            ...this.state,
            step_3: {
                ...this.state.step_3,
                tourists: state_tourists,
            }
        }, () => {
            Swal.fire(
                'สำเร็จ !',
                'เพิ่มลูกทัวร์เข้าสู่ตารางงานแล้ว',
                'success'
            )
        })




    }

    radioHandle(value) {
        let obj = this.state.step_2
        obj.name_filter = value === 'name' ? true : false;
        this.setState({
            step_2: obj
        }, () => { console.log(obj.name_filter) })
    }





    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <div className="container-fluid">
                        <h4>JOB ORDER</h4>
                        <Card>
                            <CardBody>
                                {this.state.step == 1 && (
                                    <Row>
                                        <Col lg={8} mg={8} sm={8}>
                                        </Col>
                                        <Col lg={2} mg={2} sm={2}>
                                        </Col>
                                        <Col lg={2} mg={2} sm={2}>
                                            <Button onClick={() => { this.change_step(1); }} style={{ width: '100%', backgroundColor: '#224AAC', borderColor: '#224AAC' }}>ต่อไป</Button>
                                        </Col>
                                    </Row>
                                )}
                                {this.state.step == 2 && (
                                    <Row>
                                        <Col lg={8} mg={8} sm={8}>
                                        </Col>
                                        <Col lg={2} mg={2} sm={2}>
                                            <Button onClick={() => { this.change_step(-1); }} style={{ width: '100%', backgroundColor: '#E7E7E7', borderColor: '#E7E7E7', }}><a style={{ color: '#FFFFFF' }}>ย้อนกลับ</a></Button>
                                        </Col>
                                        <Col lg={2} mg={2} sm={2}>
                                            <Button onClick={() => { this.change_step(1); }} style={{ width: '100%', backgroundColor: '#224AAC', borderColor: '#224AAC' }}>ต่อไป</Button>
                                        </Col>
                                    </Row>
                                )}
                                {this.state.step == 3 && (
                                    <Row>
                                        <Col lg={8} mg={8} sm={8}>
                                        </Col>
                                        <Col lg={2} mg={2} sm={2}>
                                            <Button onClick={() => { this.change_step(-1); }} style={{ width: '100%', backgroundColor: '#E7E7E7', borderColor: '#E7E7E7', }}><a style={{ color: '#FFFFFF' }}>ย้อนกลับ</a></Button>
                                        </Col>
                                        <Col lg={2} mg={2} sm={2}>
                                            <Button onClick={() => { this.change_step(1); }} style={{ width: '100%', backgroundColor: '#224AAC', borderColor: '#224AAC' }}>ต่อไป</Button>

                                        </Col>
                                    </Row>
                                )}
                                {this.state.step == 4 && (
                                    <Row>
                                        <Col lg={6} mg={6} sm={6}>
                                        </Col>
                                        <Col lg={2} mg={2} sm={2}>
                                            <Button onClick={() => { this.change_step(-1); }} style={{ width: '100%', backgroundColor: '#E7E7E7', borderColor: '#E7E7E7', }}><a style={{ color: '#FFFFFF' }}>ย้อนกลับ</a></Button>
                                        </Col>
                                        <Col lg={2} mg={2} sm={2}>
                                            <Button onClick={async () => {
                                                try {
                                                    var summary = await this.summary_joborder()
                                                    Swal.fire({
                                                        title: 'ยืนยันหรือไม่ ?',
                                                        text: "ยืนยันการ" + this.state.mode + "ใบงานหรือไม่ ",
                                                        icon: 'warning',
                                                        showCancelButton: true,
                                                        confirmButtonColor: '#3085d6',
                                                        cancelButtonColor: '#d33',
                                                        cancelButtonText: 'ยกเลิก',
                                                        confirmButtonText: 'ยืนยัน!'
                                                    }).then((result) => {
                                                        if (result.isConfirmed) {
                                                            this.submit_joborder(summary)
                                                        }
                                                    })
                                                } catch (err) {
                                                    console.log(err)
                                                    Swal.fire(
                                                        'เตือน !',
                                                        'พบข้อผิดพลาดในการสรุปข้อมูล ( 99 )',
                                                        'error'
                                                    )
                                                }
                                            }} style={{ width: '100%', backgroundColor: '#224AAC', borderColor: '#224AAC' }}>ยืนยัน{this.state.mode}ใบงาน</Button>
                                        </Col>
                                        <Col lg={2} mg={2} sm={2}>
                                            <Button onClick={async (event) => {
                                                let summary1 = this.state.step_1
                                                let summary2 = this.state.step_2
                                                let summary3 = this.state.step_3
                                                // if (summary == undefined) {
                                                //     summary = this.state.step_1
                                                // }
                                                await localStorage.setItem('step_1', JSON.stringify(summary1))
                                                await localStorage.setItem('step_2', JSON.stringify(summary2))
                                                await localStorage.setItem('step_3', JSON.stringify(summary3))

                                                window.open('/Savedoc')
                                            }
                                            } style={{ width: '100%', backgroundColor: '#22AD00', borderColor: '#22AD00' }}>มุมมองเอกสาร</Button>
                                        </Col>
                                    </Row>
                                )}
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <div id="kyc-verify-wizard" className="twitter-bs-wizard">
                                    <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified" style={{ background: 'none' }}>
                                        <NavItem>
                                            <NavLink className={classnames({ active: this.state.step === 1 })} onClick={() => { this.set_step(1); }} propsvalue={12} >
                                                <span className="step-number mr-2">01</span>
                                                ใบสั่งงานมัคคุเทศก์
                                            </NavLink>

                                        </NavItem>
                                        <NavItem>
                                            <NavLink className={classnames({ active: this.state.step === 2 })} onClick={() => { this.set_step(2); }} >
                                                <span className="step-number mr-2">02</span>
                                                เลือกมัคคุเทศก์
                                            </NavLink>

                                        </NavItem>
                                        <NavItem>
                                            <NavLink className={classnames({ active: this.state.step === 3 })} onClick={() => { this.set_step(3); }} >
                                                <span className="step-number mr-2">03</span>
                                                ข้อมูลนักท่องเที่ยว
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className={classnames({ active: this.state.step === 4 })} onClick={() => { this.set_step(4); }} >
                                                <span className="step-number mr-2">04</span>
                                                ตรวจสอบข้อมูล
                                            </NavLink>
                                        </NavItem>
                                    </ul>
                                    <div className="mb-100" style={{ marginTop: 10 }}>
                                        <Progress striped color="success" value={this.state.progress}></Progress>
                                    </div><br />
                                </div>
                            </CardBody>
                        </Card>
                        {this.state.step == 1 && (
                            <div>
                                <Card>
                                    <CardBody style={{ borderBottomStyle: "solid", borderBottomColor: "darkblue" }}>
                                        <Row >
                                            <Col lg={3} mg={3} sm={3}>
                                                <font style={{ color: "darkblue" }}>ชื่อผู้ประกอบธุรกิจนำเที่ยว : </font> <font>{this.state.agency_name}</font>
                                            </Col>
                                            <Col lg={3} mg={3} sm={3}>
                                                <Row>
                                                    <Col lg={12} mg={12} sm={12}>
                                                        <font style={{ color: "darkblue" }}>ใบอนุญาตเลขที่ :  </font> <font>{this.state.agency_license}</font>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col lg={3} mg={3} sm={3}>
                                                <Row>
                                                    <Col lg={12} mg={12} sm={12}>
                                                        <font style={{ color: "darkblue" }}>ใบสั่งงานเลขที่ :  </font> <font>
                                                            {this.state.joborder_code ? this.state.joborder_code :
                                                                this.state.agency_license + String(parseInt(moment().format('YYYY')) + 543).substring(2, 4) + "-xxxx"}
                                                        </font>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col lg={3} mg={3} sm={3}>
                                                <Row>
                                                    <Col lg={12} mg={12} sm={12}>
                                                        <font style={{ color: "darkblue" }}>วันที่ :</font> <font> {this.state.step_1.created_at} </font>
                                                    </Col>

                                                </Row>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardBody style={{ paddingBottom: "0px" }}>
                                        <Row>
                                            <Col lg="12" className="form-group">
                                                <Label htmlFor="dobstring">Import File</Label>
                                                <i type="file" onClick={() => {
                                                    document.getElementById('joborder_file_input').click()
                                                }} className="fas fa-file-upload" style={{ color: '#42b688', cursor: 'pointer', padding: '5px', display: 'inline-block' }}></i>
                                                <input id="joborder_file_input" style={{ display: 'none' }} onChange={(e) => { this.upload_joborderfile(e) }} type="file"></input>
                                                <a style={{ paddingLeft: '2rem' }} href="/csv/tour_external.csv">รูปแบบไฟล์นำเข้า (.csv)</a>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardBody>
                                        <Label for="kycfirstname-input" style={{ color: "darkblue" }}>ข้อมูลบริษัทนำเที่ยว :</Label>
                                        <Row>

                                            <Col lg="6">

                                                <FormGroup>
                                                    <Label for="agencyName">ชื่อบริษัทนำเที่ยวจากต่างประเทศ</Label>
                                                    <Input type="text" className="form-control" id="agencyName" placeholder="กรุณากรอกชื่อบริษัทนำเที่ยวจากต่างประเทศ" value={this.state.step_1.external_agencys} onChange={(e) => { this.handle_change(e, 'step_1', 'external_agencys') }} />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <Label for="tourCode">รหัสทัวร์</Label>
                                                    <Input type="text" className="form-control" id="external_agencys_code" placeholder="กรุณากรอกรหัสทัวร์" value={this.state.step_1.external_agencys_code} onChange={(e) => { this.handle_change(e, 'step_1', 'external_agencys_code') }} />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">

                                                <FormGroup>
                                                    <Label for="joborder_start" >วันเริ่มต้นแผนการท่องเที่ยว</Label>
                                                    <Input type="date" className="form-control" id="joborder_start" value={this.state.step_1.joborder_start} placeholder='dd-MM-yyyy' onChange={(e) => { this.handle_change(e, 'step_1', 'joborder_start') }} />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <Label for="joborder_stop">วันสิ้นสุดแผนการท่องเที่ยว</Label>
                                                    <Input type="date" className="form-control" id="joborder_stop" value={this.state.step_1.joborder_stop} onChange={(e) => { this.handle_change(e, 'step_1', 'joborder_stop') }} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>

                                <Card style={{ borderColor: '#E7E7E7', borderWidth: 2 }}>

                                    <CardBody>
                                        <Label for="kycfirstname-input" style={{ color: "darkblue" }}>ช่องทางที่คณะท่องเที่ยวเดินทางมาถึง :</Label>
                                        <Card style={{ backgroundColor: '#F6F8FF', borderColor: '#E7E7E7', borderWidth: 2, marginTop: 20, borderRadius: 10 }}>
                                            <CardBody>
                                                <Row>
                                                    <Col lg="3">
                                                        <FormGroup>
                                                            <Label for="from">เดินทางโดย</Label>
                                                            <Input type="select" className="form-control"
                                                                value={this.state.step_1.inbound_type}
                                                                onChange={e => this.handle_change_inbound(e)} >
                                                                <option value="1">เครื่องบิน</option>
                                                                <option value="2">รถ</option>
                                                                <option value="3">เรือ</option>
                                                                <option value="4">อื่นๆ</option>
                                                                <option value="5">เดินทางภายในประเทศ</option>
                                                            </Input>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="3">
                                                        <FormGroup>
                                                            <Label for="flightCode">เที่ยวบินที่/รถทะเบียนเลขที่/ชื่อเรือ</Label>
                                                            <Input type="text" disabled={(this.state.step_1.inbound_type === '5') ? true : false} className="form-control" placeholder="กรุณากรอก"
                                                                value={(this.state.step_1.inbound_type === '5') ? '' : this.state.step_1.inbound_no}
                                                                onChange={e => this.setState({ step_1: { ...this.state.step_1, inbound_no: (this.state.step_1.inbound_type === '5') ? '' : e.target.value } })}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="3">
                                                        <FormGroup>
                                                            <Label for="flightDate">วันที่เดินทางมาถึง</Label>
                                                            <Input type="date" disabled={(this.state.step_1.inbound_type === '5') ? true : false} className="form-control" placeholder="กรุณากรอก วัน/เดือน/ปี"
                                                                value={(this.state.step_1.inbound_type === '5' || this.state.step_1.inbound_date === '1970-01-01') ? '' : this.state.step_1.inbound_date}
                                                                onChange={e => this.setState({ step_1: { ...this.state.step_1, inbound_date: (this.state.step_1.inbound_type === '5') ? '' : e.target.value } })}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="3">
                                                        <FormGroup>
                                                            <Label for="from">เดินทางจากประเทศ</Label>
                                                            <Input type="text" disabled={(this.state.step_1.inbound_type === '5') ? true : false} className="form-control" placeholder="กรุณาระบุประเทศต้นทาง"
                                                                value={(this.state.step_1.inbound_type === '5') ? '' : this.state.step_1.inbound_country}
                                                                onChange={e => this.setState({ step_1: { ...this.state.step_1, inbound_country: (this.state.step_1.inbound_type === '5') ? '' : e.target.value } })}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </CardBody>
                                </Card>

                                <Card style={{ borderColor: '#E7E7E7', borderWidth: 2 }}>

                                    <CardBody>
                                        <Label for="kycfirstname-input" style={{ color: "darkblue" }}>ช่องทางที่คณะท่องเที่ยวเดินทางกลับ :</Label>
                                        <Card style={{ backgroundColor: '#F6F8FF', borderColor: '#E7E7E7', borderWidth: 2, marginTop: 20, borderRadius: 10 }}>
                                            <CardBody>
                                                <Row>
                                                    <Col lg="3">
                                                        <FormGroup>
                                                            <Label for="from">เดินทางโดย</Label>
                                                            <Input type="select" className="form-control"
                                                                value={this.state.step_1.outbound_type}
                                                                onChange={e => this.handle_change_outbound(e)} >
                                                                <option value="1">เครื่องบิน</option>
                                                                <option value="2">รถ</option>
                                                                <option value="3">เรือ</option>
                                                                <option value="4">อื่นๆ</option>
                                                                <option value="5">เดินทางภายในประเทศ</option>
                                                            </Input>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="3">
                                                        <FormGroup>
                                                            <Label for="flightCode">เที่ยวบินที่/รถทะเบียนเลขที่/ชื่อเรือ</Label>
                                                            <Input type="text" disabled={(this.state.step_1.outbound_type === '5') ? true : false} className="form-control" placeholder="กรุณากรอก"
                                                                value={(this.state.step_1.outbound_type === '5') ? '' : this.state.step_1.outbound_no}
                                                                onChange={e => this.setState({ step_1: { ...this.state.step_1, outbound_no: (this.state.step_1.outbound_type === '5') ? '' : e.target.value } })}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="3">
                                                        <FormGroup>
                                                            <Label for="flightDate">วันที่เดินทางกลับ</Label>
                                                            <Input type="date" disabled={(this.state.step_1.outbound_type === '5') ? true : false} className="form-control" placeholder="กรุณากรอก วัน/เดือน/ปี"
                                                                value={(this.state.step_1.outbound_type === '5' || this.state.step_1.outbound_date === '1970-01-01') ? '' : this.state.step_1.outbound_date}
                                                                onChange={e => this.setState({ step_1: { ...this.state.step_1, outbound_date: (this.state.step_1.outbound_type === '5') ? '' : e.target.value } })}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="3">
                                                        <FormGroup>
                                                            <Label for="from">เดินทางกลับประเทศ</Label>
                                                            <Input type="text" disabled={(this.state.step_1.outbound_type === '5') ? true : false} className="form-control" placeholder="กรุณาระบุประเทศปลายทาง"
                                                                value={(this.state.step_1.outbound_type === '5') ? '' : this.state.step_1.outbound_country}
                                                                onChange={e => this.setState({ step_1: { ...this.state.step_1, outbound_country: (this.state.step_1.outbound_type === '5') ? '' : e.target.value } })}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </CardBody>
                                </Card>

                                {/* <Card style={{ borderColor: '#E7E7E7', borderWidth: 2 }}>

                                    <CardBody>
                                        <Label for="kycfirstname-input" style={{ color: "darkblue" }}>กำหนดตารางเที่ยวบิน :</Label>
                                        {this.state.step_1.flights_external.length > 0 ?
                                            <div>
                                                {this.state.step_1.flights_external.map((e, i) => {
                                                    return (
                                                        <div key={i}>
                                                            <Card style={{ backgroundColor: '#F6F8FF', borderColor: '#E7E7E7', borderWidth: 2, marginTop: 20, borderRadius: 10 }}>
                                                                <CardBody>
                                                                    <Row>
                                                                        <Col lg={10}>
                                                                            <Label>เที่ยวบินขาเข้าที่ : {i + 1}</Label>
                                                                        </Col>
                                                                        <Col lg={2} style={{ textAlign: 'end' }}>
                                                                            <i className="fas fa-times" id="deleteFlight" onClick={(e) => { this.delete_external_flight(i) }} style={{ color: 'red' }}></i>

                                                                        </Col>
                                                                        <Col lg="6">
                                                                            <FormGroup>
                                                                                <Label for="flightDate">วัน/เดือน/ปี ที่เดินทางมาถึง</Label>
                                                                                <Input type="date" className="form-control" placeholder="กรุณากรอก วัน/เดือน/ปี ที่เดินทางมาถึง" value={e.flight_departure_date} onChange={(e) => { this.handle_external_flight_change(e, i, 'flight_departure_date') }} />
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col lg="6">
                                                                            <FormGroup>
                                                                                <Label for="flightCode">เที่ยวบิน</Label>
                                                                                <Input type="text" className="form-control" placeholder="กรุณากรอกเที่ยวบิน" value={e.flight_no} onChange={(e) => { this.handle_external_flight_change(e, i, 'flight_no') }} />
                                                                            </FormGroup>
                                                                        </Col>


                                                                    </Row>
                                                                    <Row>

                                                                        <Col lg={6}>
                                                                            <Row>
                                                                                <Col lg="6">

                                                                                    <FormGroup>
                                                                                        <Label for="from">จาก</Label>
                                                                                        <Input type="text" className="form-control" placeholder="กรุณากรอกสนามบินต้นทาง" value={e.flight_from} onChange={(e) => { this.handle_external_flight_change(e, i, 'flight_from') }} />
                                                                                    </FormGroup>
                                                                                </Col>
                                                                                <Col lg="6">
                                                                                    <FormGroup>
                                                                                        <Label for="flight_depart">เวลา</Label>
                                                                                        <div className="form-group row">
                                                                                            <div className="col-md-12">
                                                                                                <input className="form-control" type="datetime-local" defaultValue={e.flight_departure_datetime} onChange={(e) => { this.handle_external_flight_change(e, i, 'flight_departure_datetime') }} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </FormGroup>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        <Col lg={6}>
                                                                            <Row>
                                                                                <Col lg="6">

                                                                                    <FormGroup>
                                                                                        <Label for="to">ถึง</Label>
                                                                                        <Input type="text" className="form-control" placeholder="กรุณากรอกสนามบินปลายทาง" value={e.flight_to} onChange={(e) => { this.handle_external_flight_change(e, i, 'flight_to') }} />
                                                                                    </FormGroup>
                                                                                </Col>
                                                                                <Col lg="6">
                                                                                    <FormGroup>
                                                                                        <Label for="flight_arrival">เวลา</Label>
                                                                                        <div className="form-group row">
                                                                                            <div className="col-md-12">
                                                                                                <input className="form-control" type="datetime-local" defaultValue={e.flight_arrival_datetime} onChange={(e) => { this.handle_external_flight_change(e, i, 'flight_arrival_datetime') }} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </FormGroup>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                    </Row>
                                                                </CardBody>
                                                            </Card>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            :
                                            null
                                        }
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Button color="light" outline className="waves-effect" style={{ width: "100%" }} onClick={() => { this.add_external_flight() }}><i className="fas fa-plus-circle"></i>เพิ่มตารางเที่ยวบิน</Button>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card> */}
                                <Card style={{ borderColor: '#E7E7E7', borderWidth: 2 }}>

                                    <CardBody>
                                        <Label for="kycfirstname-input" style={{ color: "darkblue" }}>กำหนดตารางเที่ยวบินในประเทศ :</Label>
                                        {this.state.step_1.flights_internal.length > 0 ?
                                            <div>
                                                {this.state.step_1.flights_internal.map((e, i) => {
                                                    return (
                                                        <div key={i}>
                                                            <Card style={{ backgroundColor: '#F6F8FF', borderColor: '#E7E7E7', borderWidth: 2, marginTop: 20, borderRadius: 10 }}>
                                                                <CardBody>
                                                                    <Row>
                                                                        <Col lg={10}>
                                                                            <Label>เที่ยวบินในประเทศที่ : {i + 1}</Label>
                                                                        </Col>
                                                                        <Col lg={2} style={{ textAlign: 'end' }}>
                                                                            <i className="fas fa-times" id="deleteFlight" onClick={(e) => { this.delete_internal_flight(i) }} style={{ color: 'red' }}></i>
                                                                        </Col>
                                                                        <Col lg="6">
                                                                            <FormGroup>
                                                                                <Label for="flightDate">วัน/เดือน/ปี ที่เดินทางมาถึง</Label>
                                                                                <Input type="date" className="form-control" placeholder="กรุณากรอก วัน/เดือน/ปี ที่เดินทางมาถึง" value={e.flight_departure_date} onChange={(e) => { this.handle_internal_flight_change(e, i, 'flight_departure_date') }} />
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col lg="6">
                                                                            <FormGroup>
                                                                                <Label for="flightCode">เที่ยวบิน</Label>
                                                                                <Input type="text" className="form-control" placeholder="กรุณากรอกเที่ยวบิน" value={e.flight_no} onChange={(e) => { this.handle_internal_flight_change(e, i, 'flight_no') }} />
                                                                            </FormGroup>
                                                                        </Col>


                                                                    </Row>
                                                                    <Row>

                                                                        <Col lg={6}>
                                                                            <Row>
                                                                                <Col lg="6">

                                                                                    <FormGroup>
                                                                                        <Label for="from">จาก</Label>
                                                                                        <Input type="text" className="form-control" placeholder="กรุณากรอกสนามบินต้นทาง" value={e.flight_from} onChange={(e) => { this.handle_internal_flight_change(e, i, 'flight_from') }} />
                                                                                    </FormGroup>
                                                                                </Col>
                                                                                <Col lg="6">
                                                                                    <FormGroup>
                                                                                        <Label for="flight_depart">เวลา</Label>
                                                                                        <div className="form-group row">
                                                                                            <div className="col-md-12">
                                                                                                <input className="form-control" type="datetime-local" defaultValue={e.flight_departure_datetime} onChange={(e) => { this.handle_internal_flight_change(e, i, 'flight_departure_datetime') }} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </FormGroup>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        <Col lg={6}>
                                                                            <Row>
                                                                                <Col lg="6">

                                                                                    <FormGroup>
                                                                                        <Label for="to">ถึง</Label>
                                                                                        <Input type="text" className="form-control" placeholder="กรุณากรอกสนามบินปลายทาง" value={e.flight_to} onChange={(e) => { this.handle_internal_flight_change(e, i, 'flight_to') }} />
                                                                                    </FormGroup>
                                                                                </Col>
                                                                                <Col lg="6">
                                                                                    <FormGroup>
                                                                                        <Label for="flight_arrival">เวลา</Label>
                                                                                        <div className="form-group row">
                                                                                            <div className="col-md-12">
                                                                                                <input className="form-control" type="datetime-local" defaultValue={e.flight_arrival_datetime} onChange={(e) => { this.handle_internal_flight_change(e, i, 'flight_arrival_datetime') }} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </FormGroup>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                    </Row>
                                                                </CardBody>
                                                            </Card>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            :
                                            null
                                        }
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Button color="light" outline className="waves-effect" style={{ width: "100%" }} onClick={() => { this.add_internal_flight() }}><i className="fas fa-plus-circle"></i>เพิ่มตารางเที่ยวบิน</Button>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </div>
                        )}

                        {this.state.step == 2 && (
                            <div>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col lg="3">

                                                <FormGroup>
                                                    <Label for="agencyName">อัตราค่าตอบแทนมัคคุเทศก์ วันละ (บาท)</Label>
                                                    <Input type="text" className="form-control" id="guideFee" placeholder="อัตราค่าตอบแทนมัคคุเทศก์ วันละ (บาท)" value={this.state.step_2.guides_fee} onChange={(e) => { this.handle_change(e, 'step_2', 'guides_fee') }} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                                <Row style={{ marginBottom: '2%' }}>
                                    <Col lg={12}>
                                        <div className="text-right">
                                            <button onClick={() => {


                                                var days = this.state.step_2.days
                                                if (days.length == 0) {
                                                    days.push({
                                                        day: 1,
                                                        dayparts: [],
                                                        collapse_open: false
                                                    })
                                                } else {
                                                    days.push({
                                                        day: days[days.length - 1].day + 1,
                                                        dayparts: [],
                                                        collapse_open: false
                                                    })
                                                }
                                                days = this.reordering_places(days)
                                                var step_1 = this.state.step_1
                                                console.log(step_1)
                                                var new_stop = moment(step_1.joborder_start, "YYYY-MM-DD").add('days', days.length).format('YYYY-MM-DD');
                                                step_1.joborder_stop = new_stop

                                                this.setState({
                                                    ...this.state,
                                                    step_1: step_1,
                                                    step_2: {
                                                        ...this.state.step_2,
                                                        days: days
                                                    }
                                                })
                                            }} className="btn btn-primary">
                                                เพิ่มวันท่องเที่ยว
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                                {this.state.step_2.days.map((element, i) => {
                                    console.log(element)
                                    return (
                                        <Card key={i} style={{ border: '1px solid #C8C8C8' }}>
                                            <CardBody>
                                                <Row>
                                                    <Col xs={10} lg={11}>
                                                        <div onClick={() => {
                                                            let days = this.state.step_2.days
                                                            days[i].collapse_open = !days[i].collapse_open
                                                            this.setState({
                                                                ...this.state,
                                                                step_2: {
                                                                    ...this.state.step_2,
                                                                    days: days
                                                                }
                                                            })
                                                        }} style={{ cursor: "pointer", paddingRight: 10, alignSelf: 'center', fontSize: 20, color: 'black' }}>
                                                            <i className="fas fa-angle-down" style={{ marginRight: 10, cursor: 'pointer' }}></i> วันที่ {element.day}</div>
                                                    </Col>
                                                    <Col style={{ textAlign: 'right', paddingTop: '0.3rem' }} xs={2} lg={1}>
                                                        <div onClick={() => {
                                                            Swal.fire({
                                                                title: 'ยืนยันหรือไม่ ?',
                                                                text: "ต้องการลบวันที่ " + i + " ข้อมูลสถานที่ท่องเที่ยวที่เลือกไว้จะทำการลบข้อมูลทั้งหมด ยืนยัน ใช่หรือไม่ ",
                                                                icon: 'warning',
                                                                showCancelButton: true,
                                                                confirmButtonColor: '#3085d6',
                                                                cancelButtonColor: '#d33',
                                                                cancelButtonText: 'ยกเลิก',
                                                                confirmButtonText: 'ยืนยัน!'
                                                            }).then((result) => {
                                                                if (result.isConfirmed) {
                                                                    var days = this.state.step_2.days
                                                                    days.splice(i, 1)
                                                                    this.setState({
                                                                        ...this.state,
                                                                        step_2: {
                                                                            ...this.state.step_2,
                                                                            days: days,
                                                                        }
                                                                    }, () => {
                                                                        Swal.fire(
                                                                            'สำเร็จ !',
                                                                            'ลบวันที่ที่เลือกเรียบร้อยแล้ว',
                                                                            'success'
                                                                        )
                                                                    })
                                                                }
                                                            })
                                                        }}>  <i class="fas fa-trash-alt" style={{ color: '#e74242', cursor: 'pointer', }}></i></div>
                                                    </Col>
                                                </Row>

                                                <Collapse isOpen={element.collapse_open}>
                                                    <Card>
                                                        <CardBody>
                                                            <Row>
                                                                <Col lg="12" className="text-right" style={{}}>
                                                                    <button onClick={() => {
                                                                        this.setState({
                                                                            ...this.state,
                                                                            step_2: {
                                                                                ...this.state.step_2,
                                                                                guides_modal_is_open: !this.state.step_2.places_modal_is_open,
                                                                                // fetch_guides: true,
                                                                                guides: [],
                                                                                guides_selected_day: element.day
                                                                            }
                                                                        }, () => {
                                                                            // this.fetchs_guides()
                                                                        })
                                                                    }} className="btn btn-success" style={{ backgroundColor: '#42B600', borderColor: '#42B600', color: 'white' }}><i className="fas fa-plus-circle font-size-16 align-middle mr-2"></i>เพิ่มมัคคุเทศก์</button>
                                                                </Col>
                                                            </Row>
                                                            <Row style={{ marginTop: 10, borderRadius: 5 }}>
                                                                <Col lg="12">
                                                                    <Card style={{ backgroundColor: '#224aac', }}>
                                                                        <div style={{ margin: '2vh' }}>
                                                                            <h3 style={{ color: 'white' }}>มัคคุเทศก์</h3>
                                                                        </div>


                                                                        {typeof element.guides == 'undefined' && (
                                                                            <CardBody>
                                                                                <div className="text-center" style={{ color: 'white' }}>ไม่พบข้อมูลมัคคุเทศก์</div>
                                                                            </CardBody>
                                                                        )}
                                                                        {typeof element.guides != 'undefined' && (
                                                                            <CardBody>
                                                                                <Row>
                                                                                    {element.guides.map((guideelement, i) => {
                                                                                        console.log(guideelement)
                                                                                        return (
                                                                                            <Col key={i} xl={4} lg={6} md={12} sm={12} xs={12}>
                                                                                                <Card style={{ boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.25)' }}>
                                                                                                    <CardBody style={{ height: '23rem' }}>
                                                                                                        <center>
                                                                                                            {
                                                                                                                guideelement.photo === '' || guideelement.photo === 'Under Construction' || guideelement.photo === null
                                                                                                                    ? <img className="style-img-add-guide" src={'/assets/images/others/noimage.png'} />
                                                                                                                    : <img className="style-img-add-guide" src={API_ENDPOINT + guideelement.photo} />
                                                                                                            }
                                                                                                        </center>
                                                                                                        <div style={{ marginTop: 20 }}><b>ชื่อมัคคุเทศก์</b> : {guideelement.fname} {guideelement.lname}</div>
                                                                                                        <div><b>เลขที่ใบอนุญาต</b> : {guideelement.license}</div>
                                                                                                        <div><b>ที่อยู่</b> : {guideelement.address} </div>
                                                                                                        <div className="text-center" style={{ marginTop: 10 }}><button onClick={() => {
                                                                                                            this.delete_guides_from_day(guideelement, element.day)
                                                                                                        }} className="btn btn-danger btn-sm">ลบมัคคุเทศก์</button></div>
                                                                                                    </CardBody>
                                                                                                </Card>
                                                                                            </Col>
                                                                                            // <Col key={i} sm={12} md={12} lg={12} xl={12}>
                                                                                            //     <Card style={{ boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.25)' }}>
                                                                                            //         <CardBody>
                                                                                            //             <Col sm={12} md={12} lg={12} xl={12}>

                                                                                            //                 <div className="media" style={{ alignItems: 'center', paddingBottom: 10 }}>
                                                                                            //                     <div className="mr-3 ml-3">
                                                                                            //                         <img className="rounded-circle avatar-lg" style={{ height: 75, width: 75 }} src="https://cdn-images.zety.com/authors/christian_eilers_1.jpg" />
                                                                                            //                     </div>
                                                                                            //                     <Row>
                                                                                            //                         <Col sm={6} md={6} lg={6} xl={6}>
                                                                                            //                             <h6><span className="mr-2" style={{ color: 'rgb(0, 39, 134)' }}>ชื่อ</span>{guideelement.fname}</h6>
                                                                                            //                         </Col>
                                                                                            //                         <Col sm={6} md={6} lg={6} xl={6} className="pl-modal-guide">
                                                                                            //                             <h6><span className="mr-2" style={{ color: 'rgb(0, 39, 134)' }}>นามสกุล</span>{guideelement.lname}</h6>
                                                                                            //                         </Col >
                                                                                            //                         <Col sm={6} md={6} lg={6} xl={6}>
                                                                                            //                             <h6> <i class="far fa-id-card mr-3" style={{ borderRadius: 50, color: 'rgb(0, 39, 134)' }}></i>{guideelement.license}</h6>
                                                                                            //                         </Col>
                                                                                            //                         <Col sm={6} md={6} lg={6} xl={6}>
                                                                                            //                             <h6> <i class="fas fa-home mr-3" style={{ borderRadius: 50, color: 'rgb(0, 39, 134)' }}></i>{guideelement.address}</h6>
                                                                                            //                         </Col>
                                                                                            //                     </Row>
                                                                                            //                 </div>
                                                                                            //                 <Row style={{ width: '100%', alignItems: 'center', borderTop: 'solid 1px rgba(0, 0, 0, 0.1)', justifyContent: 'flex-end' }}>

                                                                                            //                     <div className="mt-1" onClick={() => {
                                                                                            //                         this.delete_guides_from_day(guideelement, element.day)
                                                                                            //                     }} >
                                                                                            //                         <div style={{ textAlign: 'end', cursor: 'pointer' }}>
                                                                                            //                             <p style={{ fontSize: 10, color: 'red' }}>ลบ <i class="far fa-trash-alt"></i></p>
                                                                                            //                         </div>
                                                                                            //                     </div>

                                                                                            //                 </Row>


                                                                                            //             </Col>
                                                                                            //                 <center>
                                                                                            //                     <img style={{ width: '60%' }} src="https://cdn-images.zety.com/authors/christian_eilers_1.jpg" />
                                                                                            //                 </center>
                                                                                            //                 <div style={{ marginTop: 20 }}><b>ชื่อไกด์</b> : {guideelement.fname} {guideelement.lname}</div>
                                                                                            //                 <div><b>เลขที่ใบอนุญาต</b> : {guideelement.license}</div>
                                                                                            //                 <div><b>ที่อยู่</b> : {guideelement.address} </div>
                                                                                            //                 <div className="text-center"><button onClick={() => {
                                                                                            //                     this.delete_guides_from_day(guideelement, element.day)
                                                                                            //                 }} className="btn btn-danger btn-sm">ลบไกด์</button></div>
                                                                                            //         </CardBody>
                                                                                            //     </Card>
                                                                                            // </Col>
                                                                                        )
                                                                                    })}
                                                                                </Row>
                                                                            </CardBody>
                                                                        )
                                                                        })

                                                                    </Card>
                                                                </Col>
                                                            </Row>
                                                            <hr></hr>
                                                            <Row >
                                                                <Col lg="12" className="text-right" style={{}}>
                                                                    <button onClick={() => {
                                                                        this.setState({
                                                                            ...this.state,
                                                                            step_2: {
                                                                                ...this.state.step_2,
                                                                                places_modal_is_open: !this.state.step_2.places_modal_is_open,
                                                                                fetch_places: true,
                                                                                places: [],
                                                                                places_selected_day: element.day,


                                                                            }
                                                                        }, () => {
                                                                            this.fetch_places()
                                                                        })

                                                                    }} className="btn btn-success" style={{ backgroundColor: '#42B600', borderColor: '#42B600', color: 'white' }}> <i className="fas fa-plus-circle font-size-16 align-middle mr-2"></i>เพิ่มสถานที่ท่องเที่ยว</button>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col lg="12">
                                                                    <PlacesKanban kanban_move_places={this.kanban_move_places.bind(this)} kanban_remove_places={this.kanban_remove_places.bind(this)} index={i} days={element} ></PlacesKanban>
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                </Collapse>
                                            </CardBody>
                                        </Card>
                                    )
                                })}
                                <Modal id="places_modal" size={'xl'} isOpen={this.state.step_2.places_modal_is_open} toggle={() => {
                                    this.setState({
                                        ...this.state,
                                        step_2: {
                                            ...this.state.step_2,
                                            places_modal_is_open: !this.state.step_2.places_modal_is_open
                                        }
                                    })
                                }} className={''}>
                                    {/* <ModalHeader toggle={() => {
                                        this.setState({
                                            ...this.state,
                                            step_2: {
                                                ...this.state.step_2,
                                                places_modal_is_open: !this.state.step_2.places_modal_is_open
                                            }
                                        })
                                    }}>เพิ่มสถานที่ท่องเที่ยว</ModalHeader> */}
                                    <ModalBody>
                                        <Row>
                                            <Col lg={3}>
                                                <Row>
                                                    <Col lg={12}>
                                                        <FormGroup>
                                                            <Button style={{ width: '100%' }} type="button" color="primary" className="waves-effect waves-light" onClick={event => window.location.href = '/Joborderadd'}>
                                                                เพิ่มสถานที่
                                                            </Button>
                                                            <form className="app-search d-none d-lg-block">
                                                                <div className="position-relative">
                                                                    <input type="text" onChange={(text) => {
                                                                        let places_name_filter = this.state.step_2.places_name_filter
                                                                        places_name_filter = text.target.value
                                                                        this.setState({
                                                                            ...this.state,
                                                                            step_2: {
                                                                                ...this.state.step_2,
                                                                                places_name_filter: places_name_filter
                                                                            }
                                                                        }, () => { console.log(this.state.step_2.places_name_filter) })
                                                                    }} className="form-control" id="places_name" placeholder="ค้นหาสถานที่เที่ยว" />
                                                                    <span className="bx bx-search-alt"></span>
                                                                </div>
                                                            </form>
                                                            <button onClick={() => { this.fetch_places() }} className="btn btn-primary btn-block">ค้นหา</button>

                                                        </FormGroup>


                                                        <FormGroup>
                                                            <Label>หมวดหมู่สถานที่</Label>
                                                            {this.state.step_2.places_categorys.map((places_element, i) => {
                                                                return (
                                                                    <div className="custom-control custom-checkbox mt-2" key={i}> <Input style={{ marginTop: 5 }} value={places_element.id} onClick={(e) => {
                                                                        if (e.target.checked) {
                                                                            let places_selected_category = this.state.step_2.places_selected_category
                                                                            if (places_selected_category.indexOf(e.target.value) == -1) {
                                                                                places_selected_category.push(e.target.value)
                                                                                this.setState({
                                                                                    ...this.state,
                                                                                    step_2: {
                                                                                        ...this.state.step_2,
                                                                                        places_selected_category: places_selected_category
                                                                                    }
                                                                                }, () => {
                                                                                    console.log(this.state.step_2)
                                                                                    this.fetch_places()
                                                                                })
                                                                            }
                                                                        } else {
                                                                            let places_selected_category = this.state.step_2.places_selected_category
                                                                            var places_selected_category_index = places_selected_category.indexOf(e.target.value)
                                                                            if (places_selected_category_index > -1) {
                                                                                places_selected_category.splice(places_selected_category_index, 1)
                                                                                this.setState({
                                                                                    ...this.state,
                                                                                    step_2: {
                                                                                        ...this.state.step_2,
                                                                                        places_selected_category: places_selected_category
                                                                                    }
                                                                                }, () => {
                                                                                    this.fetch_places()
                                                                                })
                                                                            }
                                                                        }
                                                                    }} id={"category-" + places_element.id} type="checkbox"></Input> {places_element.name}</div>
                                                                )
                                                            })}
                                                        </FormGroup>
                                                    </Col>
                                                </Row>

                                            </Col>
                                            <Col lg={9}>
                                                <div style={{ textAlign: 'end', paddingRight: 5, cursor: 'pointer', paddingBottom: 5 }}>
                                                    <h3 onClick={() =>
                                                        this.setState({
                                                            ...this.state,
                                                            step_2: {
                                                                ...this.state.step_2,
                                                                places_modal_is_open: !this.state.step_2.places_modal_is_open
                                                            }
                                                        })
                                                    }>x</h3>
                                                </div>
                                                <div style={{ textAlign: 'center', marginTop: -35, paddingBottom: 10 }}>
                                                    <h5><span style={{ color: 'rgb(34, 74, 172)' }}></span> สถานที่ในหมวดหมู่นี้1111</h5>
                                                </div>
                                                {
                                                    this.state.step_2.fetch_places == true && (

                                                        <Row>
                                                            <Col>
                                                                <div className="text-center">
                                                                    <FadeIn>
                                                                        <div style={{ display: "flex", justifyContent: 'center' }}>
                                                                            <Card>
                                                                                <CardBody>
                                                                                    <Lottie options={defaultOptions} height={300} width={300} />
                                                                                    <div>
                                                                                        <h4 style={{ color: 'black', padding: 30 }}>กำลังเตรียมข้อมูล กรุณารอสักครู่</h4>
                                                                                    </div>
                                                                                </CardBody>
                                                                            </Card>

                                                                        </div>
                                                                    </FadeIn>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    )
                                                }
                                                {
                                                    this.state.step_2.fetch_places == false && (
                                                        <Row>
                                                            {this.state.step_2.places.map((element, i) => {
                                                                console.log(element)
                                                                console.log(this.state.step_2.places)
                                                                console.log(this.state.step_2.places_selected)
                                                                console.log(this.state.step_2.places_selected.photo)
                                                                var img = IMAGE_ENDPOINT + "/" + element.photo.split(';')[0]
                                                                return (
                                                                    <Col key={i} md={12} lg={12} xl={6}>

                                                                        <Card style={{ boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.25)' }}>
                                                                            <CardImg top src={img} style={{ objectFit: 'cover', borderRadius: '5px 5px 0px 0px', width: 'auto', height: 150 }} />
                                                                            <CardBody>
                                                                                <Row>
                                                                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                                        {element.name.length < 50 ?
                                                                                            <h5 style={{ fontWeight: 'bold', color: 'black' }}>{element.name}</h5>
                                                                                            :
                                                                                            <h5 style={{ fontWeight: 'bold', color: 'black' }}>{element.name.substring(0, 50)}...</h5>}
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row className="justify-content-center mt-lg-1 row">
                                                                                    <Col xs={12} sm={12} md={6} lg={7} xl={7}>
                                                                                        <Row className="ml-0">
                                                                                            <div>
                                                                                                <img
                                                                                                    src={IMAGE_ENDPOINT + "/logo/" + element.category_icon + '.svg'}
                                                                                                    // src={element.category_icon}
                                                                                                    alt=""
                                                                                                    width={20}
                                                                                                />
                                                                                            </div>
                                                                                            {element.category_name != undefined ? <div>
                                                                                                {element.category_name.length < 24 ?
                                                                                                    <h6 className="mt-1 ml-2" style={{ fontWeight: 'bold', color: 'black' }}>{element.category_name}</h6>
                                                                                                    :
                                                                                                    <h6 className="mt-1 ml-2" style={{ fontWeight: 'bold', color: 'black' }}>{element.category_name.substring(0, 25)}...</h6>
                                                                                                }

                                                                                            </div> : null}

                                                                                        </Row>
                                                                                    </Col>
                                                                                    <Col xs={12} sm={12} md={6} lg={5} xl={5}>
                                                                                        <Row className="ml-0">
                                                                                            <div>
                                                                                                <img
                                                                                                    src={'/assets/images/icon/pin-drop.svg'}
                                                                                                    alt=""
                                                                                                    width={20}
                                                                                                    height={20}
                                                                                                />
                                                                                            </div>
                                                                                            <h6 className="mt-1 ml-2" style={{ fontWeight: 'bold', color: 'black' }}>จังหวัด{element.province_name}</h6>
                                                                                        </Row>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row className="justify-content-center mt-lg-1 row" style={{ borderTop: 'solid 1px rgba(0, 0, 0, 0.1)', alignItems: 'center' }}>

                                                                                    <Col className="mt-2" xs={6} sm={6} md={6} lg={6} xl={6} >

                                                                                        <Row className="ml-0" >
                                                                                            <div >
                                                                                                <img
                                                                                                    src={'/assets/images/icon/file-text.svg'}
                                                                                                    alt=""
                                                                                                    width={20}
                                                                                                    height={20}
                                                                                                />
                                                                                            </div>
                                                                                            <div style={{ cursor: 'pointer' }} onClick={event => {
                                                                                                let place_id = element.id
                                                                                                if (place_id == undefined) {
                                                                                                    place_id = element.id
                                                                                                }
                                                                                                window.open('/places/detail?placeid=' + place_id)
                                                                                            }
                                                                                            } >

                                                                                                <h6 className="mt-1 ml-2">คลิกดูรายละเอียดสถานที่</h6>

                                                                                            </div>
                                                                                        </Row>
                                                                                    </Col>

                                                                                    <Col className="mt-2" xs={6} sm={6} md={6} lg={6} xl={6} style={{ textAlign: "right" }} >
                                                                                        <button onClick={() => {
                                                                                            this.setState({
                                                                                                ...this.state,
                                                                                                step_2: {
                                                                                                    ...this.state.step_2,
                                                                                                    places_modal_is_open: false,
                                                                                                    places_period_modal_is_open: true,
                                                                                                    places_selected: element
                                                                                                }
                                                                                            }, () => {
                                                                                                console.log(this.state.step_2.places_selected)
                                                                                            })
                                                                                        }} type="button" className="btn" style={{ backgroundColor: 'rgba(255, 0, 0, -0.7)', borderColor: '#224AAC', color: "#224AAC" }}>
                                                                                            <i class="fa fa-plus-circle pr-1 mr-2 " aria-hidden="true"></i>เลือกสถานที่
                                                                                        </button>

                                                                                    </Col>
                                                                                </Row>

                                                                            </CardBody>
                                                                        </Card>

                                                                    </Col>
                                                                )
                                                            })}
                                                        </Row>

                                                    )
                                                }

                                                <Row style={{ alignSelf: 'center', justifyContent: 'center' }} onClick={() => { this.fetch_places(true) }}>
                                                    <Card style={{ width: '100%', backgroundColor: 'transparent', borderWidth: 1, borderColor: 'lightgray' }}>
                                                        <CardBody style={{ textAlign: 'center', cursor: 'pointer' }}>
                                                            <i className="fas fa-plus" style={{ marginRight: 10, cursor: 'pointer' }}></i>โหลดเพิ่ม
                                                        </CardBody>
                                                    </Card>
                                                </Row>


                                            </Col>

                                        </Row>

                                    </ModalBody>
                                    <ModalFooter>

                                    </ModalFooter>
                                </Modal>
                                <Modal id="places_period_modal" size={'lg'} isOpen={this.state.step_2.places_period_modal_is_open} toggle={() => {
                                    // let img = card.places.place_img.split(';')[0]
                                    console.log(this.state.step_2)
                                    this.setState({

                                        ...this.state,
                                        step_2: {
                                            ...this.state.step_2,
                                            places_selected: {},
                                            places_period_modal_is_open: !this.state.step_2.places_period_modal_is_open,
                                            places_modal_is_open: true,
                                            places_selected_daypart: []
                                        }
                                    })
                                }} className={''}>


                                    <ModalHeader toggle={() => {
                                        console.log(this.state.step_2)
                                        this.setState({

                                            ...this.state,
                                            step_2: {
                                                ...this.state.step_2,
                                                places_selected: {},
                                                places_period_modal_is_open: !this.state.step_2.places_period_modal_is_open,
                                                places_modal_is_open: true,
                                                places_selected_daypart: []

                                            }
                                        }, () => {
                                            console.log(this.state.step_2)

                                        })
                                    }}>แจ้งเตือน</ModalHeader>


                                    <ModalBody>

                                        <Row style={{ paddingTop: 15, paddingBottom: 15 }}>

                                            <Col xs={5} sm={5} md={5} lg={5} xl={5} style={{ textAlign: 'center', padding: '10px' }}>
                                                {typeof this.state.step_2.places_selected.photo != 'undefined' ? <img className="rounded mr-2 rounded-circle avatar-lg" alt="Skote" width="100" src={IMAGE_ENDPOINT + this.state.step_2.places_selected.photo.split(';')[0]} /> : ''}
                                            </Col>
                                            <Col xs={7} sm={7} md={7} lg={7} xl={7} style={{ alignSelf: 'center', justifyContent: 'center', fontWeight: 'bold' }}>
                                                <h4 style={{ fontWeight: 'bold', color: '#224AAC' }}> {this.state.step_2.places_selected.name} </h4>
                                                <h5 style={{ fontWeight: 'bold', color: 'black' }}>คุณต้องการไป ในช่วงเวลาใดบ้าง? </h5>
                                            </Col>

                                        </Row>
                                        <Row style={{ borderTop: 'solid 1px rgba(0, 0, 0, 0.1)', paddingTop: 40, paddingBottom: 40 }}>
                                            <Col lg="4" className="text-center"><input onClick={(e) => {
                                                var places_selected_daypart = this.state.step_2.places_selected_daypart
                                                console.log(this.state.step_2.places_selected)
                                                if (e.target.checked) {
                                                    var places_selected_daypart_index = places_selected_daypart.indexOf(1)
                                                    if (places_selected_daypart_index == -1) {
                                                        places_selected_daypart.push(1)
                                                    }
                                                } else {
                                                    var places_selected_daypart_index = places_selected_daypart.indexOf(1)
                                                    if (places_selected_daypart_index > -1) {
                                                        places_selected_daypart.splice(places_selected_daypart_index, 1);
                                                    }
                                                }
                                                this.setState({
                                                    ...this.state,
                                                    step_2: {
                                                        ...this.state.step_2,
                                                        places_selected_daypart: places_selected_daypart
                                                    }
                                                }, () => {
                                                    console.log(this.state.step_2)

                                                })
                                            }} type="checkbox" name="dayperiod"></input> ช่วงเช้า </Col>
                                            <Col lg="4" className="text-center"><input onClick={(e) => {
                                                var places_selected_daypart = this.state.step_2.places_selected_daypart
                                                if (e.target.checked) {
                                                    var places_selected_daypart_index = places_selected_daypart.indexOf(2)
                                                    if (places_selected_daypart_index == -1) {
                                                        places_selected_daypart.push(2)
                                                    }
                                                } else {
                                                    var places_selected_daypart_index = places_selected_daypart.indexOf(2)
                                                    if (places_selected_daypart_index > -1) {
                                                        places_selected_daypart.splice(places_selected_daypart_index, 1);
                                                    }
                                                }
                                                this.setState({
                                                    ...this.state,
                                                    step_2: {
                                                        ...this.state.step_2,
                                                        places_selected_daypart: places_selected_daypart
                                                    }
                                                }, () => {
                                                    console.log(this.state.step_2)
                                                })
                                            }} type="checkbox" name="dayperiod"></input> ช่วงบ่าย </Col>
                                            <Col lg="4" className="text-center"><input onClick={(e) => {
                                                var places_selected_daypart = this.state.step_2.places_selected_daypart
                                                if (e.target.checked) {
                                                    var places_selected_daypart_index = places_selected_daypart.indexOf(3)
                                                    if (places_selected_daypart_index == -1) {
                                                        places_selected_daypart.push(3)
                                                    }
                                                } else {
                                                    var places_selected_daypart_index = places_selected_daypart.indexOf(3)
                                                    if (places_selected_daypart_index > -1) {
                                                        places_selected_daypart.splice(places_selected_daypart_index, 1);
                                                    }
                                                }
                                                this.setState({
                                                    ...this.state,
                                                    step_2: {
                                                        ...this.state.step_2,
                                                        places_selected_daypart: places_selected_daypart
                                                    }
                                                }, () => {
                                                })
                                            }} type="checkbox" name="dayperiod"></input> ช่วงเย็น</Col>
                                        </Row>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button color="secondary" onClick={() => {
                                            this.setState({
                                                ...this.state,
                                                step_2: {
                                                    ...this.state.step_2,
                                                    places_selected: {},
                                                    places_period_modal_is_open: !this.state.step_2.places_period_modal_is_open,
                                                    places_modal_is_open: true,
                                                    places_selected_daypart: 0
                                                }
                                            })
                                        }}>ยกเลิก</Button>
                                        <Button color="primary" onClick={() => {
                                            this.setState({
                                                ...this.state,
                                                step_2: {
                                                    ...this.state.step_2,
                                                    places_period_modal_is_open: false,
                                                    places_modal_is_open: false,
                                                }
                                            }, () => {
                                                this.add_places_to_daypart()
                                            })
                                        }}>ยืนยัน</Button>
                                    </ModalFooter>
                                </Modal>

                                <Modal id="guides_modal" size={'xl'} isOpen={this.state.step_2.guides_modal_is_open} toggle={() => {
                                    this.setState({
                                        ...this.state,
                                        step_2: {
                                            ...this.state.step_2,
                                            guides_modal_is_open: !this.state.step_2.guides_modal_is_open
                                        }
                                    })
                                }} className={''}>
                                    <ModalHeader toggle={() => {
                                        this.setState({
                                            ...this.state,
                                            step_2: {
                                                ...this.state.step_2,
                                                guides_modal_is_open: !this.state.step_2.guides_modal_is_open
                                            }
                                        })
                                    }}>เพิ่มมัคคุเทศก์</ModalHeader>
                                    <ModalBody>
                                        <Row>
                                            <Col lg={12}>
                                                <Row>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <Label>ค้นหา</Label>
                                                            <Input type="text" onChange={(text) => {
                                                                let guides_name_filter = this.state.step_2.guides_name_filter
                                                                guides_name_filter = text.target.value
                                                                this.setState({
                                                                    ...this.state,
                                                                    step_2: {
                                                                        ...this.state.step_2,
                                                                        guides_name_filter: guides_name_filter,
                                                                        searching: false
                                                                    }
                                                                }, () => { console.log(this.state.step_2.guides_name_filter) })
                                                            }} value={this.state.step_2.guides_name_filter} className="form-control" id="guides_name" placeholder="ค้นหา" />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="2">
                                                        <FormGroup>
                                                            <Label for="from">ค้นหาจาก</Label>
                                                            <Input type="select" className="form-control"
                                                                // disabled={true} readOnly
                                                                // value={this.state.step_2.name_filter === true ? 'ชื่อ':'เลขที่ใบอนุญาต'}
                                                                onChange={e => this.setState({
                                                                    ...this.state,
                                                                    step_2: {
                                                                        ...this.state.step_2,
                                                                        guides_name_filter: ''
                                                                    }
                                                                }, () => { this.radioHandle(e.target.value) })} >
                                                                <option value="name"> ชื่อ</option>
                                                                <option value="no">เลขที่ใบอนุญาต</option>
                                                            </Input>
                                                        </FormGroup>

                                                    </Col>
                                                    <Col lg="2">
                                                        <FormGroup>
                                                            <Label style={{ color: 'white' }}>ปุ่ม</Label><br></br>
                                                            <button onClick={() => {
                                                                if (this.state.step_2.guides_name_filter === '' || this.state.step_2.guides_name_filter === null || this.state.step_2.guides_name_filter === undefined) {

                                                                    return
                                                                }
                                                                let obj = this.state.step_2
                                                                obj.fetch_guides = true
                                                                this.setState({ step_2: obj }, () => { this.fetchs_guides() })
                                                            }} className="btn btn-success" style={{ width: '100%' }}>ค้นหา</button>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <hr></hr>
                                                {
                                                    this.state.step_2.fetch_guides == true && (
                                                        <Row>
                                                            <Col>
                                                                <div style={{ display: "flex", justifyContent: 'center' }}>
                                                                    <Card>
                                                                        <CardBody>
                                                                            <Lottie options={defaultOptions} height={300} width={300} />
                                                                            <div>
                                                                                <h4 style={{ color: 'black', padding: 30 }}>กำลังเตรียมข้อมูล กรุณารอสักครู่</h4>
                                                                            </div>
                                                                        </CardBody>
                                                                    </Card>

                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    )
                                                }
                                                {
                                                    (this.state.step_2.fetch_guides == false && this.state.step_2.guides.length !== 0) ? (
                                                        <Row>
                                                            {this.state.step_2.guides.map((element, i) => {
                                                                return (
                                                                    <Col key={i} xl={4} lg={6} md={12} sm={12} xs={12}>
                                                                        <Card style={{ boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.25)' }}>
                                                                            <CardBody style={{ height: '23rem' }}>
                                                                                <center>
                                                                                    {
                                                                                        element.photo === '' || element.photo === 'Under Construction' || element.photo === null
                                                                                            ? <img className="style-img-add-guide" src={'/assets/images/others/noimage.png'} />
                                                                                            : <img className="style-img-add-guide" src={API_ENDPOINT + element.photo} />
                                                                                    }
                                                                                </center>
                                                                                <div style={{ marginTop: 20 }}><b>ชื่อมัคคุเทศก์</b> : {element.fname} {element.lname}</div>
                                                                                <div><b>เลขที่ใบอนุญาต</b> : {element.license}</div>
                                                                                <div><b>ที่อยู่</b> : {element.address} </div>
                                                                                <div className="text-center" style={{ marginTop: 10 }}><button onClick={() => {
                                                                                    this.add_guides_to_day(element, this.state.step_2.guides_selected_day)
                                                                                }} className="btn btn-success btn-sm">เพิ่มมัคคุเทศก์</button></div>
                                                                            </CardBody>
                                                                        </Card>
                                                                    </Col>
                                                                )
                                                            })}
                                                        </Row>
                                                    )
                                                        :
                                                        <div>
                                                            {this.state.step_2.guides_name_filter !== '' && this.state.step_2.guides.length == 0 && this.state.step_2.searching === true ?
                                                                <span style={{ color: 'red' }}>* ไม่พบข้อมูล '{this.state.step_2.name_filter === true ? 'ชื่อ' : 'เลขที่ใบอนุญาต'}' ที่ค้นหา</span>
                                                                :
                                                                <span style={{ color: 'red' }}>* กรุณากรอกชื่อ หรือ เลขใบอนุญาติเพื่อค้นหา</span>
                                                            }

                                                        </div>
                                                }



                                            </Col>
                                        </Row>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button color="secondary" onClick={() => {
                                            this.setState({
                                                ...this.state,
                                                step_2: {
                                                    ...this.state.step_2,
                                                    guides_modal_is_open: !this.state.step_2.guides_modal_is_open
                                                }
                                            })
                                        }}>ปิด</Button>
                                    </ModalFooter>
                                </Modal>
                            </div>

                        )}

                        {this.state.step == 3 && (
                            <div>
                                <Card>
                                    <CardBody>
                                        <Label for="kycfirstname-input" style={{ color: "darkblue" }}>จำนวนนักท่องเที่ยว :</Label>
                                        <Row>
                                            <Col lg="3">
                                                <FormGroup>
                                                    <Label for="adults">ผู้ใหญ่ (คน)</Label>
                                                    <Input type="text" className="form-control" id="adults" placeholder="ผู้ใหญ่ (คน)" value={this.state.step_3.tourists.filter(item => moment().diff(item.date_of_birth, 'years') > 15).length}
                                                    // onChange={(e) => { this.handle_change(e, 'step_3', 'tourists_adults') }} 
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="3">
                                                <FormGroup>
                                                    <Label for="children">เด็ก (คน)</Label>
                                                    <Input type="text" className="form-control" id="children" placeholder="เด็ก (คน)" value={this.state.step_3.tourists.filter(item => moment().diff(item.date_of_birth, 'years') < 15).length}
                                                    // onChange={(e) => { this.handle_change(e, 'step_3', 'tourists_children') }} 
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="3">
                                                <FormGroup>
                                                    <Label for="followers">ผู้ติดตาม (คน)</Label>
                                                    <Input type="text" className="form-control" id="followers" placeholder="ผู้ติดตาม (คน)" value={this.state.step_3.tourists_followers} onChange={(e) => { this.handle_change(e, 'step_3', 'tourists_followers') }} />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="3">
                                                <FormGroup>
                                                    <Label for="all">รวม (คน)</Label>
                                                    <Input type="text" className="form-control" id="all" placeholder="รวม (คน)" value={this.state.step_3.tourists.length}
                                                    // onChange={(e) => { this.handle_change(e, 'step_3', 'tourists_all') }} 
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardBody>
                                        <div className="text-right">จำนวนลูกทัวร์ {this.state.step_3.tourists.length}</div>
                                        {this.state.step_3.tourists.map((element, i) => {
                                            return (
                                                <div key={i} className="row">
                                                    <Col lg="2" className="form-group">
                                                        <Label htmlFor="passport">Passport</Label>
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="กรุณากรอกพาสปอต"
                                                            value={element.passport}
                                                            onChange={(e) => {
                                                                var tourists = this.state.step_3.tourists
                                                                tourists[i].passport = e.target.value
                                                                this.setState({
                                                                    step_3: {
                                                                        ...this.state.step_3,
                                                                        tourists: tourists
                                                                    }
                                                                })
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col lg="1" className="form-group">
                                                        <Label htmlFor="dobstring">Passport File</Label>
                                                        <i type="file" onClick={() => {
                                                            document.getElementById('photo_input').click()
                                                        }} className="fas fa-file-upload" style={{ color: '#42b688', cursor: 'pointer', padding: '5px', display: 'inline-block' }}></i>
                                                        <input id="photo_input" style={{ display: 'none' }} onChange={(e) => { this.upload_file(e, i) }} type="file"></input>
                                                        {this.state.step_3.tourists[i].passport_file === undefined || this.state.step_3.tourists[i].passport_file === '' || this.state.step_3.tourists[i].passport_file === null
                                                            ? <i className="fas fa-file-image" style={{ color: 'gray', cursor: 'pointer', padding: '5px', display: 'inline-block' }}></i>
                                                            : <i className="fas fa-file-image" style={{ color: '#42b688', cursor: 'pointer', padding: '5px', display: 'inline-block' }} onClick={(e) => { window.open(API_ENDPOINT + this.state.step_3.tourists[i].passport_file) }}></i>
                                                        }
                                                        {this.state.step_3.tourists[i].passport_file === undefined || this.state.step_3.tourists[i].passport_file === '' || this.state.step_3.tourists[i].passport_file === null
                                                            ? <i className="fas fa-trash-alt" style={{ color: 'gray', cursor: 'pointer', padding: '5px', display: 'inline-block' }}></i>
                                                            : <i className="fas fa-trash-alt" style={{ color: '#e74242', cursor: 'pointer', padding: '5px', display: 'inline-block' }} onClick={(e) => { this.remove_file(i) }}></i>
                                                        }
                                                    </Col>
                                                    <Col lg="0.5" className="form-group">
                                                        <Label htmlFor="prefix">Prefix</Label>
                                                        <Dropdown isOpen={element.prefix_toggle} toggle={() => {

                                                            var tourists = this.state.step_3.tourists
                                                            tourists[i].prefix_toggle = !tourists[i].prefix_toggle
                                                            this.setState({
                                                                step_3: {
                                                                    ...this.state.step_3,
                                                                    tourists: tourists
                                                                }
                                                            })
                                                        }}>
                                                            <DropdownToggle caret>
                                                                {this.state.step_3.tourists[i].prefix}<i style={{ marginLeft: 5 }} className="fas fa-angle-down"></i>
                                                            </DropdownToggle>
                                                            <DropdownMenu>
                                                                <DropdownItem value="Mr." onClick={(e) => {
                                                                    var tourists = this.state.step_3.tourists
                                                                    tourists[i].prefix = e.target.value
                                                                    this.setState({
                                                                        step_3: {
                                                                            ...this.state.step_3,
                                                                            tourists: tourists
                                                                        }
                                                                    })
                                                                }}>Mr.</DropdownItem>
                                                                <DropdownItem value="Ms." onClick={(e) => {
                                                                    var tourists = this.state.step_3.tourists
                                                                    tourists[i].prefix = e.target.value
                                                                    this.setState({
                                                                        step_3: {
                                                                            ...this.state.step_3,
                                                                            tourists: tourists
                                                                        }
                                                                    })
                                                                }}>Ms.</DropdownItem>
                                                                <DropdownItem value="Mrs." onClick={(e) => {
                                                                    var tourists = this.state.step_3.tourists
                                                                    tourists[i].prefix = e.target.value
                                                                    this.setState({
                                                                        step_3: {
                                                                            ...this.state.step_3,
                                                                            tourists: tourists
                                                                        }
                                                                    })
                                                                }}>Mrs.</DropdownItem>
                                                            </DropdownMenu>
                                                        </Dropdown>
                                                    </Col>
                                                    <Col lg="2" className="form-group">
                                                        <Label htmlFor="fname">First Name</Label>
                                                        <Input
                                                            type="text"
                                                            name="untyped-input"
                                                            className="form-control"
                                                            placeholder="กรุณากรอกชื่อ"
                                                            value={element.firstname}
                                                            onChange={(e) => {
                                                                var tourists = this.state.step_3.tourists
                                                                tourists[i].firstname = e.target.value
                                                                this.setState({
                                                                    step_3: {
                                                                        ...this.state.step_3,
                                                                        tourists: tourists
                                                                    }
                                                                })
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col lg="2" className="form-group">
                                                        <Label htmlFor="lname">Last Name</Label>
                                                        <Input
                                                            type="text"
                                                            name="untyped-input"
                                                            className="form-control"
                                                            placeholder="กรุณากรอกนามสกุล"
                                                            value={element.lastname}
                                                            onChange={(e) => {
                                                                var tourists = this.state.step_3.tourists
                                                                tourists[i].lastname = e.target.value
                                                                this.setState({
                                                                    step_3: {
                                                                        ...this.state.step_3,
                                                                        tourists: tourists
                                                                    }
                                                                })
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col lg="2" className="form-group">
                                                        <Label htmlFor="dobstring">Birth Date</Label>
                                                        <Input
                                                            type="date"
                                                            name="untyped-input"
                                                            className="form-control"
                                                            value={element.date_of_birth}
                                                            onChange={(e) => {
                                                                var tourists = this.state.step_3.tourists
                                                                tourists[i].date_of_birth = e.target.value
                                                                this.setState({
                                                                    step_3: {
                                                                        ...this.state.step_3,
                                                                        tourists: tourists
                                                                    }
                                                                }, () => {
                                                                })
                                                            }}
                                                        />
                                                    </Col>

                                                    <Col lg="1" className="form-group">
                                                        <Label htmlFor="origincountry">Nation</Label>
                                                        <Input
                                                            type="text"
                                                            name="untyped-input"
                                                            className="form-control"
                                                            placeholder="กรุณากรอกเชื้อชาติ"
                                                            value={element.nation}
                                                            onChange={(e) => {
                                                                var tourists = this.state.step_3.tourists
                                                                tourists[i].nation = e.target.value
                                                                this.setState({
                                                                    step_3: {
                                                                        ...this.state.step_3,
                                                                        tourists: tourists
                                                                    }
                                                                })
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col
                                                        lg={1}
                                                        className="form-group"
                                                        style={{ alignSelf: 'flex-end' }}
                                                    >
                                                        <i className="fas fa-trash-alt" style={{ color: '#e74242', cursor: 'pointer', }} onClick={(e) => { this.delete_tourlist(i) }}></i>

                                                        {/* <Button

                                                            onClick={(e) => {
                                                                this.delete_tourlist(i)
                                                            }
                                                            }
                                                            color="primary"
                                                            className="mt-3"
                                                            style={{ width: "100%" }}
                                                            block
                                                        > Delete
                                                        </Button> */}
                                                    </Col>
                                                </div>
                                            )
                                        })}

                                        <Button onClick={() => {
                                            var tourists = this.state.step_3.tourists
                                            tourists.push({
                                                passport: '',
                                                passport_file: '',
                                                prefix_toggle: false,
                                                prefix: '',
                                                firstname: '',
                                                lastname: '',
                                                date_of_birth: '',
                                                nation: '',
                                                flight_toggle: false,
                                                flight: ''
                                            })
                                            this.setState({
                                                step_3: {
                                                    ...this.state.step_3,
                                                    tourists: tourists
                                                }
                                            })
                                        }} color="light" outline className="waves-effect" style={{ width: "100%" }}><i className="fas fa-plus-circle"></i>
                                            Add
                                        </Button>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardBody>
                                        <h4 style={{ color: '#224AAC', textAlign: 'center' }}><i class="fas fa-2x fa-file-alt" style={{ marginRight: 10 }}></i>Import File (.csv)</h4>
                                        <a style={{ paddingLeft: '2rem' }} href="/csv/tourist.csv">รูปแบบไฟล์นำเข้า (.csv)</a>
                                        <Row>
                                            <Col lg={12}>
                                                <Dropzone onDrop={this.dropFile.bind(this)}>
                                                    {({ getRootProps, getInputProps }) => (
                                                        <section className="">
                                                            <div {...getRootProps({ className: 'dropzone' })}>
                                                                <div className="dz-message needsclick mt-2" >
                                                                    <input {...getInputProps()} accept='.csv,.txt' />
                                                                    <div className="mb-3">
                                                                        <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                                                                    </div>
                                                                    <h4>Drop files here or click to upload.</h4>
                                                                </div>
                                                            </div>
                                                        </section>
                                                    )}
                                                </Dropzone>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </div>
                        )}

                        <Card>
                            <CardBody>
                                {this.state.step == 1 && (
                                    <Row>
                                        <Col lg={8} mg={8} sm={8}>
                                        </Col>
                                        <Col lg={2} mg={2} sm={2}>
                                        </Col>
                                        <Col lg={2} mg={2} sm={2}>
                                            <Button onClick={() => { this.change_step(1); }} style={{ width: '100%', backgroundColor: '#224AAC', borderColor: '#224AAC' }}>ต่อไป</Button>
                                        </Col>
                                    </Row>
                                )}
                                {this.state.step == 2 && (
                                    <Row>
                                        <Col lg={8} mg={8} sm={8}>
                                        </Col>
                                        <Col lg={2} mg={2} sm={2}>
                                            <Button onClick={() => { this.change_step(-1); }} style={{ width: '100%', backgroundColor: '#E7E7E7', borderColor: '#E7E7E7', }}><a style={{ color: '#FFFFFF' }}>ย้อนกลับ</a></Button>
                                        </Col>
                                        <Col lg={2} mg={2} sm={2}>
                                            <Button onClick={() => { this.change_step(1); }} style={{ width: '100%', backgroundColor: '#224AAC', borderColor: '#224AAC' }}>ต่อไป</Button>
                                        </Col>
                                    </Row>
                                )}
                                {this.state.step == 3 && (
                                    <Row>
                                        <Col lg={8} mg={8} sm={8}>
                                        </Col>
                                        <Col lg={2} mg={2} sm={2}>
                                            <Button onClick={() => { this.change_step(-1); }} style={{ width: '100%', backgroundColor: '#E7E7E7', borderColor: '#E7E7E7', }}><a style={{ color: '#FFFFFF' }}>ย้อนกลับ</a></Button>
                                        </Col>
                                        <Col lg={2} mg={2} sm={2}>
                                            <Button onClick={() => { this.change_step(1); }} style={{ width: '100%', backgroundColor: '#224AAC', borderColor: '#224AAC' }}>ต่อไป</Button>

                                        </Col>
                                    </Row>
                                )}
                                {this.state.step == 4 && (
                                    <div>
                                        <Label for="kycfirstname-input" style={{ color: "darkblue" }}>ข้อมูลบริษัทนำเที่ยว :</Label>
                                        <Row>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <Label for="agencyName">ชื่อบริษัทนำเที่ยวจากต่างประเทศ</Label>
                                                    <Input disabled={true} readOnly type="text" className="form-control" id="agencyName" value={this.state.step_1.external_agencys} onChange={(e) => { this.handle_change(e, 'step_1', 'external_agencys') }} />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <Label for="tourCode">รหัสทัวร์</Label>
                                                    <Input disabled={true} readOnly type="text" className="form-control" id="external_agencys_code" value={this.state.step_1.external_agencys_code} onChange={(e) => { this.handle_change(e, 'step_1', 'external_agencys_code') }} />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">

                                                <FormGroup>
                                                    <Label for="joborder_start" >วันเริ่มต้นแผนการท่องเที่ยว</Label>
                                                    <Input disabled={true} readOnly type="date" className="form-control" id="joborder_start" value={this.state.step_1.joborder_start} onChange={(e) => { this.handle_change(e, 'step_1', 'joborder_start') }} />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <Label for="joborder_stop">วันสิ้นสุดแผนการท่องเที่ยว</Label>
                                                    <Input disabled={true} readOnly type="date" className="form-control" id="joborder_stop" value={this.state.step_1.joborder_stop} onChange={(e) => { this.handle_change(e, 'step_1', 'joborder_stop') }} />
                                                </FormGroup>
                                            </Col>
                                        </Row>


                                        <Label for="kycfirstname-input" style={{ color: "darkblue" }}>ช่องทางที่คณะท่องเที่ยวเดินทางมาถึง :</Label>

                                        <Row>
                                            <Col lg="3">
                                                <FormGroup>
                                                    <Label for="from">เดินทางโดย</Label>
                                                    <Input type="select" className="form-control"
                                                        disabled={true} readOnly
                                                        value={this.state.step_1.inbound_type}
                                                        onChange={e => this.setState({ step_1: { ...this.state.step_1, inbound_type: e.target.value } })} >
                                                        <option value="1">เครื่องบิน</option>
                                                        <option value="2">รถ</option>
                                                        <option value="3">เรือ</option>
                                                        <option value="4">อื่นๆ</option>
                                                        <option value="5">เดินทางภายในประเทศ</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col lg="3">
                                                <FormGroup>
                                                    <Label for="flightCode">เที่ยวบินที่/รถทะเบียนเลขที่/ชื่อเรือ</Label>
                                                    <Input type="text" className="form-control" placeholder="กรุณากรอก"
                                                        disabled={true} readOnly
                                                        value={(this.state.step_1.inbound_type === '5') ? '' : this.state.step_1.inbound_no}
                                                        onChange={e => this.setState({ step_1: { ...this.state.step_1, inbound_no: e.target.value } })}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="3">
                                                <FormGroup>
                                                    <Label for="flightDate">วันที่เดินทางมาถึง</Label>
                                                    <Input type="date" className="form-control" placeholder="กรุณากรอก วัน/เดือน/ปี"
                                                        disabled={true} readOnly
                                                        value={(this.state.step_1.inbound_type === '5' || this.state.step_1.inbound_date === '1970-01-01') ? '' : this.state.step_1.inbound_date}
                                                        onChange={e => this.setState({ step_1: { ...this.state.step_1, inbound_date: e.target.value } })}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="3">
                                                <FormGroup>
                                                    <Label for="from">เดินทางจากประเทศ</Label>
                                                    <Input type="text" className="form-control" placeholder="กรุณาระบุประเทศต้นทาง"
                                                        disabled={true} readOnly
                                                        value={(this.state.step_1.inbound_type === '5') ? '' : this.state.step_1.inbound_country}
                                                        onChange={e => this.setState({ step_1: { ...this.state.step_1, inbound_country: e.target.value } })}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Label for="kycfirstname-input" style={{ color: "darkblue" }}>ช่องทางที่คณะท่องเที่ยวเดินทางกลับ :</Label>

                                        <Row>
                                            <Col lg="3">
                                                <FormGroup>
                                                    <Label for="from">เดินทางโดย</Label>
                                                    <Input type="select" className="form-control"
                                                        disabled={true} readOnly
                                                        value={this.state.step_1.outbound_type}
                                                        onChange={e => this.setState({ step_1: { ...this.state.step_1, outbound_type: e.target.value } })} >
                                                        <option value="1">เครื่องบิน</option>
                                                        <option value="2">รถ</option>
                                                        <option value="3">เรือ</option>
                                                        <option value="4">อื่นๆ</option>
                                                        <option value="5">เดินทางภายในประเทศ</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col lg="3">
                                                <FormGroup>
                                                    <Label for="flightCode">เที่ยวบินที่/รถทะเบียนเลขที่/ชื่อเรือ</Label>
                                                    <Input type="text" className="form-control" placeholder="กรุณากรอก"
                                                        disabled={true} readOnly
                                                        value={(this.state.step_1.outbound_type === '5') ? '' : this.state.step_1.outbound_no}
                                                        onChange={e => this.setState({ step_1: { ...this.state.step_1, outbound_no: e.target.value } })}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="3">
                                                <FormGroup>
                                                    <Label for="flightDate">วันที่เดินทางกลับ</Label>
                                                    <Input type="date" className="form-control" placeholder="กรุณากรอก วัน/เดือน/ปี"
                                                        disabled={true} readOnly
                                                        value={(this.state.step_1.outbound_type === '5' || this.state.step_1.outbound_date === '1970-01-01') ? '' : this.state.step_1.outbound_date}
                                                        onChange={e => this.setState({ step_1: { ...this.state.step_1, outbound_date: e.target.value } })}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="3">
                                                <FormGroup>
                                                    <Label for="from">เดินทางกลับประเทศ</Label>
                                                    <Input type="text" className="form-control" placeholder="กรุณาระบุประเทศปลายทาง"
                                                        disabled={true} readOnly
                                                        value={(this.state.step_1.outbound_type === '5') ? '' : this.state.step_1.outbound_country}
                                                        onChange={e => this.setState({ step_1: { ...this.state.step_1, outbound_country: e.target.value } })}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row style={{ marginTop: "20px" }}>
                                            <Col lg={6} mg={6} sm={6}>
                                            </Col>
                                            <Col lg={2} mg={2} sm={2}>
                                                <Button onClick={() => { this.change_step(-1); }} style={{ width: '100%', backgroundColor: '#E7E7E7', borderColor: '#E7E7E7', }}><a style={{ color: '#FFFFFF' }}>ย้อนกลับ</a></Button>
                                            </Col>
                                            <Col lg={2} mg={2} sm={2}>
                                                <Button onClick={async () => {
                                                    try {
                                                        var summary = await this.summary_joborder()
                                                        Swal.fire({
                                                            title: 'ยืนยันหรือไม่ ?',
                                                            text: "ยืนยันการ" + this.state.mode + "ใบงานหรือไม่ ",
                                                            icon: 'warning',
                                                            showCancelButton: true,
                                                            confirmButtonColor: '#3085d6',
                                                            cancelButtonColor: '#d33',
                                                            cancelButtonText: 'ยกเลิก',
                                                            confirmButtonText: 'ยืนยัน!'
                                                        }).then((result) => {
                                                            if (result.isConfirmed) {
                                                                this.submit_joborder(summary)
                                                            }
                                                        })
                                                    } catch (err) {
                                                        console.log(err)
                                                        Swal.fire(
                                                            'เตือน !',
                                                            'พบข้อผิดพลาดในการสรุปข้อมูล ( 99 )',
                                                            'error'
                                                        )
                                                    }
                                                }} style={{ width: '100%', backgroundColor: '#224AAC', borderColor: '#224AAC' }}>ยืนยัน{this.state.mode}ใบงาน</Button>
                                            </Col>

                                            <Col lg={2} mg={2} sm={2}>
                                                <Button onClick={async (event) => {
                                                    let summary1 = this.state.step_1
                                                    let summary2 = this.state.step_2
                                                    let summary3 = this.state.step_3
                                                    // if (summary == undefined) {
                                                    //     summary = this.state.step_1
                                                    // }
                                                    await localStorage.setItem('step_1', JSON.stringify(summary1))
                                                    await localStorage.setItem('step_2', JSON.stringify(summary2))
                                                    await localStorage.setItem('step_3', JSON.stringify(summary3))

                                                    window.open('/Savedoc')
                                                }
                                                } style={{ width: '100%', backgroundColor: '#22AD00', borderColor: '#22AD00' }}>มุมมองเอกสาร</Button>
                                            </Col>
                                        </Row>
                                    </div>




                                )}



                            </CardBody>
                        </Card>
                    </div>
                </div >
            </React.Fragment >
        )
    }
}
