import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { API_ENDPOINT } from '../../../../assets/api'
import DataGrid, {
    Column,
    SearchPanel,
    FilterRow,
    HeaderFilter,
    GroupPanel,
    Pager, Paging,
    FilterPanel,
    FilterBuilderPopup,
    Scrolling
} from 'devextreme-react/data-grid';
import { Button, Container, Form, Card, CardBody, Row, Col, Input } from "reactstrap";
import { Link } from "react-router-dom";
const allowedPageSizes = [5, 10];

export default class Tabletrips extends Component {
    constructor(props) {
        super(props);
        this.state = {

            activeTab: '1',
            isLoading: true,
            tripData: []
        }
        // this.toggleTab = this.toggleTab.bind(this);
        this.getTrips = this.getTrips.bind(this);
        this.auth = JSON.parse(localStorage.getItem('authToken'))

    }

    // toggleTab(tab) {
    //     if (this.state.activeTab !== tab) {
    //         this.setState({
    //             activeTab: tab
    //         });
    //     }
    // }

    async getTrips() {
        var response = await fetch(API_ENDPOINT + 'api/v2/web/tour/get', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: this.auth.data.type + ' ' + this.auth.data.token,

            }
        });
        var responseJson = await response.json();
        console.log(responseJson)
        this.setState({
            tripData: responseJson,
            isLoading: false
        })
    }

    componentDidMount() {
        this.getTrips()
    }

    render() {
        return (
            <div>
                <DataGrid
                    dataSource={this.state.tripData}
                    showBorders={true}
                    showColumnLines={true}
                    rowAlternationEnabled={true}
                    // mode={"offline"}
                    //serversideSource={'https://js.devexpress.com/Demos/WidgetsGalleryDataService/api/orders'}
                    // tableId={0}
                    // user_id={0}
                    selectedCallback={this.selectedCallback}
                    initialCallback={this.tableInitial}
                    selection={{ mode: 'single' }}
                    columnMinWidth={50}
                    columnAutoWidth={true}
                    allowColumnResizing={true}


                >
                    <Pager
                        allowedPageSizes={allowedPageSizes}
                        showPageSizeSelector={true}
                        showNavigationButtons={true}
                    />
                    <Paging defaultPageSize={10} />
                    <GroupPanel visible={true} />
                    <FilterRow visible={this.state.showFilterRow}
                        applyFilter={this.state.currentFilter} />
                    <HeaderFilter visible={this.state.showHeaderFilter} />
                    <SearchPanel visible={true}
                        width={240}
                        placeholder="Search..." />
                    <Column dataField="id" />
                    <Column dataField="name" />
                    <Column dataField="description" />
                    <Column dataField="max_recommend" />
                    <Column dataField="min_recommend" />
                    <Column dataField="tripMinMax" />
                    <Column dataField="visible" />
                    {/* <Paging defaultPageSize={10} />
                    <GroupPanel visible={true} />
                    <FilterRow visible={this.state.showFilterRow}
                        applyFilter={this.state.currentFilter} />
                    <HeaderFilter visible={this.state.showHeaderFilter} />
                    <SearchPanel visible={true}
                        width={240}
                        placeholder="Search..." />
                    <Column dataField={"photo"}
                        width={120}
                        cellRender={this.calculateCellphoto}
                        alignment='center'
                    />
                    <Column dataField={"name"}
                        calculateCellValue={this.calculateCellValue}
                    />
                    <Column dataField="address" />

                    <Column dataField="Detail"
                        type="buttons"

                        cellRender={this.renderMyCommand}>


                    </Column> */}

                </DataGrid>
            </div>
        )
    }
}
