import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { API_ENDPOINT } from '../../assets/api';
import { logoutUser } from '../../store/actions';

class Logout extends Component {
    /**
     * Redirect to login
     */
    componentDidMount = async () => {
        // emit the event
        if (!localStorage.getItem("authToken")) {
            return window.location = '/login'
        }
        var token = JSON.parse(localStorage.getItem("authToken"))
        this.props.logoutUser(this.props.history);
        const auth_check_response = await fetch(API_ENDPOINT + "auth/logout", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.data.type + " " + token.data.token,
            }
        });
        // window.location = "/login"
    };

    render() {
        return <React.Fragment></React.Fragment>;
    }
}

export default withRouter(
    connect(
        null,
        { logoutUser }
    )(Logout)
);
