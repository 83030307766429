import React, { Component } from 'react'
//import PropTypes from 'prop-types'
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Table, Media, Form, FormGroup, Label, Input, Button, UncontrolledTooltip, Container } from "reactstrap";
import DataGrid, {
    Column,
    SearchPanel,
    FilterRow,
    HeaderFilter,
    GroupPanel,
    Pager, Paging,
    FilterPanel,
    FilterBuilderPopup,
    Scrolling,
    Export
} from 'devextreme-react/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import Select from "react-select";
import { API_ENDPOINT } from "../../../assets/api";
import Swal from 'sweetalert2'

const allowedPageSizes = [5, 10];
const optionGroup = [
    {
        label: "Picnic",
        options: [
            { label: "Mustard", value: "Mustard" },
            { label: "Ketchup", value: "Ketchup" },
            { label: "Relish", value: "Relish" }
        ]
    },

];
export default class Tablereport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedGroup: null,
        }
        this.handleSelectGroup = this.handleSelectGroup.bind(this);
        this.guide_name = React.createRef()
        this.guide_license = React.createRef()
        this.agency_name = React.createRef()
        this.nation = React.createRef()
        this.place_name = React.createRef()



    };
    handleSelectGroup = selectedGroup => {
        this.setState({ selectedGroup });
    };
    async fetch_tourists() {
        var period = this.props.period
        var selected_period_start = this.props.selected_period_start
        var selected_period_end = this.props.selected_period_end
        var auth = JSON.parse(await localStorage.getItem('authToken'))
        var body = {
            period: period
        }
        if (period == 'custom') {
            if (selected_period_start == '' || selected_period_end == '') {
                Swal.fire(
                    'ล้มเลหว !',
                    'กรุณาเลือกช่วงเวลาเริ่มต้น และเวลาสิ้นสุด',
                    'error'
                )
                return
            }
            body.selected_period_start = selected_period_start
            body.selected_period_end = selected_period_end
        }
        if (this.guide_name.current.value != '') {
            body.guide_name = this.guide_name.current.value
        }
        if (this.guide_license.current.value != '') {
            body.guide_license = this.guide_license.current.value
        }

        if (this.agency_name.current.value != '') {
            body.agency_name = this.agency_name.current.value
        }

        if (this.nation.current.value != '') {
            body.nation = this.nation.current.value
        }
        var url = API_ENDPOINT + 'api/v2/web/report/tourists/get'
        var dev_url = 'https://joborder-api.dot.go.th/api/v2/web/report/tourists/get'
        var response = await fetch(url, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: auth.data.type + ' ' + auth.data.token,
            },
            body: JSON.stringify(body)

        });
        var response_object = await response.json();
        if (response_object.status == 'success') {
            this.setState({
                tourists: response_object.tourists
            })
        } else {
            Swal.fire(
                'ล้มเลหว !',
                'ไม่สามารถดึงข้อมูลนักท่องเที่ยวได้ ',
                'error'
            )
            this.setState({
                tourists: []
            })
        }
    }
    componentDidMount() {
        this.fetch_tourists()
    }
    render() {
        const { selectedGroup } = this.state;
        return (
            <React.Fragment>


                <Row>
                    <Col lg="3">
                        <FormGroup>
                            <Label>ค้นหาจากชื่อ- นามสกุล มัคคุเทศก์ :</Label>
                            <Input type="text" innerRef={this.guide_name} className="form-control" placeholder="ชื่อ- นามสกุล มัคคุเทศก์" />
                        </FormGroup>
                    </Col>
                    <Col lg="3">
                        <FormGroup>
                            <Label>ค้นหาจากเลขที่ใบอนุญาตมัคคุเทศก์</Label>
                            <Input type="text" innerRef={this.guide_license} className="form-control" placeholder="หมายเลขอ้างอิงใบสั่งงาน" />
                        </FormGroup>
                    </Col>
                    <Col lg="3">
                        <FormGroup>
                            <Label>ค้นหาชื่อผู้ประกอบธุรกิจนำเที่ยว</Label>
                            <Input type="text" innerRef={this.agency_name} className="form-control" placeholder="ชื่อผู้ประกอบธุรกิจนำเที่ยว" />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg="3">
                        <FormGroup>
                            <Label>ชื่อประเทศต้นทางของนักท่องเที่ยว</Label>
                            <Input type="text" innerRef={this.nation} className="form-control" placeholder="ชื่อประเทศต้นทาง" />
                        </FormGroup>
                    </Col>
                    <Col lg="3">

                        <Row>
                            <Col xl={5} lg={5} md={6}>
                                <FormGroup className="mt-1 mb-0">
                                    <Button onClick={() => { this.fetch_tourists() }} className="mt-2" style={{ width: '100%', backgroundColor: '#224AAC', borderColor: '#224AAC' }}>ค้นหา</Button>
                                </FormGroup>
                            </Col>
                            <Col xl={7} lg={7} md={6}>
                                <FormGroup className="mt-1 mb-0">
                                    <Button className="mt-2" style={{ width: '100%', backgroundColor: '#9B9B9B', borderColor: '#9B9B9B' }}>ล้างการค้นหา</Button>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                </Row >
                <hr></hr>
                <DataGrid
                    onExporting={this.onExporting}
                    dataSource={this.state.tourists}
                    showBorders={true}
                    showColumnLines={true}
                    rowAlternationEnabled={true}
                    selectedCallback={this.selectedCallback}
                    initialCallback={this.tableInitial}
                    selection={{ mode: 'single' }}
                    columnMinWidth={50}
                    columnAutoWidth={true}
                    allowColumnResizing={true}

                >
                    <Pager
                        allowedPageSizes={allowedPageSizes}
                        showPageSizeSelector={true}
                        showNavigationButtons={true}
                    />
                    <Paging defaultPageSize={10} />
                    <GroupPanel visible={true} />
                    <FilterRow visible={this.state.showFilterRow}
                        applyFilter={this.state.currentFilter} />
                    <HeaderFilter visible={this.state.showHeaderFilter} />
                    <SearchPanel visible={true}
                        width={240}
                        placeholder="Search..." />
                    {/* <Column dataField="id"
                        width={50} />

                    <Column caption={"Tour Code"} dataField="tourCode" /> */}
                    <Column caption={"Passport"} dataField="passport" />
                    <Column caption={"Firstname"} dataField="fname" />
                    <Column caption={"Lastname"} dataField="lname" />
                    <Export enabled={true} allowExportSelectedData={true} />

                </DataGrid>

            </React.Fragment >
        )
    }
}
