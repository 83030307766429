
import { api_endpoint } from './endpoint'
// Gets the logged in user data from local session 
const getLoggedInUser = () => {
    const user = localStorage.getItem('user');
    if (user)
        return JSON.parse(user);
    return null;
}

//is user is logged in
const isUserAuthenticated = () => {
    return getLoggedInUser() !== null;
}


const postLogin = async (url, data) => {
    var object = {
        username: data.username,
        password: data.password,
        application_id: 1
    }
    console.log(object)
    const response = await fetch(api_endpoint + url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(object) // body data type must match "Content-Type" header
    });
    return await response.json();
}

const getAccount = async (url, data) => {
    const response = await fetch(api_endpoint + url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `${data.type} ${data.token}`
        }
    });
    return await response.json();
}

export { postLogin, getAccount, isUserAuthenticated, getLoggedInUser } 