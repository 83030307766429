import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Row, Col, Breadcrumb, BreadcrumbItem } from "reactstrap";
import '../../assets/scss/custom.scss'
class Breadcrumbs extends Component {


    render() {
        return (
            <React.Fragment>
                {/* <Row>
                    <Col xl={4} lg={4} md={6} style={{ marginTop: '1rem' }}>
                        <h4 className="font-size-18 cl-black">{this.props.breadcrumbItem}</h4>
                        <Breadcrumb style={{ marginTop: '-0.5rem' }}>
                            <BreadcrumbItem>
                                <Link to="#" className="font-style-14 cl-black">{this.props.title}</Link>
                            </BreadcrumbItem>
                            <BreadcrumbItem active>
                                <Link to="#" className="font-style-14 cl-black">{this.props.breadcrumbItem}</Link>
                            </BreadcrumbItem>
                        </Breadcrumb>
                    </Col>
                    <Col xl={8} lg={4} md={6} style={{ marginTop: '1.5rem' }}>
                        <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <button type="button" className="btn btn-primary waves-effect waves-light"
                                style={{ width: 150, background: 'transparent', borderColor: '#c2c2c2', margin: '0px 20px' }}>
                                <span className="font-style-16 cl-gray">ดูรายละเอียด</span>
                            </button>
                            <button type="button" className="btn btn-primary waves-effect waves-light" style={{ width: 150 }}>
                                <span className="font-style-16">ปุ่มหลัก</span>
                            </button>
                        </Row>
                    </Col>
                </Row> */}

                {/* <Row>
                    <Col xs="12" style={{ marginTop: '1rem' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}> */}
                {/* <Row>
                    <Col xs="12"> */}
                <div style={{ display: 'flex', alignItems: 'center', margin: '15px 0px 15px 0px' }}>
                    <h4 className="mb-0 font-size-18 cl-black">{this.props.breadcrumbItem}</h4>
                </div>
                {/* </Col>
                </Row> */}
                {/* <div style={{ display: 'flex', alignItems: 'center', margin: '15px 0px 15px 0px' }}>
                    <h4 className="font-size-18 cl-black" >{this.props.breadcrumbItem}</h4>
                </div> */}
                {/* <div className="row" style={{ margin: 0 }}>
                                <button type="button" className="btn btn-primary waves-effect waves-light"
                                    style={{ width: 150, background: 'transparent', borderColor: '#c2c2c2', margin: '0px 20px' }}>

                                    <span className="font-style-16 cl-gray">ดูรายละเอียด</span>
                                </button>
                                <button type="button" className="btn btn-primary waves-effect waves-light" style={{ width: 150 }}
                                >
                                    <span className="font-style-16">ปุ่มหลัก</span>
                                </button>
                            </div> */}
                {/* </div>
                    </Col>
                </Row> */}
                {/* <Row style={{ marginTop: '-1rem' }}>
                    <Col xs={12}>
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <Link to="#" className="font-style-14 cl-black">{this.props.title}</Link>
                            </BreadcrumbItem>
                            <BreadcrumbItem active>
                                <Link to="#" className="font-style-14 cl-black">{this.props.breadcrumbItem}</Link>
                            </BreadcrumbItem>
                        </Breadcrumb>
                    </Col>
                </Row> */}
            </React.Fragment>
        );
    }
}

export default Breadcrumbs;