import React, { Component } from 'react';
import { Row, Col, Card, CardBody } from "reactstrap";
import ReactApexChart from 'react-apexcharts';

class Touristregion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            series: [56, 38, 26],
            options: {
                labels: ["Series A", "Series B", "Series C"],
                colors: ["#556ee6", "#34c38f", "#f46a6a"],
                legend: { show: !1 },
                plotOptions: {
                    pie: {
                        donut: {
                            size: "70%"
                        }
                    },
                }
            }
        };
    }
    render() {
        return (
            <React.Fragment>

                <Card>
                    <CardBody>
                        <h4 className="card-title mb-4">ภูมิภาคนักนักท่องเที่ยว</h4>
                        <Row>
                            <Col xl={6}>
                                <div id="donut-chart" className="apex-charts">
                                    <ReactApexChart options={this.state.options} series={this.state.series} type="donut" height={240} />
                                </div>
                            </Col>
                            <Col xl={6}>
                                <div className="text-muted">
                                    <Row>
                                        <Col xl="6">
                                            <div className="mt-4">
                                                <p className="mb-2 text-truncate"><i className="mdi mdi-circle text-primary mr-1"></i> ยุโรป</p>
                                                <h5>2,132</h5>
                                            </div>
                                        </Col>
                                        <Col xl="6">
                                            <div className="mt-4">
                                                <p className="mb-2 text-truncate"><i className="mdi mdi-circle text-success mr-1"></i>อเมริกาเหนือ</p>
                                                <h5>1,763</h5>
                                            </div>
                                        </Col>
                                        <Col xl="6">
                                            <div className="mt-4">
                                                <p className="mb-2 text-truncate"><i className="mdi mdi-circle text-danger mr-1"></i> แอฟริกา</p>
                                                <h5>973</h5>
                                            </div>
                                        </Col>
                                        <Col xl="6">
                                            <div className="mt-4">
                                                <p className="mb-2 text-truncate"><i className="mdi mdi-circle text-primary mr-1"></i>อเมริกาใต้</p>
                                                <h5>2,132</h5>
                                            </div>
                                        </Col>
                                        <Col xl="6">
                                            <div className="mt-4">
                                                <p className="mb-2 text-truncate"><i className="mdi mdi-circle text-success mr-1"></i>เอเชีย</p>
                                                <h5>1,763</h5>
                                            </div>
                                        </Col>
                                        <Col xl="6">
                                            <div className="mt-4">
                                                <p className="mb-2 text-truncate"><i className="mdi mdi-circle text-danger mr-1"></i>แอนตาร์กติกา</p>
                                                <h5>973</h5>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>


                    </CardBody>
                </Card>

            </React.Fragment>
        );
    }
}

export default Touristregion;