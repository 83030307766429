import React, { Component } from 'react';
import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";

import ReactApexChart from "./ChartStatistics"
import { withTranslation } from 'react-i18next';

class Statistics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            series: [
                { name: "นักท่องเที่ยว", data: [11, 40, 46, 51, 49, 72, 69, 56, 68, 82, 68, 76] },
                { name: "ใบสั่งงาน", data: [21, 40, 46, 51, 49, 72, 69, 56, 68, 82, 68, 76] },
                { name: "สถานที่ท่องเที่ยว", data: [31, 50, 36, 51, 49, 72, 69, 56, 68, 82, 68, 76] }
            ],
            options: {
                chart: {
                    toolbar: "false",
                    dropShadow: {
                        enabled: !0,
                        color: "#000",
                        top: 18,
                        left: 7,
                        blur: 8,
                        opacity: .2
                    }
                },
                dataLabels: {
                    enabled: !1
                },
                colors: ["#556ee6"],
                stroke: {
                    curve: "smooth",
                    width: 3
                }
            }
        };
    }

    render() {
        return (
            <React.Fragment>

                <Card>
                    <CardBody>
                        <Row>
                            <Col xl="3">
                                <div className="text-muted">
                                    <div className="mb-4">
                                        <h3 style={{ color: '#224AAC' }}>{this.props.tourist_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h3>
                                        <h5 style={{ color: 'black' }}>{this.props.t('Number of tourists')}</h5>
                                    </div>
                                    <div className="mb-4">
                                        <h3 style={{ color: '#224AAC' }}>{this.props.joborder_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h3>
                                        <h5 style={{ color: 'black' }}>{this.props.t('Number Joborder')}</h5>
                                    </div>
                                    <div >
                                        <h3 style={{ color: '#224AAC' }}>{this.props.places_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h3>
                                        <h5 style={{ color: 'black' }}>จำนวนสถานที่ท่องที่ยว</h5>
                                    </div>
                                </div>
                            </Col>
                            <Col xl="9">
                                <div id="line-chart" className="apex-charts" dir="ltr">
                                    <ReactApexChart series={this.state.series} options={this.state.options} type="line" height={500} />
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>

            </React.Fragment>
        );
    }
}

export default (withTranslation()(Statistics));