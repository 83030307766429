import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Col, Row, Card, CardBody, CardTitle, CardSubtitle, CardImg, CardText, CardHeader, CardImgOverlay, CardFooter, CardDeck, CardColumns, Container, Button, Carousel, CarouselItem, CarouselControl, CarouselIndicators } from "reactstrap";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import Swal from 'sweetalert2'
// import check from '../../../assets/images/icon/check.svg';
import { Prompt } from 'react-router';
// import choss from "../../../assets/images/icon/c.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

export default class Cardtimeperiodcopy extends Component {

    constructor(props) {

        super(props);
        console.log(props)
        this.state = {

            showtext: {
                index: -1,
                text: 'เลือกแล้ว !'
            },
            showimage: {
                index: -1,
                image: '/assets/images/icon/c.png'
            },
            showborder: {
                index: -1,
                border: 5
            }


        };
        // this.cardSelected = this.cardSelected.bind(this);
    }

    // cardSelected(e){
    //     this.props.cardSelected(e)
    // }



    render() {

        const { data } = this.props;
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            adaptiveHeight: true
        };
        return (
            <div className="row" >
                <div className="col-12" id="main" onSwiper={(swiper) => console.log(swiper)} onClick={(swiper, event) => {
                    console.log(swiper)
                    if (swiper.clickedSlide == undefined) {
                        console.log('clickedSlide undefined')
                        return
                    }
                    else {
                        if (swiper.clickedSlide.innerText.trim() == 'แผนการท่องเที่ยว 1 วัน') {
                            this.props.cardSelected(1)
                            this.setState({
                                showtext: {
                                    ...this.state.showtext,
                                    index: 0,
                                },
                                showimage: {
                                    ...this.state.showimage,
                                    index: 0
                                },
                                showborder: {
                                    ...this.state.showborder,
                                    indew: 0
                                }


                            })

                        }
                        if (swiper.clickedSlide.innerText.trim() == '1-2 คืน') {
                            this.props.cardSelected(2)
                            this.setState({
                                showtext: {
                                    ...this.state.showtext,
                                    index: 1
                                },
                                showimage: {
                                    ...this.state.showimage,
                                    index: 1
                                }

                            })

                        }
                        if (swiper.clickedSlide.innerText.trim() == '3-4 คืน') {
                            this.props.cardSelected(4)
                            this.setState({
                                showtext: {
                                    ...this.state.showtext,
                                    index: 2
                                },
                                showimage: {
                                    ...this.state.showimage,
                                    index: 2
                                }

                            })
                        }
                        if (swiper.clickedSlide.innerText.trim() == 'แผนการท่องเที่ยวระยะยาว') {
                            this.props.cardSelected(5)
                            Swal.fire({
                                title: 'กรุณากำหนดช่วงเวลาที่ต้องการ !',
                                text: "กรอกจำนวนวันที่ต้องการลงในช่องว่าง !",
                                input: 'number',
                                inputAttributes: {
                                    min: 6,
                                    max: 30
                                },
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'ยืนยัน',
                                cancelButtonText: 'ยกเลิก'
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    if (isNaN(result.value) == true || result.value == "") {
                                        Swal.fire(
                                            'ผิดพลาด !',
                                            'กรุณากรอกจำนวนวันที่ต้องการ !',
                                            'warning'
                                        )

                                    }
                                    else {
                                        Swal.fire(
                                            'สำเร็จ !',
                                            'จำนวนวันที่คุณเลือกคือ ' + result.value + ' วัน',
                                            'success'

                                        )
                                        this.props.cardSelected(result.value)
                                        this.setState({
                                            showtext: {
                                                ...this.state.showtext,
                                                index: 3
                                            }
                                        })
                                    }
                                }
                                else {
                                    return
                                }
                            })
                            // this.props.cardSelected(6)
                        }
                    }


                }}>
                    <Card style={{ padding: 20 }}>
                        <CardBody>
                            <Slider {...settings} >
                                {data.map((Item, index) => {
                                    return (
                                        <div key={index} >
                                            <label className="card-radio-label mb-2" >
                                                <input type="radio" name="timeperiod" id="timeperiod1" className="card-radio-input" />
                                                <div className="card-radio" key={index} style={{ width: '18rem', height: '21rem', borderRadius: 10, borderWidth: 5 }}>
                                                    <div style={{ textAlign: 'center', marginBottom: '2rem', padding: 20 }}>
                                                        <CardImg top className="img-fluid" src={Item.src} style={{ width: '10rem', height: '11rem' }} />
                                                        {/* <img src={Item.src} alt="" style={{ width: '10rem', height: '11rem' }} /> */}
                                                        <CardImgOverlay >
                                                            <div style={{ paddingTop: 53 }}>
                                                                {/* {this.state.showtext.index == index ? <p style={{ color: '#F4D006', fontWeight: 'bold', transform: 'rotate(-50deg)', fontSize: '5rem' }} >{this.state.showtext.text}</p> : null} */}
                                                                {this.state.showimage.index == index ? <img src={this.state.showimage.image} alt="" style={{ width: '90%', }} /> : null}
                                                            </div>
                                                        </CardImgOverlay>
                                                    </div>
                                                    <div style={{ textAlign: "center" }}  >
                                                        <h3 style={{ color: "black", fontWeight: 'bold' }}>{Item.title}</h3>
                                                    </div>
                                                </div>
                                            </label>
                                        </div>
                                    )
                                }
                                )
                                }
                            </Slider>
                        </CardBody>
                    </Card>
                </div>
            </div >
            // <Swiper
            //     breakpoints={{
            //         200: {
            //             width: 500,
            //             slidesPerView: 1,

            //         },
            //         // when window width is >= 640px
            //         640: {
            //             width: 640,
            //             slidesPerView: 1,

            //         },
            //         768: {
            //             width: 768,
            //             slidesPerView: 2,
            //         },
            //         960: {
            //             width: 820,
            //             slidesPerView: 2,
            //         },
            //         1800: {
            //             width: 900,
            //             slidesPerView: 2,
            //         },
            //         1900: {
            //             width: 920,
            //             slidesPerView: 2,
            //         },
            //         2000: {
            //             width: 1000,
            //             slidesPerView: 2,
            //         },
            //         2100: {
            //             width: 1100,
            //             slidesPerView: 2,
            //         },
            //         2200: {
            //             width: 1100,
            //             slidesPerView: 2,
            //         },
            //         2400: {
            //             width: 1200,
            //             slidesPerView: 2,
            //         },
            //         2600: {
            //             width: 1300,
            //             slidesPerView: 2,
            //         },
            //         2800: {
            //             width: 1500,
            //             slidesPerView: 2,
            //         },
            //         3000: {
            //             width: 1500,
            //             slidesPerView: 2,
            //         },

            //     }}
            //     id="main"
            //     width="480"
            //     loop={true}
            //     spaceBetween={5}
            //     slidesPerView={'auto'}
            //     navigation
            //     // pagination={{ clickable: true }}
            //     // scrollbar={{ draggable: true }}
            //     onSwiper={(swiper) => console.log(swiper)}
            //     onSlideChange={() => console.log('slide change')}
            //     spaceBetween={5}
            //     slidesPerView={'auto'}
            //     onClick={(swiper, event) => {
            //         if (swiper.clickedSlide == undefined) {
            //             console.log('clickedSlide undefined')
            //             return
            //         }
            //         else {
            //             if (swiper.clickedSlide.innerText.trim() == 'แผนการท่องเที่ยว 1 วัน') {
            //                 this.props.cardSelected(1)
            //                 this.setState({
            //                     showtext: {
            //                         ...this.state.showtext,
            //                         index: 0,
            //                     },
            //                     showimage: {
            //                         ...this.state.showimage,
            //                         index: 0
            //                     },
            //                     showborder: {
            //                         ...this.state.showborder,
            //                         indew: 0
            //                     }


            //                 })

            //             }
            //             if (swiper.clickedSlide.innerText.trim() == '1-2 คืน') {
            //                 this.props.cardSelected(2)
            //                 this.setState({
            //                     showtext: {
            //                         ...this.state.showtext,
            //                         index: 1
            //                     },
            //                     showimage: {
            //                         ...this.state.showimage,
            //                         index: 1
            //                     }

            //                 })

            //             }
            //             if (swiper.clickedSlide.innerText.trim() == '3-4 คืน') {
            //                 this.props.cardSelected(4)
            //                 this.setState({
            //                     showtext: {
            //                         ...this.state.showtext,
            //                         index: 2
            //                     },
            //                     showimage: {
            //                         ...this.state.showimage,
            //                         index: 2
            //                     }

            //                 })
            //             }
            //             if (swiper.clickedSlide.innerText.trim() == 'แผนการท่องเที่ยวระยะยาว') {
            //                 this.props.cardSelected(5)
            //                 Swal.fire({
            //                     title: 'กรุณากำหนดช่วงเวลาที่ต้องการ !',
            //                     text: "กรอกจำนวนวันที่ต้องการลงในช่องว่าง !",
            //                     input: 'number',
            //                     inputAttributes: {
            //                         min: 6,
            //                         max: 30
            //                     },
            //                     icon: 'warning',
            //                     showCancelButton: true,
            //                     confirmButtonColor: '#3085d6',
            //                     cancelButtonColor: '#d33',
            //                     confirmButtonText: 'ยืนยัน',
            //                     cancelButtonText: 'ยกเลิก'
            //                 }).then((result) => {
            //                     if (result.isConfirmed) {
            //                         if (isNaN(result.value) == true || result.value == "") {
            //                             Swal.fire(
            //                                 'ผิดพลาด !',
            //                                 'กรุณากรอกจำนวนวันที่ต้องการ !',
            //                                 'warning'
            //                             )

            //                         }
            //                         else {
            //                             Swal.fire(
            //                                 'สำเร็จ !',
            //                                 'จำนวนวันที่คุณเลือกคือ ' + result.value + ' วัน',
            //                                 'success'

            //                             )
            //                             this.props.cardSelected(result.value)
            //                             this.setState({
            //                                 showtext: {
            //                                     ...this.state.showtext,
            //                                     index: 3
            //                                 }
            //                             })
            //                         }
            //                     }
            //                     else {
            //                         return
            //                     }
            //                 })
            //                 // this.props.cardSelected(6)
            //             }
            //         }


            //     }}
            // >
            //     {data.map((Item, index) =>
            //         <SwiperSlide key={index}  >


            //             <label className="card-radio-label mb-2" >
            //                 <input type="radio" name="timeperiod" id="timeperiod1" className="card-radio-input" />
            //                 <Col lg={12} mg={12} sm={12} className="card-radio" key={index} style={{ width: '18rem', height: '21rem', borderRadius: 10, borderWidth: 5 }}
            //                 >
            //                     {/* <div style={{ right: 20, top: 20, position: 'absolute' }}>
            //                             {this.state.showimage.index == index ? <img src={this.state.showimage.image} alt="" /> : null}
            //                         </div> */}

            //                     <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
            //                         <CardImg top className="img-fluid" src={Item.src} style={{ width: '10rem', height: '11rem' }} />
            //                         {/* <img src={Item.src} alt="" style={{ width: '10rem', height: '11rem' }} /> */}
            //                         <CardImgOverlay >
            //                             <div style={{ paddingTop: 53 }}>

            //                                 {/* {this.state.showtext.index == index ? <p style={{ color: '#F4D006', fontWeight: 'bold', transform: 'rotate(-50deg)', fontSize: '5rem' }} >{this.state.showtext.text}</p> : null} */}
            //                                 {this.state.showimage.index == index ? <img src={this.state.showimage.image} alt="" style={{ width: '90%', }} /> : null}

            //                             </div>

            //                         </CardImgOverlay>

            //                     </div>

            //                     <div style={{ textAlign: "center" }}  >
            //                         <h3 style={{ color: "black", fontWeight: 'bold' }}>{Item.title}</h3>
            //                     </div>
            //                 </Col>
            //                 {/* <div style={{ textAlign: 'center', paddingTop: 20 }}>
            //                         {this.state.showtext.index == index ? <h3 style={{ color: '#224AAC', fontWeight: 'bold' }}>{this.state.showtext.text}</h3> : null}
            //                     </div> */}
            //             </label>

            //         </SwiperSlide>

            //     )
            //     }

            // </Swiper>

        );
    }
}

//             