import React, { Component } from 'react';
import { Row, CardTitle, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from "reactstrap";
// import sun from "../../../../assets/images/icon/sun.svg"
// import night from "../../../../assets/images/icon/night.svg"
// import cloudy from "../../../../assets/images/icon/cloudy.svg"
import '../../../../assets/scss/custom.scss'
class RenderCardTitle extends Component {

    render() {
        console.log(this.props)
        return (
            <React.Fragment>
                {/* <Button onClick={() => { this.props.modalCallback(this.props.title, true) }}>Add</Button> */}
                <UncontrolledDropdown className="float-right">
                    <DropdownToggle href="#" className="arrow-none" tag="i">
                        <i className="mdi mdi-dots-vertical m-0 text-muted h5"></i>
                    </DropdownToggle>
                    <DropdownMenu right >
                        <DropdownItem href="#">Edit</DropdownItem>
                        <DropdownItem href="#">Delete</DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
                <Row style={{ margin: '20px 20px', borderBottom: "2px solid white" }}>
                    {/* <div style={{ paddingLeft: 10 }}>
                        <img className="" alt="Skote" src={column.icon} />
                    </div> */}
                    <div>
                        <div className="font-style-18" style={{ color: 'white' }}>{this.props.title}</div>
                    </div>
                </Row>
            </React.Fragment>
        );
    }
}

export default RenderCardTitle;