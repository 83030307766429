import React, { Component } from 'react'
//import PropTypes from 'prop-types'
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Table, Media, Form, FormGroup, Label, Input, Button, UncontrolledTooltip, Container } from "reactstrap";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Statistics from './Component/Statistics'
import Touristregion from './Component/Touristregion'
import Tablereport from './Component/Tablereport'
import './ReportStyle.scss';
import { Link } from "react-router-dom";
import Printer, { print } from 'react-pdf-print'

import Lottie from "react-lottie";
import FadeIn from "react-fade-in";
import * as loadingData from "../Joborder/men-component/loading.json";
import { API_ENDPOINT } from "../../assets/api";
import Swal from 'sweetalert2'
//i18n
import { withTranslation } from 'react-i18next';
import RangeTab from './Component/RangeTab';
import TypeTab from './Component/TypeTab';
import CountryGrid from './Component/CountryGrid'
import AttractionGrid from './Component/AttractionGrid'
import AccommodationGrid from './Component/AccommodationGrid'
import GuideGrid from './Component/GuideGrid'
import AgencyGrid from './Component/AgencyGrid'
import StaffGrid from './Component/StaffGrid'
import moment from 'moment'

import DataGrid, {
    Column,
    SearchPanel,
    FilterRow,
    HeaderFilter,
    GroupPanel,
    Pager, Paging,
    FilterPanel,
    FilterBuilderPopup,
    Scrolling,
    Export
} from 'devextreme-react/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import TrendsTab from './Component/TrendsTab';
import JobOrderCountTab from './Component/JobOrderCountTab'
import Search from './Component/Search';
import GraphTab from './Component/GraphTab';
import ParetoChart from './Component/ParetoChart';
import NetworkChart from './Component/NetworkChart';
import Maps from './Component/Map';
import DoughnutChart from './Component/DoughnutChart';


const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingData.default,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};


const ids = ['1']
class Report extends Component {
    /*static propTypes = {
         prop: PropTypes
     }*/
    constructor(props) {
        super(props);
        this.state = {
            fetch_report: false,
            error: true,
            report: {
                type: '',
                value: 0,
                filter: 'country',
                start_date: '',
                end_date: '',
                view: '',
                garph: '',
                search: '',
                pareto_data: []
            },
            report_heat: {
                job_count: 0,
                tourists_adults: 0,
                tourists_all: 0,
                tourists_children: 0,
                tourists_followers: 0,
                heat_data: [],
                region_data: [],
                error: false
            }

        }
        this.callback = this.callback.bind(this);
    };




    callback(target, data) {
        var obj = this.state.report
        console.log("OBJ", obj)
        switch (target) {
            case "date":
                if (obj.value === data.value && (obj.start_date === data.selected_period_start || obj.end_date === data.selected_period_end)) { return }
                obj.value = data.value

                if (data.value !== 'custom') {
                    obj.start_date = ''
                    obj.end_date = ''
                }
                else {
                    obj.start_date = data.selected_period_start
                    obj.end_date = data.selected_period_end
                }
                this.setState({
                    report: obj
                }, () => {
                    if (obj.type === 'tourists-report-count' && obj.value === 'custom') {
                        this.setState({
                            fetch_report: true
                        }, () => {
                            console.log("getdata")
                            this.getData()
                        })
                    }
                    if (obj.type === 'heat-map' && obj.value !== 'custom') {
                        this.setState({
                            fetch_report: true
                        }, () => {
                            this.getData()
                        })

                    }
                    else {
                        if (obj.type === 'heat-map' && obj.start_date !== '' && obj.end_date !== '' && obj.value === 'custom') {
                            this.setState({
                                fetch_report: true
                            }, () => {
                                this.getData()
                            })

                        }
                        else {

                            return
                        }

                    }
                })
                break;
            case "filter":
                obj.filter = data
                this.setState({
                    report: obj
                })
                break;
            case "view":
                obj.view = data
                this.setState({
                    report: obj
                })
                if (obj.type === 'tourists-report-count') {
                    this.setState({
                        fetch_report: true
                    }, () => {
                        console.log("getdata")
                        this.getData()
                    })
                }
                break;
            case "garph":
                obj.garph = data
                this.setState({
                    report: obj
                })
                break;
            case "search":
                obj.search = data
                this.setState({
                    report: obj
                }, () => {

                })
                break;
            default:
                return
        }
        if (this.state.report.search !== "" && obj.type !== 'heat-map') { this.getData() }
    }

    getHeader() {
        var pathname = window.location.pathname;
        var obj = this.state.report
        switch (pathname) {
            case "/tourists-report-count":
                obj.type = "tourists-report-count"
                return this.props.t('จำนวนการออกใบ Job Order')
            case "/tourists-report":
                obj.type = "tourists-report"
                return this.props.t('Tourists Report')
            case "/tourism-trends":
                obj.type = "tourism-trends"
                return this.props.t('Tourism Trends')
            case "/data-relationship":
                obj.type = "data-relationship"
                return this.props.t('Data Relationship')
            case "/staff-performance":
                obj.type = "staff-performance"
                return this.props.t('Staff Performance')
            case "/heat-map":
                obj.type = "heat-map"
                return this.props.t('Heat Map')
            default:
                return this.props.t('Report')
        }
    }

    onExporting(e) {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');

        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
            autoFilterEnabled: true
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
            });
        });
        e.cancel = true;
    }



    async getData() {
        var path = "report/trending/country"
        var body = {
            startdate: this.state.report.start_date,
            enddate: this.state.report.end_date,
            period: this.state.report.view,
            country: this.state.report.search
        }
        if (this.state.report.filter === 'attraction') {
            path = "report/trending/places";
            body = {
                startdate: this.state.report.start_date,
                enddate: this.state.report.end_date,
                period: this.state.report.view,
                places: this.state.report.search
            }
        }
        if (this.state.report.filter === 'accommodation') {
            path = "report/trending/accommodation";
            body = {
                startdate: this.state.report.start_date,
                enddate: this.state.report.end_date,
                period: this.state.report.view,
                places: this.state.report.search
            }
        }
        if (this.state.report.filter === 'country-attraction') {
            path = "report/relation/country_places";
            body = {
                startdate: this.state.report.start_date,
                enddate: this.state.report.end_date,
                period: this.state.report.value,
                country: this.state.report.search
            }
        }
        if (this.state.report.filter === 'attraction-country') {
            path = "report/relation/places_country";
            body = {
                startdate: this.state.report.start_date,
                enddate: this.state.report.end_date,
                period: this.state.report.value,
                places: this.state.report.search
            }
        }
        if (this.state.report.filter === 'country-accommodation') {
            path = "report/relation/country_accommodation";
            body = {
                startdate: this.state.report.start_date,
                enddate: this.state.report.end_date,
                period: this.state.report.value,
                country: this.state.report.search
            }
        }
        if (this.state.report.filter === 'accommodation-country') {
            path = "report/relation/accommodation_country";
            body = {
                startdate: this.state.report.start_date,
                enddate: this.state.report.end_date,
                period: this.state.report.value,
                places: this.state.report.search
            }
        }
        console.log("asdasdas-------")
        if (this.state.report.type === 'tourists-report-count' && this.state.report.value === 'custom' && (this.state.report.start_date === '' || this.state.report.end_date === '')) { return }
        if (this.state.report.type === 'tourists-report-count') {
            console.log("asdasdas")
            path = 'report/joborder/count'
            var object = {
                startdate: this.state.report.start_date,
                enddate: this.state.report.end_date,
                period: this.state.report.view
            }
            var response = await fetch(API_ENDPOINT + path, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(object)
            })
            var _result = await response.json()
            console.log("result", _result)
            if (Array.isArray(_result)) {
                let obj = this.state.report
                obj.pareto_data = _result
                this.setState({
                    report: obj,
                    fetch_report: false
                })
            }
            else {
                console.log('error : ' + _result)
                let obj = this.state.report
                obj.pareto_data = []
                this.setState({
                    report: obj
                })
            }
        }
        if (this.state.report.type === 'heat-map' && this.state.report.value === 'custom' && (this.state.report.start_date === '' || this.state.report.end_date === '')) { return }
        if (this.state.report.type === 'heat-map') {
            path = 'api/v2/web/joborder/heatmap/get?period=' + this.state.report.value;
            if (this.state.report.value === 'custom') {
                path = 'api/v2/web/joborder/heatmap/get?period=' + this.state.report.value + '&startdate=' + this.state.report.start_date + '&enddate=' + this.state.report.end_date;
            }
            var auth = JSON.parse(localStorage.getItem('authToken'))
            const response = await fetch(API_ENDPOINT + path, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: auth.data.type + ' ' + auth.data.token,
                },
            });

            if (response.status !== 200) {
                let obj = this.state.report_heat
                obj.error = true
                this.setState({
                    fetch_report: false,
                    report_heat: obj
                }, () => { return })
            }
            var data = await response.json()
            console.log("data", data)
            var job_data = []
            var region_data = []
            let obj = this.state.report_heat
            let tourists_adults = 0
            let tourists_all = 0
            let tourists_children = 0
            let tourists_followers = 0
            if (data.joborders !== undefined && data.regions !== undefined) {
                for (let i = 0; i < data.joborders.length; i++) {
                    tourists_adults = Number(tourists_adults) + Number(data.joborders[i].tourists_adults)
                    tourists_all = Number(tourists_all) + Number(data.joborders[i].tourists_all)
                    tourists_children = Number(tourists_children) + Number(data.joborders[i].tourists_children)
                    tourists_followers = Number(tourists_followers) + Number(data.joborders[i].tourists_followers)
                    for (let j = 0; j < data.joborders[i].tripGuide.day.length; j++) {
                        if (data.joborders[i].tripGuide.day[j].daypart !== undefined) {
                            if (data.joborders[i].tripGuide.day[j].daypart.length > 0) {
                                for (let k = 0; k < data.joborders[i].tripGuide.day[j].daypart.length; k++) {
                                    for (let l = 0; l < data.joborders[i].tripGuide.day[j].daypart[k].place.length; l++) {
                                        job_data.push({
                                            lat: parseFloat(data.joborders[i].tripGuide.day[j].daypart[k].place[l].latitude),
                                            lng: parseFloat(data.joborders[i].tripGuide.day[j].daypart[k].place[l].longitude)
                                        })
                                    }
                                }

                            }
                            else {
                                continue
                            }
                        }
                    }
                }
                for (let i = 0; i < data.regions.length; i++) {
                    let fillColor = "#8A3FFC"
                    if (data.regions[i].name === 'ภาคตะวันออกเฉียงเหนือ') { fillColor = "#33B1FF" }
                    if (data.regions[i].name === 'ภาคกลาง') { fillColor = "#007D79" }
                    if (data.regions[i].name === 'ภาคตะวันออก') { fillColor = "#FF7EB6" }
                    if (data.regions[i].name === 'ภาคตะวันตก') { fillColor = "#6FDC8C" }
                    if (data.regions[i].name === 'ภาคใต้') { fillColor = "#FA4D56" }
                    region_data.push({
                        x: data.regions[i].name,
                        value: data.regions[i].count,
                        normal: {
                            fill: fillColor
                        },
                        hovered: {
                            fill: fillColor,
                            outline: {
                                enabled: true,
                                width: 3,
                                fill: "#404040",
                                stroke: null
                            }
                        },
                        selected: {
                            outline: {
                                enabled: true,
                                width: 3,
                                fill: "#404040",
                                stroke: null
                            }
                        }
                    })
                }
                obj.heat_data = job_data
                obj.job_count = data.joborders.length
                obj.tourists_adults = tourists_adults
                obj.tourists_all = tourists_all
                obj.tourists_children = tourists_children
                obj.tourists_followers = tourists_followers
                obj.region_data = region_data
                this.setState({
                    report_heat: obj,
                    fetch_report: false
                }, () => {
                    console.log(this.state.report_heat)
                })
            }
            else {
                console.log('error')
                let obj = this.state.report_heat
                obj.error = true
                this.setState({
                    fetch_report: false,
                    report_heat: obj
                })
            }

        }
        else {
            const response = await fetch(API_ENDPOINT + path, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            });
            var data = await response.json()
            if (Array.isArray(data)) {
                let obj = this.state.report
                obj.pareto_data = data
                this.setState({
                    report: obj
                })
            }
            else {
                console.log('error : ' + data)
                let obj = this.state.report
                obj.pareto_data = []
                this.setState({
                    report: obj
                })
            }
        }

        // if (Array.isArray(data) && data.length > 0) {
        //     let obj = this.state.report
        //     obj.pareto_data = data
        //     this.setState({
        //         report: obj
        //     })
        // }
        // else {
        //     console.log('error')
        // }
    }

    printInvoice() {
        window.print();
    }




    componentDidMount() {
        // if (this.state.report.type === 'heat-map') { this.setState({ fetch_report: true }, () => { this.getData() }) }
    }


    render() {

        return (
            <React.Fragment>
                <div className="page-content" id={ids[0]}>
                    <Container fluid>
                        <Row>
                            <Col xs="12">
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Breadcrumbs title={this.getHeader()} breadcrumbItem={this.getHeader()} />
                                    <div className="d-print-none">
                                        <div className="float-right">
                                            <Link to="#" onClick={this.printInvoice} className="btn btn-success waves-effect waves-light mr-2"><i className="fa fa-print"></i></Link>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Printer>
                            {/* <Row className="mb-3">
                                <Col xl={12} lg={12} md={12} sm={12} style={{ display: 'inline-flex', marginTop: 5 }}>
                                    <span style={{ display: 'none' }}>{this.getHeader()}</span>
                                </Col>
                            </Row> */}
                            {this.state.report.type === 'tourists-report-count' &&
                                <>
                                    <Card className="card-shadow" style={{ marginTop: '1.5rem' }}>
                                        <CardBody>
                                            <JobOrderCountTab callback={this.callback}></JobOrderCountTab>
                                        </CardBody>
                                    </Card>
                                    <Row>
                                        <Col lg={12} >
                                            <Card className="card-shadow" style={{ height: '60vh' }}>
                                                <CardBody>
                                                    {this.state.fetch_report === true ?
                                                        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', height: '100%' }}>
                                                            <Lottie options={defaultOptions} height={200} width={200} />
                                                            <div style={{ fontSize: 16 }}>กำลังค้นหา</div>
                                                        </div>
                                                        :
                                                        <>
                                                            {this.state.report.pareto_data.length < 1 ?
                                                                <>
                                                                    <Card className="card-shadow" style={{ marginTop: '1.5rem' }}>
                                                                        <CardBody>
                                                                            <span>ผลลัพธ์การค้นหา " {this.state.report.search} " : <span style={{ color: 'red' }}>ไม่พบข้อมูลที่ค้นหาในช่วงเวลาหรือรูปแบบที่กำหนด</span></span>
                                                                        </CardBody>
                                                                    </Card>
                                                                </>
                                                                :
                                                                <Card className="card-shadow" style={{ marginTop: '1.5rem' }}>
                                                                    <CardBody>
                                                                        <ParetoChart report={this.state.report}></ParetoChart>
                                                                    </CardBody>
                                                                </Card>
                                                            }
                                                        </>
                                                    }
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </>
                            }
                            {this.state.report.type === 'tourists-report' &&
                                <>
                                    <Card className="card-shadow" style={{ marginTop: '1.5rem' }}>
                                        <CardBody>
                                            {/* <Row className="mb-3">
                                                <Col xl={10} lg={10} md={10} sm={10} xs={10} style={{ display: 'inline-flex', borderBottomWidth: 1, borderBottomStyle: 'solid', borderBlockColor: '#E6E6E6', paddingBottom: 15, marginBottom: 10 }}>
                                                    <span style={{ fontSize: 20 }}>{this.getHeader()}</span>
                                                </Col>
                                                <Col xl={2} lg={2} md={2} sm={2} xs={2} style={{ display: 'inline-flex', borderBottomWidth: 1, borderBottomStyle: 'solid', borderBlockColor: '#E6E6E6', paddingBottom: 15, marginBottom: 10, justifyContent: 'flex-end' }}>
                                                    <div className="d-print-none">
                                                        <div className="float-right">
                                                            <Link to="#" onClick={this.printInvoice} className="btn btn-success waves-effect waves-light mr-2"><i className="fa fa-print"></i></Link>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row> */}
                                            <RangeTab callback={this.callback}></RangeTab>
                                            <TypeTab callback={this.callback} type={this.state.report.type}></TypeTab>
                                        </CardBody>
                                    </Card>
                                    {
                                        this.state.fetch_report ?
                                            <Card className="card-shadow" style={{ marginTop: '1.5rem' }}>
                                                <CardBody>
                                                    <Row >
                                                        <Col>
                                                            <div style={{ marginTop: 50 }} className="text-center">
                                                                <FadeIn>
                                                                    <div style={{ display: "flex", justifyContent: 'center' }}>
                                                                        <Card>
                                                                            <CardBody>
                                                                                <Lottie options={defaultOptions} height={300} width={300} />
                                                                                <div>
                                                                                    <h4 style={{ color: 'black', padding: 30 }}>กำลังเตรียมข้อมูล กรุณารอสักครู่</h4>
                                                                                </div>
                                                                            </CardBody>
                                                                        </Card>

                                                                    </div>
                                                                </FadeIn>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                            :
                                            <Card className="card-shadow" style={{ marginTop: '1.5rem' }}>
                                                <CardBody style={{ background: 'white' }}>
                                                    <Row>
                                                        {/* {this.state.report.type}<br></br>
                                                filter :{this.state.report.filter}<br></br>
                                                day :{this.state.report.value}<br></br>
                                                start :  {this.state.report.start_date}<br></br>
                                                end :  {this.state.report.end_date}<br></br> */}
                                                        <Col xl={12} lg={12} md={12} sm={12} style={{ display: 'inline-flex' }}>
                                                            <Card>
                                                                <CardBody>
                                                                    {this.state.report.filter === 'country' && <CountryGrid report={this.state.report}></CountryGrid>}
                                                                    {this.state.report.filter === 'attraction' && <AttractionGrid report={this.state.report}></AttractionGrid>}
                                                                    {this.state.report.filter === 'accommodation' && <AccommodationGrid report={this.state.report}></AccommodationGrid>}
                                                                    {this.state.report.filter === 'guide' && <GuideGrid report={this.state.report}></GuideGrid>}
                                                                    {this.state.report.filter === 'agency' && <AgencyGrid report={this.state.report}></AgencyGrid>}
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                    }
                                </>
                            }
                            {this.state.report.type === 'tourism-trends' &&
                                <>
                                    <Card className="card-shadow" style={{ marginTop: '1.5rem' }}>
                                        <CardBody>
                                            {/* <Row className="mb-3">
                                                <Col xl={10} lg={10} md={10} sm={10} xs={10} style={{ display: 'inline-flex', borderBottomWidth: 1, borderBottomStyle: 'solid', borderBlockColor: '#E6E6E6', paddingBottom: 15, marginBottom: 10 }}>
                                                    <span style={{ fontSize: 20 }}>{this.getHeader()}</span>
                                                </Col>
                                                <Col xl={2} lg={2} md={2} sm={2} xs={2} style={{ display: 'inline-flex', borderBottomWidth: 1, borderBottomStyle: 'solid', borderBlockColor: '#E6E6E6', paddingBottom: 15, marginBottom: 10, justifyContent: 'flex-end' }}>
                                                    <div className="d-print-none">
                                                        <div className="float-right">
                                                            <Link to="#" onClick={this.printInvoice} className="btn btn-success waves-effect waves-light mr-2"><i className="fa fa-print"></i></Link>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row> */}
                                            <TrendsTab callback={this.callback}></TrendsTab>
                                            <TypeTab callback={this.callback} type={this.state.report.type}></TypeTab>
                                            <Search callback={this.callback}></Search>
                                        </CardBody>
                                    </Card>
                                    {this.state.report.search === '' || this.state.report.search === null ?
                                        null
                                        :
                                        <>
                                            {/* {this.state.report.type}<br></br>
                                            filter :{this.state.report.filter}<br></br>
                                            view :{this.state.report.view}<br></br>
                                            start :  {this.state.report.start_date}<br></br>
                                            end :  {this.state.report.end_date}<br></br>
                                            search :  {this.state.report.search}<br></br> */}
                                            {this.state.report.pareto_data.length < 1 ?
                                                <>
                                                    <Card className="card-shadow" style={{ marginTop: '1.5rem' }}>
                                                        <CardBody>
                                                            <span>ผลลัพธ์การค้นหา " {this.state.report.search} " : <span style={{ color: 'red' }}>ไม่พบข้อมูลที่ค้นหาในช่วงเวลาหรือรูปแบบที่กำหนด</span></span>
                                                        </CardBody>
                                                    </Card>
                                                </>
                                                :
                                                <Card className="card-shadow" style={{ marginTop: '1.5rem' }}>
                                                    <CardBody>
                                                        <ParetoChart report={this.state.report}></ParetoChart>
                                                    </CardBody>
                                                </Card>
                                            }
                                        </>
                                    }
                                </>
                            }
                            {this.state.report.type === 'data-relationship' &&
                                <>
                                    <Card className="card-shadow" style={{ marginTop: '1.5rem' }}>
                                        <CardBody>
                                            {/* <Row className="mb-3">
                                                <Col xl={10} lg={10} md={10} sm={10} xs={10} style={{ display: 'inline-flex', borderBottomWidth: 1, borderBottomStyle: 'solid', borderBlockColor: '#E6E6E6', paddingBottom: 15, marginBottom: 10 }}>
                                                    <span style={{ fontSize: 20 }}>{this.getHeader()}</span>
                                                </Col>
                                                <Col xl={2} lg={2} md={2} sm={2} xs={2} style={{ display: 'inline-flex', borderBottomWidth: 1, borderBottomStyle: 'solid', borderBlockColor: '#E6E6E6', paddingBottom: 15, marginBottom: 10, justifyContent: 'flex-end' }}>
                                                    <div className="d-print-none">
                                                        <div className="float-right">
                                                            <Link to="#" onClick={this.printInvoice} className="btn btn-success waves-effect waves-light mr-2"><i className="fa fa-print"></i></Link>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row> */}
                                            <RangeTab callback={this.callback}></RangeTab>
                                            <TypeTab callback={this.callback} type={this.state.report.type}></TypeTab>
                                            <GraphTab callback={this.callback}></GraphTab>
                                            <Search callback={this.callback}></Search>
                                        </CardBody>
                                    </Card>
                                    {this.state.report.search === '' || this.state.report.search === null ?
                                        null
                                        :
                                        <>
                                            {/* {this.state.report.type}<br></br>
                                            filter :{this.state.report.filter}<br></br>
                                            day :{this.state.report.value}<br></br>
                                            start :  {this.state.report.start_date}<br></br>
                                            end :  {this.state.report.end_date}<br></br>
                                            garph :  {this.state.report.garph}<br></br>
                                            search :  {this.state.report.search}<br></br> */}
                                            {this.state.report.pareto_data.length < 1 ?
                                                <>
                                                    <Card className="card-shadow" style={{ marginTop: '1.5rem' }}>
                                                        <CardBody>
                                                            <span>ผลลัพธ์การค้นหา " {this.state.report.search} " : <span style={{ color: 'red' }}>ไม่พบข้อมูลที่ค้นหาในช่วงเวลาหรือรูปแบบที่กำหนด</span></span>
                                                        </CardBody>
                                                    </Card>
                                                </>
                                                :
                                                <Card className="card-shadow" style={{ marginTop: '1.5rem' }}>
                                                    <CardBody>
                                                        {this.state.report.garph === 'pareto' && <ParetoChart report={this.state.report}></ParetoChart>}
                                                        {this.state.report.garph === 'network' && <NetworkChart report={this.state.report}></NetworkChart>}
                                                    </CardBody>
                                                </Card>
                                            }
                                        </>
                                    }
                                </>
                            }
                            {this.state.report.type === 'staff-performance' &&
                                <>
                                    <Card className="card-shadow" style={{ marginTop: '1.5rem' }}>
                                        <CardBody>
                                            {/* <Row className="mb-3">
                                                <Col xl={10} lg={10} md={10} sm={10} xs={10} style={{ display: 'inline-flex', borderBottomWidth: 1, borderBottomStyle: 'solid', borderBlockColor: '#E6E6E6', paddingBottom: 15, marginBottom: 10 }}>
                                                    <span style={{ fontSize: 20 }}>{this.getHeader()}</span>
                                                </Col>
                                                <Col xl={2} lg={2} md={2} sm={2} xs={2} style={{ display: 'inline-flex', borderBottomWidth: 1, borderBottomStyle: 'solid', borderBlockColor: '#E6E6E6', paddingBottom: 15, marginBottom: 10, justifyContent: 'flex-end' }}>
                                                    <div className="d-print-none">
                                                        <div className="float-right">
                                                            <Link to="#" onClick={this.printInvoice} className="btn btn-success waves-effect waves-light mr-2"><i className="fa fa-print"></i></Link>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row> */}
                                            <RangeTab callback={this.callback}></RangeTab>
                                        </CardBody>
                                    </Card>
                                    {this.state.fetch_report ?
                                        <Card className="card-shadow" style={{ marginTop: '1.5rem' }}>
                                            <CardBody>
                                                <Row >
                                                    <Col>
                                                        <div style={{ marginTop: 50 }} className="text-center">
                                                            <FadeIn>
                                                                <div style={{ display: "flex", justifyContent: 'center' }}>
                                                                    <Card>
                                                                        <CardBody>
                                                                            <Lottie options={defaultOptions} height={300} width={300} />
                                                                            <div>
                                                                                <h4 style={{ color: 'black', padding: 30 }}>กำลังเตรียมข้อมูล กรุณารอสักครู่</h4>
                                                                            </div>
                                                                        </CardBody>
                                                                    </Card>

                                                                </div>
                                                            </FadeIn>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                        :
                                        <Card className="card-shadow" style={{ marginTop: '1.5rem' }}>
                                            <CardBody>
                                                <Row>
                                                    <Col xl={12} lg={12} md={12} sm={12} style={{ display: 'inline-flex' }}>
                                                        <Card>
                                                            <CardBody>
                                                                <StaffGrid report={this.state.report}></StaffGrid>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    }
                                </>
                            }
                            {this.state.report.type === 'heat-map' &&
                                <>
                                    <Card className="card-shadow" style={{ marginTop: '1.5rem' }}>
                                        <CardBody>
                                            {/* <Row className="mb-3">
                                                <Col xl={10} lg={10} md={10} sm={10} xs={10} style={{ display: 'inline-flex', borderBottomWidth: 1, borderBottomStyle: 'solid', borderBlockColor: '#E6E6E6', paddingBottom: 15, marginBottom: 10 }}>
                                                    <span style={{ fontSize: 20 }}>{this.getHeader()}</span>
                                                </Col>
                                                <Col xl={2} lg={2} md={2} sm={2} xs={2} style={{ display: 'inline-flex', borderBottomWidth: 1, borderBottomStyle: 'solid', borderBlockColor: '#E6E6E6', paddingBottom: 15, marginBottom: 10, justifyContent: 'flex-end' }}>
                                                    <div className="d-print-none">
                                                        <div className="float-right">
                                                            <Link to="#" onClick={this.printInvoice} className="btn btn-success waves-effect waves-light mr-2"><i className="fa fa-print"></i></Link>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row> */}
                                            <RangeTab callback={this.callback}></RangeTab>
                                        </CardBody>
                                    </Card>
                                    <Row>
                                        <Col lg={8}>
                                            <Card className="card-shadow" style={{ height: '60vh' }}>
                                                <CardBody>
                                                    {this.state.fetch_report === true ?
                                                        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', height: '100%' }}>
                                                            <Lottie options={defaultOptions} height={200} width={200} />
                                                            <div style={{ fontSize: 16 }}>กำลังค้นหา</div>
                                                        </div>
                                                        :
                                                        <>
                                                            {this.state.report_heat.error === false ?
                                                                <Maps data={this.state.report_heat.heat_data}></Maps>
                                                                :
                                                                <span >การค้นหาล้มเหลวกรุณาลองใหม่อีกครั้ง</span>
                                                            }
                                                        </>
                                                    }
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col lg={4} >
                                            <Card className="card-shadow" style={{ height: '60vh' }}>
                                                <CardBody>
                                                    {this.state.fetch_report === true ?
                                                        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', height: '100%' }}>
                                                            <Lottie options={defaultOptions} height={200} width={200} />
                                                            <div style={{ fontSize: 16 }}>กำลังค้นหา</div>
                                                        </div>
                                                        :
                                                        <>
                                                            {this.state.report_heat.error === false ?
                                                                <>
                                                                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                                                        <span style={{ fontWeight: 'bold', fontSize: 16 }}>   ผลลัพธ์การค้นหา</span>
                                                                    </Col>
                                                                    <hr></hr>
                                                                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                                                        <span style={{ fontWeight: 'bold', fontSize: 14 }}>   แบ่งตามภูมิภาค</span>
                                                                        <DoughnutChart data={this.state.report_heat.region_data}></DoughnutChart>
                                                                        <Row>
                                                                            <Col xl={4} lg={4} md={4} sm={4} xs={4}>
                                                                                <i class="fa fa-circle" aria-hidden="true" style={{ color: "#8A3FFC" }}></i> เหนือ
                                                                            </Col>
                                                                            <Col xl={5} lg={5} md={5} sm={5} xs={5}>
                                                                                <i class="fa fa-circle" aria-hidden="true" style={{ color: "#33B1FF" }}></i> ตะวันออกเฉียงเหนือ
                                                                            </Col>
                                                                            <Col xl={3} lg={3} md={3} sm={3} xs={3}>
                                                                                <i class="fa fa-circle" aria-hidden="true" style={{ color: "#007D79" }}></i> กลาง
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col xl={4} lg={4} md={4} sm={4} xs={4}>
                                                                                <i class="fa fa-circle" aria-hidden="true" style={{ color: "#FF7EB6" }}></i> ตะวันออก
                                                                            </Col>
                                                                            <Col xl={5} lg={5} md={5} sm={5} xs={5}>
                                                                                <i class="fa fa-circle" aria-hidden="true" style={{ color: "#6FDC8C" }}></i> ตะวันตก
                                                                            </Col>
                                                                            <Col xl={3} lg={3} md={3} sm={3} xs={3}>
                                                                                <i class="fa fa-circle" aria-hidden="true" style={{ color: "#FA4D56" }}></i> ใต้
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    <hr></hr>
                                                                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                                                        <Row>
                                                                            <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                                                                                <span style={{ fontSize: 14 }}>ระยะเวลา : </span>
                                                                            </Col>
                                                                            <Col xl={3} lg={3} md={3} sm={3} xs={3}>
                                                                                <span style={{ fontSize: 14 }}>{this.state.report.value === 'custom' ? moment(new Date(this.state.report.end_date)).diff(new Date(this.state.report.start_date), 'days') : this.state.report.value}</span>
                                                                            </Col>
                                                                            <Col xl={3} lg={3} md={3} sm={3} xs={3}>
                                                                                <span style={{ fontSize: 14 }}>วัน</span>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                                                        <Row>
                                                                            <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                                                                                <span style={{ fontSize: 14 }}>Job Order ทั้งหมด : </span>
                                                                            </Col>
                                                                            <Col xl={3} lg={3} md={3} sm={3} xs={3}>
                                                                                <span style={{ fontSize: 14 }}>{this.state.report_heat.job_count}</span>
                                                                            </Col>
                                                                            <Col xl={3} lg={3} md={3} sm={3} xs={3}>
                                                                                <span style={{ fontSize: 14 }}>งาน</span>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                                                        <Row>
                                                                            <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                                                                                <span style={{ fontSize: 14 }}>ผู้ใหญ่ : </span>
                                                                            </Col>
                                                                            <Col xl={3} lg={3} md={3} sm={3} xs={3}>
                                                                                <span style={{ fontSize: 14 }}>{this.state.report_heat.tourists_adults}</span>
                                                                            </Col>
                                                                            <Col xl={3} lg={3} md={3} sm={3} xs={3}>
                                                                                <span style={{ fontSize: 14 }}>คน</span>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                                                        <Row>
                                                                            <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                                                                                <span style={{ fontSize: 14 }}>เด็ก : </span>
                                                                            </Col>
                                                                            <Col xl={3} lg={3} md={3} sm={3} xs={3}>
                                                                                <span style={{ fontSize: 14 }}>{this.state.report_heat.tourists_children}</span>
                                                                            </Col>
                                                                            <Col xl={3} lg={3} md={3} sm={3} xs={3}>
                                                                                <span style={{ fontSize: 14 }}>คน</span>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                                                        <Row>
                                                                            <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                                                                                <span style={{ fontSize: 14 }}>ผู้ติดตาม : </span>
                                                                            </Col>
                                                                            <Col xl={3} lg={3} md={3} sm={3} xs={3}>
                                                                                <span style={{ fontSize: 14 }}>{this.state.report_heat.tourists_followers}</span>
                                                                            </Col>
                                                                            <Col xl={3} lg={3} md={3} sm={3} xs={3}>
                                                                                <span style={{ fontSize: 14 }}>คน</span>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                                                        <Row>
                                                                            <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                                                                                <span style={{ fontSize: 14 }}>นักท่องเที่ยวทั้งหมด : </span>
                                                                            </Col>
                                                                            <Col xl={3} lg={3} md={3} sm={3} xs={3}>
                                                                                <span style={{ fontSize: 14 }}>{this.state.report_heat.tourists_all}</span>
                                                                            </Col>
                                                                            <Col xl={3} lg={3} md={3} sm={3} xs={3}>
                                                                                <span style={{ fontSize: 14 }}>คน</span>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </>
                                                                :
                                                                <span>การค้นหาล้มเหลวกรุณาลองใหม่อีกครั้ง</span>
                                                            }
                                                        </>
                                                    }
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </>
                            }
                        </Printer>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}
export default withTranslation()(Report);
