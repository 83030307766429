
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col, Input, Button, Card, CardBody, Table, Label, Badge, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip, Pagination, PaginationItem, PaginationLink } from "reactstrap";
import DataGrid, {
    Column,
    SearchPanel,
    FilterRow,
    HeaderFilter,
    GroupPanel,
    Pager, Paging,
    FilterPanel,
    FilterBuilderPopup,
    Scrolling
} from 'devextreme-react/data-grid';
//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { API_ENDPOINT } from "../../../assets/api";


class Tablejoborder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Orders: [


            ],
            modal: false,
        }
        this.togglemodal.bind(this);
        this.auth = JSON.parse(localStorage.getItem('authToken'))
        this.getJoborder = this.getJoborder.bind(this);
    }

    togglemodal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    async getJoborder() {
        var response = await fetch(API_ENDPOINT + 'api/v2/web/joborder/get?take=10&skip=0', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: this.auth.data.type + ' ' + this.auth.data.token,

            }
        });
        var responseJson = await response.json();
        console.log(responseJson)
        this.setState({
            Orders: responseJson,
            isLoading: false
        })
    }

    componentDidMount() {
        this.getJoborder()
    }

    render() {
        if (this.state.isLoading == true)
            return (
                <React.Fragment>
                </React.Fragment>
            )
        else {
            return (
                <React.Fragment>
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <Row className="mb-2">
                                        <Col sm="12" style={{ textAlign: 'right' }}>
                                            <div className="search-box mr-2 mb-2 d-inline-block">
                                                <div className="position-relative">
                                                    <Input type="text" className="form-control" placeholder="Search..." />
                                                    <i className="bx bx-search-alt search-icon"></i>
                                                </div>
                                            </div>
                                        </Col>

                                    </Row>

                                    <div className="table-responsive">
                                        <Table className="table table-centered table-nowrap">
                                            <thead className="thead-light">
                                                <tr>
                                                    <th style={{ width: "20px" }}>
                                                        <div className="custom-control custom-checkbox">
                                                            <Input type="checkbox" className="custom-control-input" id="customCheck1" />
                                                            <Label className="custom-control-label" htmlFor="customCheck1">&nbsp;</Label>
                                                        </div>
                                                    </th>
                                                    <th>Tour Code</th>
                                                    <th>Joborder Start</th>
                                                    <th>Joborder End</th>
                                                    <th>Flight From</th>
                                                    <th>Flight Code</th>
                                                    <th>Arrival Date</th>
                                                    <th>Create by</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.Orders.map((order, key) =>
                                                        <tr key={"_order_" + key}>
                                                            <td>
                                                                <div className="custom-control custom-checkbox">
                                                                    <Input type="checkbox" className="custom-control-input" id={order.id} />
                                                                    <Label className="custom-control-label" htmlFor={order.id}>&nbsp;</Label>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {order.tourCode}
                                                            </td>
                                                            <td>
                                                                {order.joborder_start_date}
                                                            </td>
                                                            <td>
                                                                {order.joborder_stop_date}
                                                            </td>
                                                            <td>
                                                                {order.flightFrom}
                                                            </td>
                                                            <td>
                                                                {order.flightCode}
                                                            </td>
                                                            <td>
                                                                {order.arrival_date}
                                                            </td>
                                                            <td>
                                                                {order.agencyName}
                                                            </td>

                                                            {/* <td>
                                                            <Link to={'/Invoice'}>
                                                                <Button type="button" color="primary" className="btn-sm btn-rounded" >
                                                                    View Details
                                                                    </Button>
                                                            </Link>

                                                        </td>
                                                        <td>
                                                            <Link to="#" className="mr-3 text-primary">
                                                                <i className="mdi mdi-pencil font-size-18 mr-3" id="edittooltip"></i>
                                                                <UncontrolledTooltip placement="top" target="edittooltip">
                                                                    Edit
                                                                        </UncontrolledTooltip >
                                                            </Link>
                                                            <Link to="#" className="text-danger">
                                                                <i className="mdi mdi-close font-size-18 mr-3" id="deletetooltip"></i>
                                                                <UncontrolledTooltip placement="top" target="deletetooltip">
                                                                    Delete
                                                                        </UncontrolledTooltip >
                                                            </Link>
                                                        </td> */}
                                                        </tr>
                                                    )
                                                }

                                            </tbody>
                                        </Table>
                                    </div>
                                    {/* <Pagination className="pagination pagination-rounded justify-content-end mb-2">
                                        <PaginationItem disabled>
                                            <PaginationLink previous href="#" />
                                        </PaginationItem>
                                        <PaginationItem>
                                            <PaginationLink href="#">
                                                1
                                                </PaginationLink>
                                        </PaginationItem>
                                        <PaginationItem active>
                                            <PaginationLink href="#">
                                                2
                                                </PaginationLink>
                                        </PaginationItem>
                                        <PaginationItem>
                                            <PaginationLink href="#">
                                                3
                                                </PaginationLink>
                                        </PaginationItem>
                                        <PaginationItem>
                                            <PaginationLink href="#">
                                                4
                                                </PaginationLink>
                                        </PaginationItem>
                                        <PaginationItem>
                                            <PaginationLink href="#">
                                                5
                                                </PaginationLink>
                                        </PaginationItem>
                                        <PaginationItem>
                                            <PaginationLink next href="#" />
                                        </PaginationItem>
                                    </Pagination> */}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    {/* 
                    <Modal isOpen={this.state.modal} role="dialog" autoFocus={true} centered={true} className="exampleModal" tabindex="-1" toggle={this.togglemodal}>
                        <div className="modal-content">
                            <ModalHeader toggle={this.togglemodal}>
                                Order Details
                            </ModalHeader >
                            <ModalBody>
                                <p className="mb-2">Product id: <span className="text-primary">#SK2540</span></p>
                                <p className="mb-4">Billing Name: <span className="text-primary">Neal Matthews</span></p>

                                <div className="table-responsive">
                                    <Table className="table table-centered table-nowrap">
                                        <thead>
                                            <tr>
                                                <th scope="col">Product</th>
                                                <th scope="col">Product Name</th>
                                                <th scope="col">Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>

                                                <td>
                                                    <div>
                                                        <h5 className="text-truncate font-size-14">Wireless Headphone (Black)</h5>
                                                        <p className="text-muted mb-0">$ 225 x 1</p>
                                                    </div>
                                                </td>
                                                <td>$ 255</td>
                                            </tr>
                                            <tr>

                                                <td>
                                                    <div>
                                                        <h5 className="text-truncate font-size-14">Hoodie (Blue)</h5>
                                                        <p className="text-muted mb-0">$ 145 x 1</p>
                                                    </div>
                                                </td>
                                                <td>$ 145</td>
                                            </tr>
                                            <tr>
                                                <td colspan="2">
                                                    <h6 className="m-0 text-right">Sub Total:</h6>
                                                </td>
                                                <td>
                                                    $ 400
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2">
                                                    <h6 className="m-0 text-right">Shipping:</h6>
                                                </td>
                                                <td>
                                                    Free
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2">
                                                    <h6 className="m-0 text-right">Total:</h6>
                                                </td>
                                                <td>
                                                    $ 400
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button type="button" color="secondary" onClick={this.togglemodal}>Close</Button>
                            </ModalFooter>
                        </div>
                    </Modal> */}
                </React.Fragment>
            );
        }
    }
}

export default Tablejoborder;