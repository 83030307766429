import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { Col, Row, Card, CardBody, CardTitle, CardSubtitle, CardImg, CardText, CardHeader, CardImgOverlay, CardFooter, CardDeck, CardColumns, Container, Button, Progress } from "reactstrap";
import Cardregion from "./components/Cardregion";
import { Link } from "react-router-dom";
import Swal from 'sweetalert2'
import { withTranslation } from 'react-i18next';
import './Trip.scss';

class Groupregion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            tripAdded: []
        }
        this.cardSelected = this.cardSelected.bind(this);
        this.alert = this.alert.bind(this);
        this.removeState = this.removeState.bind(this);
    };
    myRef = React.createRef();
    // getData = async () => {
    //     const res = await fetch('dataregion.json')
    //     const data = await res.json();
    //     console.log(data)
    //     this.setState({ data: data })


    // };

    alert() {
        return (
            Swal.fire(
                'ผิดพลาด !',
                'กรุณาเลือกภูมิภาคที่ต้องการ !',
                'warning'
            )
        )
    }
    removeState() {
        localStorage.removeItem("state");
    }

    cardSelected(e) {
        console.log(e)
        let arr = this.state.tripAdded
        console.log(arr.length)
        if (arr.length = 1) {
            arr.push({
                region: e
            })
            this.setState({
                tripAdded: arr
            }, () => {
                localStorage.setItem('state', JSON.stringify(this.state.tripAdded))
                console.log(this.state.tripAdded)
            })

        }
    }

    componentDidMount() {
        document.getElementById("Trip_navagation").classList.add("active");

        console.log('componentDidMount')
        let lcStorage = localStorage.getItem('state')
        console.log(lcStorage)
        if (typeof lcStorage == 'string') {
            console.log('GOOD')
            this.setState({
                tripAdded: JSON.parse(lcStorage),
                isLoading: false
            }, () => {
                console.log(this.state.tripAdded)
                // this.getData();
            })

        }
        else {
            console.log('BAD')
            // this.getData();
        }
    };

    render() {
        const data = [
            {
                src: "/imagesregion/img1.svg",
                title: "ภาคเหนือ"
            },
            {
                src: "/imagesregion/img2.svg",
                title: "ภาคตะวันออกเฉียงเหนือ"
            },
            {
                src: "/imagesregion/img3.svg",
                title: "ภาคกลาง"
            },
            {
                src: "/imagesregion/img4.svg",
                title: "ภาคใต้"
            },
            {
                src: "/imagesregion/img5.svg",
                title: "กรุงเทพและปริมณทล"
            }
        ]
        return (
            // <React.Fragment>






            //     <Row style={{ alignSelf: 'center', justifyContent: 'center' }}>
            //         <Cardregion data={data} cardSelected={this.cardSelected} />
            //     </Row>
            //     <div>
            //         <div style={{ textAlign: "center", marginTop: 25 }}>
            //             <h5 style={{ color: "black" }}>เลือกภูมิภาคในการเที่ยว</h5>

            //         </div>
            //     </div>




            // </React.Fragment>
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Row style={{ display: 'flex', alignItems: 'center' }}>
                            <Col xl={8} lg={6} md={6} sm={12} xs={12}>
                                <Breadcrumbs title="Trip" breadcrumbItem={this.props.t("Trip")} />
                            </Col>
                            <Col xl={2} lg={3} md={3} sm={12} xs={12}>
                                {this.state.tripAdded.length < 2 ?
                                    <Link to={{
                                        pathname: '/Trip/Addtrip/Selecttimeperiod',
                                        tripAdded: this.state.tripAdded
                                    }}>
                                        <button type="button" className="btn btn-primary waves-effect waves-light"
                                            style={{ width: '100%', backgroundColor: '#E7E7E7', borderColor: '#AAAAAA' }}>
                                            <span className="font-style-16 cl-gray">
                                                {this.props.t('Back')}
                                            </span>
                                        </button>
                                    </Link>
                                    :
                                    <Link to={'/Trip/Addtrip/Selecttimeperiod'}>
                                        <button onClick={() => { this.removeState(); }} type="button" className="btn btn-primary waves-effect waves-light"
                                            style={{ width: '100%', backgroundColor: '#E7E7E7', borderColor: '#AAAAAA' }}>
                                            <span className="font-style-16 cl-gray">
                                                {this.props.t('Back')}
                                            </span>
                                        </button>
                                    </Link>
                                }
                            </Col>
                            <Col xl={2} lg={3} md={3} sm={12} xs={12} className="mr-bt">
                                {this.state.tripAdded.length < 2 ?
                                    <button type="button" className="btn btn-primary waves-effect waves-light"
                                        style={{ width: '100%', backgroundColor: '#E7E7E7', borderColor: '#AAAAAA' }}
                                        onClick={() => { this.alert() }}>
                                        <span className="font-style-16 cl-gray">
                                            {this.props.t('Next')}
                                        </span>
                                    </button>
                                    :
                                    <Link to={{
                                        pathname: '/Trip/Addtrip/Selectinterested',
                                        tripAdded: this.state.tripAdded
                                    }}>
                                        <button type="button" className="btn btn-primary waves-effect waves-light"
                                            style={{ width: '100%', backgroundColor: '#224AAC', borderColor: '#224AAC' }}>
                                            <span className="font-style-16">
                                                {this.props.t("Next")}
                                            </span>
                                        </button>
                                    </Link>
                                }
                            </Col>
                        </Row>
                    </Container>
                    {/* <Card style={{ width: '100%', backgroundColor: '#F0F0F0' }}>
                        <CardBody>
                            <Container fluid>
                                <Row style={{ justifyContent: 'flex-end' }}>
                                    <Col xl={2} lg={3} md={6} sm={12} xs={12} >
                                        {this.state.tripAdded.length < 2 ?
                                            <Link to={{
                                                pathname: '/Trip/Addtrip/Selecttimeperiod',
                                                tripAdded: this.state.tripAdded
                                            }}>
                                                <Button style={{ width: '100%', backgroundColor: '#E7E7E7', borderColor: '#AAAAAA' }}><a style={{ color: '#8C8C8C' }}>{this.props.t('Back')}</a></Button>
                                            </Link>
                                            :
                                            <Link to={'/Trip/Addtrip/Selecttimeperiod'}>
                                                <Button style={{ width: '100%', backgroundColor: '#E7E7E7', borderColor: '#AAAAAA', }}><a style={{ color: '#8C8C8C' }}>{this.props.t('Back')}</a></Button>

                                            </Link>
                                        }
                                    </Col>
                                    <Col xl={2} lg={3} md={6} sm={12} xs={12} >
                                        {this.state.tripAdded.length < 2 ?
                                            <Button className="mt-button-1" style={{ width: '100%', backgroundColor: '#E7E7E7', borderColor: '#AAAAAA' }} onClick={() => { this.alert() }}><a style={{ color: '#8C8C8C' }}>{this.props.t('Next')}</a></Button>
                                            :
                                            <Link to={{
                                                pathname: '/Trip/Addtrip/Selectinterested',
                                                tripAdded: this.state.tripAdded
                                            }}>
                                                <Button className="mt-button-1" style={{ width: '100%', backgroundColor: '#224AAC', borderColor: '#224AAC' }}>{this.props.t('Next')}</Button>

                                            </Link>
                                        }
                                    </Col>
                                </Row>
                            </Container>
                        </CardBody>
                    </Card> */}
                    <Row className="justify-content-center mt-lg-5 row">
                        <Cardregion data={data} cardSelected={this.cardSelected} />
                        <Row style={{ padding: 50, justifyContent: 'center' }} >
                            <h4 style={{ color: '#224AAC' }}> {this.props.t('Step')} 2 / 3 </h4><h4 style={{ marginLeft: 15, color: 'black' }}>{this.props.t('Select the region you want and we will introduce you to the best places to visit in that region')}</h4>
                        </Row>
                    </Row>



                </div>

            </React.Fragment>

        )
    }
}
export default (
    (withTranslation()(Groupregion))
);