import React, { Component } from 'react'
import { Link } from "react-router-dom";
import PropTypes from 'prop-types'
import { Container, Row, Col, Input, Button, Card, CardBody, NavItem, NavLink, Table, Label, Badge, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip, Pagination, PaginationItem, PaginationLink, FormGroup, Form, TabContent, TabPane } from "reactstrap";
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import classnames from 'classnames';
import { API_ENDPOINT } from '../../../assets/api'
import Tabletrips from './TableTrip/Tabletrips';
import { withTranslation } from 'react-i18next';
import TripGrid from '../component/TripGrid';
class TableTrip extends Component {
    constructor(props) {
        super(props);
        this.state = {

            activeTab: '1',
            isLoading: true,
            tripData: []
        }
        this.toggleTab = this.toggleTab.bind(this);
        this.getTrips = this.getTrips.bind(this);
        this.auth = JSON.parse(localStorage.getItem('authToken'))

    }
    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    async getTrips() {
        var response = await fetch(API_ENDPOINT + 'api/v2/web/tour/get', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: this.auth.data.type + ' ' + this.auth.data.token,

            }
        });
        var responseJson = await response.json();
        console.log(responseJson)
        this.setState({
            tripData: responseJson,
            isLoading: false
        })
    }

    componentDidMount() {
        this.getTrips()
    }

    render() {
        if (this.state.isLoading == true) {
            return (
                <div></div>
            )
        }
        else {
            return (
                <React.Fragment>
                    <div className="page-content">
                        <Container fluid>
                            <Row>
                                <Col xs="12">
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Breadcrumbs title="User Management" breadcrumbItem={this.props.t("Trip Management")} />
                                        <Link to={{ pathname: '/Trip/Addtrip' }} style={{ display: 'flex', alignItems: 'center' }}>
                                            <button type="button" className="btn btn-primary waves-effect waves-light" style={{ width: '100%', backgroundColor: '#224AAC', borderColor: '#224AAC' }}>
                                                <i class="fas fa-plus-circle mr-3"></i>
                                                <span className="font-style-16"> {this.props.t('Add New Trip')}</span>
                                            </button>
                                        </Link>
                                    </div>
                                </Col>
                            </Row>

                            {/* <Card className="card-shadow" style={{ marginTop: '1.5rem' }}>
                                <CardBody>
                                    <Row className="mb-4">
                                        <Col xl={3} lg={3} md={6} sm={12} xs={12}>
                                            <FormGroup className="mt-3 mb-0">
                                                <Label>Status</Label>
                                                <select className="form-control select2-search-disable">
                                                    <option value="CO" defaultValue>Completed</option>
                                                    <option value="PE">Pending</option>
                                                </select>
                                            </FormGroup>
                                        </Col>
                                        <Col xl={3} lg={3} md={6} sm={12} xs={12}>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card> */}
                            <Card className="card-shadow" style={{ marginTop: '1.5rem' }}>
                                <CardBody>
                                    <TripGrid />
                                </CardBody>
                            </Card>


                            {/* <Row className="mt-24">
                                                <Col xl={10} lg={9} md={8} sm={8}>
                                                </Col>
                                                <Col xl={2} lg={3} md={4} sm={4}>
                                                    <Link to={{
                                                        pathname: '/Trip/Addtrip'
                                                    }}>
                                                        <Button style={{ width: '100%', backgroundColor: '#224AAC', borderColor: '#224AAC' }}><i class="fas fa-plus-circle mr-3"></i>{this.props.t('Add New Trip')} </Button>
                                                    </Link>

                                                </Col>
                                            </Row>
                                            <hr></hr> */}

                            {/* 
                                    <div className="col-xl col-sm-6">
                                        <FormGroup className="mt-3 mb-0">
                                            <Label>Date :</Label>
                                            <DatePicker
                                                selected={this.state.startDate}
                                                onChange={this.handleChange}
                                                className="form-control"
                                                placeholderText="Select date"
                                            />
                                        </FormGroup>
                                    </div> */}

                            {/* <Col xl={3} lg={3} md={6} sm={12} xs={12}>
                                                    <FormGroup className="mt-3 mb-0">
                                                        <Label>FlightCode</Label>
                                                        <select className="form-control select2-search-disable">
                                                            <option value="BU" defaultValue>TG101</option>
                                                            <option value="SE">TG111</option>
                                                        </select>
                                                    </FormGroup>
                                                </Col> */}


                        </Container>
                    </div>
                </React.Fragment>
            )
        }

    }
}
export default (
    (withTranslation()(TableTrip))
);
