const API_ENDPOINT = "https://joborder-api.dot.go.th/"
const API_DEVEXPRESS_ENDPOINT = "https://joborder-api.dot.go.th/"

const IMAGE_ENDPOINT = "https://joborder.dot.go.th/assets/images"

const API_UPLOAD_ENDPOINT = "https://joborder-api.dot.go.th/"


export {API_ENDPOINT,API_DEVEXPRESS_ENDPOINT,IMAGE_ENDPOINT,API_UPLOAD_ENDPOINT};




