import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { Col, Row, Card, CardBody, CardTitle, CardSubtitle, CardImg, CardText, CardHeader, CardImgOverlay, CardFooter, CardDeck, CardColumns, Container, Button } from "reactstrap";
import img6 from "../../assets/images/small/img-6.jpg";
import img10 from "../../assets/images/small/img-10.png";
import img11 from "../../assets/images/small/img-11.png";
import img13 from "../../assets/images/small/img-13.png";
import img14 from "../../assets/images/small/img-14.png";
// import img15 from "../../assets/images/small/img-trip.png";
// import img16 from "../../assets/images/small/img-trip2.png";
// import place from "../../assets/images/small/place.svg"
// import templates from "../../assets/images/small/templates.svg"
import { Link } from "react-router-dom";
// import upload from "../../assets/images/small/img-trip2.png"
import { withTranslation } from 'react-i18next';

class Addtrip extends Component {
    constructor(props) {
        super(props)
        this.state = {


        }

    }

    static propTypes = {
        prop: PropTypes
    }

    componentDidMount() {
        document.getElementById("Trip_navagation").classList.add("active");

    }

    render() {

        return (
            <React.Fragment>


                <div className="page-content">
                    <div className="container-fluid">
                        <Breadcrumbs title="Trip" breadcrumbItem={this.props.t('Trip')} />
                        <div className="justify-content-center mt-lg-5">
                            <Row style={{ justifyContent: 'center', alignSelf: 'center' }}>
                                <Link to={'/Trip/Addtrip/Selecttimeperiod'}>
                                    <Card style={{ width: "50rem", height: "10rem" }}>

                                        <CardBody style={{ alignSelf: 'center' }}>
                                            <div className="media">
                                                <div class="align-self-center mr-4">
                                                    <img src={'/assets/images/icon/place.svg'} alt="" style={{ width: "110px", height: "110px" }} />
                                                </div>
                                                <div class="align-self-center ">
                                                    <h2 style={{ fontWeight: 'bold', marginBottom: 0, color: 'black' }}>{this.props.t('Add New Trip')}</h2>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Link>
                            </Row>
                            {/* <Row style={{ justifyContent: 'center', paddingTop: 30 }}>
                                <Link to={'/Trip/FormUpload'}>
                                    <Card style={{ width: "50rem", height: "10rem" }}>
                                        <CardBody style={{ alignSelf: 'center' }}>
                                            <div className="media">
                                                <div class="align-self-center mr-4">
                                                    <img src={upload} alt="" style={{ width: "110px", height: "110px", marginLeft: -92 }} />
                                                </div>
                                                <div class="align-self-center ">
                                                    <h2 style={{ fontWeight: 'bold', marginBottom: 0, color: 'black' }}>Upload File CSV</h2>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Link>
                            </Row> */}
                        </div>
                        {/* <Row style={{ justifyContent: 'center', paddingTop: 30 }}>
                            <h3 style={{ color: 'black' }}>{this.props.t('Choose a format for creating a travel plan')}</h3>
                        </Row> */}

                    </div>



                </div>
            </React.Fragment >
        )
    }
}
export default (
    (withTranslation()(Addtrip))
);