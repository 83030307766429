import React, { Component } from 'react';
import { Container, Row, Col, Card, Alert, CardBody, Media, Button, Input, Label, Nav, NavItem, NavLink } from "reactstrap";

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Link } from "react-router-dom";

// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumb from '../../components/Common/Breadcrumb';

import avatar from '../../assets/images/users/avatar-1.jpg';
// actions
import { editProfile } from '../../store/actions';
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";
import classnames from 'classnames';

import Lottie from "react-lottie";
import FadeIn from "react-fade-in";
import * as loadingData from "../Joborder/men-component/loading.json";
import { API_ENDPOINT } from "../../assets/api";
import Swal from 'sweetalert2'


const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingData.default,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};


class UserProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: "",
            name: "",
            idx: 1,
            activeTab: '1',
            add: true,
            fetch_profile: true
        }

        // handleValidSubmit
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
        this.toggleTab = this.toggleTab.bind(this);

    }

    // handleValidSubmit
    handleValidSubmit(event, values) {

        this.props.editProfile(values);
    }
    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            }
            );

        }
        this.setState({
            add: tab == '2' ? false : true
        })

    }

    async fetch_profile() {
        var auth = JSON.parse(await localStorage.getItem('authToken'))
        var url = API_ENDPOINT + "api/v2/web/profile"
        var dev_url = "https://joborder-api.dot.go.th/api/v2/web/profile"
        var profile_response = await fetch(url, {
            method: 'GET',
            headers: {
                Authorization: auth.data.type + ' ' + auth.data.token,
            }
        })
        var profile_object = await profile_response.json()
        console.log(profile_object)
        this.setState({
            fetch_profile: false,
            profile: profile_object
        })
    }
    async change_profile() {
        var firstname = this.state.profile.fname
        var lastname = this.state.profile.lname
        var identication_card = this.state.profile.identication_card
        if (firstname == '' || lastname == '' || identication_card == '') {
            return Swal.fire(
                'ล้มเหลว !',
                'กรุณาระบุข้อมูลส่วนตัวให้ครบถ้วน',
                'error'
            )
        }
        var auth = JSON.parse(await localStorage.getItem('authToken'))
        var url = API_ENDPOINT + "api/v2/web/profile"
        var dev_url = "https://joborder-api.dot.go.th/api/v2/web/profile/change_profile"
        var changeprofile_response = await fetch(url, {
            method: 'POSt',
            headers: {
                Authorization: auth.data.type + ' ' + auth.data.token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                firstname: firstname,
                lastname: lastname,
                identication_card: identication_card,
            })
        })
        var changeprofile_object = await changeprofile_response.json()
        console.log(changeprofile_object)
        if (changeprofile_object.status == 'success') {
            return Swal.fire(
                'สำเร็จ !',
                'แก้ไขข้อมูลสำเร็จ',
                'success'
            ).then(() => {
                window.location.reload()
            })
        } else {
            return Swal.fire(
                'ล้มเหลว !',
                'ไม่สามารถแก้ไขข้อมุลได้กรุณาติดต่อผู้ดูแลระบบ',
                'error'
            )
        }
    }
    async change_agency() {
        var name = this.state.profile.agency.name
        var license = this.state.profile.agency.license
        var address = this.state.profile.agency.address
        if (name == '' || license == '' || address == '') {
            return Swal.fire(
                'ล้มเหลว !',
                'กรุณาระบุข้อมูลส่วนตัวให้ครบถ้วน',
                'error'
            )
        }
        var auth = JSON.parse(await localStorage.getItem('authToken'))
        var url = API_ENDPOINT + "api/v2/web/profile"
        var dev_url = "https://joborder-api.dot.go.th/api/v2/web/profile/change_agency"
        var changeagency_response = await fetch(url, {
            method: 'POSt',
            headers: {
                Authorization: auth.data.type + ' ' + auth.data.token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name: name,
                license: license,
                address: address,
            })
        })
        var changeagency_object = await changeagency_response.json()
        console.log(changeagency_object)
        if (changeagency_object.status == 'success') {
            return Swal.fire(
                'สำเร็จ !',
                'แก้ไขข้อมูลสำเร็จ',
                'success'
            ).then(() => {
                window.location.reload()
            })
        } else {
            return Swal.fire(
                'ล้มเหลว !',
                'ไม่สามารถแก้ไขข้อมุลได้กรุณาติดต่อผู้ดูแลระบบ',
                'error'
            )
        }
    }
    async change_guide() {
        var license = this.state.profile.guide.license
        var address = this.state.profile.guide.address
        if (license == '' || address == '') {
            return Swal.fire(
                'ล้มเหลว !',
                'กรุณาระบุข้อมูลส่วนตัวให้ครบถ้วน',
                'error'
            )
        }
        var auth = JSON.parse(await localStorage.getItem('authToken'))
        var url = API_ENDPOINT + "api/v2/web/profile"
        var dev_url = "https://joborder-api.dot.go.th/api/v2/web/profile/change_guide"
        var changeagency_response = await fetch(url, {
            method: 'POSt',
            headers: {
                Authorization: auth.data.type + ' ' + auth.data.token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                license: license,
                address: address,
            })
        })
        var changeagency_object = await changeagency_response.json()
        if (changeagency_object.status == 'success') {
            return Swal.fire(
                'สำเร็จ !',
                'แก้ไขข้อมูลสำเร็จ',
                'success'
            ).then(() => {
                window.location.reload()
            })
        } else {
            return Swal.fire(
                'ล้มเหลว !',
                'ไม่สามารถแก้ไขข้อมุลได้กรุณาติดต่อผู้ดูแลระบบ',
                'error'
            )
        }
    }
    async change_avatar(e) {
        Swal.fire({
            title: 'ยืนยันหรือไม่ ?',
            text: "ต้องการเปลี่ยนรูปประจำตัวใช่หรือไม่",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'ยกเลิก',
            confirmButtonText: 'ยืนยัน!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                var formData = new FormData()
                formData.append('profile_pics', e.target.files[0], e.target.files[0].name);
                var auth = JSON.parse(await localStorage.getItem('authToken'))
                var url = API_ENDPOINT + "api/v2/web/profile/change_image"
                var dev_url = "https://joborder-api.dot.go.th/api/v2/web/profile/change_image"
                var changephoto_response = await fetch(url, {
                    method: 'POSt',
                    headers: {
                        Authorization: auth.data.type + ' ' + auth.data.token,
                    },
                    body: formData
                })
                var changephoto_object = await changephoto_response.json()
                if (changephoto_object.status == 'success') {
                    Swal.fire(
                        'สำเร็จ !',
                        'ระบบทำการเปลี่ยนรูปแล้ว',
                        'success'
                    ).then(async () => {
                        window.location.reload()
                    })

                } else {
                    Swal.fire(
                        'ล้มเหลว !',
                        'กรุณาตรวจสอบ รหัสผ่านให้เหมือนกัน',
                        'error'
                    )
                    return
                }
            }
        })

    }
    async change_password() {
        var old_password = this.state.old_password
        var new_password = this.state.new_password
        var new_password_again = this.state.new_password_again
        console.log(this.state)
        console.log(new_password)
        console.log(new_password_again)
        console.log(new_password == new_password_again)
        if (new_password != new_password_again) {
            Swal.fire(
                'ล้มเหลว !',
                'กรุณาระบุพาสเวิร์ดให้ตรงกัน',
                'error'
            )
            return
        }
        var auth = JSON.parse(await localStorage.getItem('authToken'))
        var url = API_ENDPOINT + "api/v2/web/profile/change_password"
        //var dev_url = "https://joborder-api.dot.go.th/api/v2/web/profile/change_password"
        var changepass_response = await fetch(url, {
            method: 'POSt',
            headers: {
                Authorization: auth.data.type + ' ' + auth.data.token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                old_password,
                new_password,
            })
        })
        var changepass_object = await changepass_response.json()
        if (changepass_object.status == 'success') {
            Swal.fire(
                'สำเร็จ !',
                'กรุณาเข้าระบบใหม่อีกครั้ง',
                'success'
            ).then(async () => {
                var auth_logout_response = await fetch(API_ENDPOINT + "auth/logout", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': auth.data.type + " " + auth.data.token,
                    }
                });
                await localStorage.removeItem("authToken");
                await localStorage.removeItem("authUser");
                window.location = "/login"
                return
            })

        } else {
            Swal.fire(
                'ล้มเหลว !',
                'กรุณาตรวจสอบ รหัสผ่านให้เหมือนกัน',
                'error'
            )
            return
        }

    }

    async componentDidMount() {
        this.fetch_profile()
    }

    render() {
        if (this.state.fetch_profile) {
            return (
                <React.Fragment>
                    <Row >
                        <Col>
                            <div style={{ marginTop: 200 }} className="text-center">
                                <FadeIn>
                                    <div style={{ display: "flex", justifyContent: 'center' }}>
                                        <Card>
                                            <CardBody>
                                                <Lottie options={defaultOptions} height={300} width={300} />
                                                <div>
                                                    <h4 style={{ color: 'black', padding: 30 }}>กำลังเตรียมข้อมูล กรุณารอสักครู่</h4>
                                                </div>
                                            </CardBody>
                                        </Card>

                                    </div>
                                </FadeIn>
                            </div>
                        </Col>
                    </Row>
                </React.Fragment>
            )
        }
        if (this.state.add == true) {
            return (
                <React.Fragment>
                    <div className="page-content">
                        <Container fluid>

                            {/* Render Breadcrumb */}
                            <Breadcrumb title="Account Settings" breadcrumbItem="Account Settings" />

                            <Row>

                                <Col lg="3">

                                    <div className="checkout-tabs">
                                        <Nav className="flex-column" pills>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({ active: this.state.activeTab === '1' })}
                                                    onClick={() => { this.toggleTab('1'); }}
                                                >
                                                    <i class="fas fa-user d-block check-nav-icon mt-4 mb-2" ></i>

                                                    <p className="font-weight-bold" style={{ fontSize: 20 }}>ข้อมูลผู้ใช้งาน</p>

                                                </NavLink>
                                            </NavItem>
                                            <NavItem >
                                                <NavLink
                                                    className={classnames({ active: this.state.activeTab === '2' })}
                                                    onClick={() => { this.toggleTab('2'); }}
                                                >
                                                    <i class="fas fa-cog d-block check-nav-icon mt-4 mb-2"></i>
                                                    <p className="font-weight-bold mb-4" style={{ fontSize: 20 }}>เปลี่ยนรหัสผ่าน</p>
                                                </NavLink>
                                            </NavItem>

                                        </Nav>
                                    </div>
                                </Col>

                                <Col lg="9">
                                    {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                                    {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}

                                    <Card>
                                        <CardBody>
                                            <Media>
                                                <div className="mr-3">
                                                    <img onClick={() => {
                                                        document.getElementById('photo_input').click()
                                                    }} src={API_ENDPOINT+'profile/photo/' + this.state.profile.id} alt="" className="avatar-md rounded-circle img-thumbnail" />
                                                </div>
                                                <input id="photo_input" style={{ display: 'none' }} onChange={(e) => { this.change_avatar(e) }} type="file"></input>
                                                <Media body className="align-self-center">
                                                    <div className="text-muted">
                                                        <h5>{this.state.profile.fname}  {this.state.profile.lname} </h5>
                                                        <p className="mb-1">Allowed JPG, GIF or PNG. Max size of 800kB</p>
                                                    </div>
                                                </Media>
                                            </Media>
                                        </CardBody>
                                        <CardBody>
                                            <h4><b>Profile</b></h4>
                                            <hr></hr>
                                            <div className="form-group">
                                                <label>UserName</label>
                                                <input className="form-control" type="text" value={this.state.profile.username} disabled={true}></input>
                                            </div>

                                            <div className="form-group">
                                                <label>Firstname</label>
                                                <input className="form-control" onChange={(e) => {
                                                    var state = this.state
                                                    state.profile.fname = e.target.value
                                                }} type="text" value={this.state.profile.fname} disabled={true}></input>
                                            </div>

                                            <div className="form-group">
                                                <label>Lastname</label>
                                                <input className="form-control" type="text" value={this.state.profile.lname} disabled={true}></input>
                                            </div>

                                            <div className="form-group">
                                                <label>Identication Card</label>
                                                <input className="form-control" type="text" value={this.state.profile.identication_card} disabled={true}></input>
                                            </div>
                                            {this.state.profile.type_category == 'agency' ?
                                                <div>
                                                    <h4><b>Agency</b></h4>
                                                    <hr></hr>
                                                    <div className="form-group">
                                                        <label>Agency Name</label>
                                                        <input className="form-control" type="text" value={this.state.profile.agency.name} disabled={true}></input>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>License</label>
                                                        <input className="form-control" type="text" value={this.state.profile.agency.license} disabled={true}></input>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>address</label>
                                                        <input className="form-control" type="text" value={this.state.profile.agency.address} disabled={true}></input>
                                                    </div>
                                                    {/* <div className="form-group">
                                                        <Button
                                                            onClick={() => { this.change_agency() }}
                                                            color="primary"
                                                            className="mt-3"
                                                            style={{ width: "100%" }}
                                                            block
                                                        > บันทึก
                                                        </Button>
                                                    </div> */}
                                                </div> : ''}

                                            {this.state.profile.type_category == 'guides' ?
                                                <div>
                                                    <h4><b>Guide</b></h4>
                                                    <hr></hr>
                                                    <div className="form-group">
                                                        <label>License</label>
                                                        <input className="form-control" type="text" value={this.state.profile.guide.license} ></input>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>address</label>
                                                        <input className="form-control" type="text" value={this.state.profile.guide.address} ></input>
                                                    </div>
                                                    {/* <div className="form-group">
                                                        <Button
                                                            onClick={() => { this.change_guide() }}
                                                            color="primary"
                                                            className="mt-3"
                                                            style={{ width: "100%" }}
                                                            block
                                                        > บันทึก
                                                        </Button>
                                                    </div> */}
                                                </div> : ''}






                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>


                        </Container>
                    </div>
                </React.Fragment>
            )


        }
        if (this.state.add == false) {
            return (
                <React.Fragment>
                    <div className="page-content">
                        <Container fluid>

                            {/* Render Breadcrumb */}
                            <Breadcrumb title="Account Settings" breadcrumbItem="Account Settings" />

                            <Row>

                                <Col lg="3">

                                    <div className="checkout-tabs">
                                        <Nav className="flex-column" pills>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({ active: this.state.activeTab === '1' })}
                                                    onClick={() => { this.toggleTab('1'); }}
                                                >
                                                    <i class="fas fa-user d-block check-nav-icon mt-4 mb-2" ></i>

                                                    <p className="font-weight-bold" style={{ fontSize: 20 }}>ข้อมูลผู้ใช้งาน</p>

                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({ active: this.state.activeTab === '2' })}
                                                    onClick={() => { this.toggleTab('2'); }}
                                                >
                                                    <i class="fas fa-cog d-block check-nav-icon mt-4 mb-2"></i>
                                                    <p className="font-weight-bold mb-4" style={{ fontSize: 20 }}>เปลี่ยนรหัสผ่าน</p>
                                                </NavLink>
                                            </NavItem>

                                        </Nav>
                                    </div>
                                </Col>

                                <Col lg="9">
                                    {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                                    {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}

                                    <Card>
                                        <CardBody>
                                            <div className="form-group">
                                                <label>รหัสผ่านเก่า</label>
                                                <input className="form-control" onChange={(e) => {
                                                    var old_password = this.state.old_password
                                                    old_password = e.target.value
                                                    this.setState({
                                                        old_password: old_password
                                                    })
                                                }} value={this.state.old_password} type="password"></input>
                                            </div>
                                            <div className="form-group">
                                                <label>รหัสผ่านใหม่</label>
                                                <input className="form-control" onChange={(e) => {
                                                    var new_password = this.state.new_password
                                                    new_password = e.target.value
                                                    this.setState({
                                                        new_password: new_password
                                                    })
                                                }} value={this.state.new_password} type="password"></input>
                                            </div>
                                            <div className="form-group">
                                                <label>รหัสผ่านใหม่อีกครั้ง</label>
                                                <input className="form-control" onChange={(e) => {
                                                    var new_password_again = this.state.new_password_again
                                                    new_password_again = e.target.value
                                                    this.setState({
                                                        new_password_again: new_password_again
                                                    })
                                                }} value={this.state.new_password_again} type="password"></input>
                                            </div>
                                            <Row>
                                                <Col lg={8} ></Col>
                                                <Col lg={2} style={{ textAlign: "right" }}>
                                                    <Button
                                                        onClick={() => { this.change_password() }}
                                                        color="primary"
                                                        className="mt-3"
                                                        style={{ width: "100%" }}
                                                        block
                                                    > บันทึก
                                                        </Button>
                                                </Col>
                                                <Col lg={2}>

                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>


                        </Container>
                    </div>
                </React.Fragment>
            );
        }
    }
}

const mapStatetoProps = state => {
    const { error, success } = state.Profile;
    return { error, success };
}

export default withRouter(connect(mapStatetoProps, { editProfile })(UserProfile));

