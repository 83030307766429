import React, { Component } from 'react';

import { Row, Col, CardBody, Card, Alert, Container, CardImg } from "reactstrap";

// Redux
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';



import "../Authentication/Login.scss"
import { withTranslation } from 'react-i18next';
class JobOrderSearch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: ''
        }
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }

    handleValidSubmit(event, values) {
        this.check_joborder(values.joborder_code)
    }
    async check_joborder(code) {
        let url = `https://joborder-api.dot.go.th/search/joborder/${code}`
        let fetch_response = await fetch(url)
        let fetch_response_object = await fetch_response.json()
        console.log(fetch_response_object)
        if (fetch_response_object.status == 'success') {
            return window.location = `/joborder/search_paper/${code}`
        } else {
            this.setState({
                error: fetch_response_object.msg
            })
        }

    }

    componentDidMount() {
    }

    render() {

        return (
            <React.Fragment>
                <div className="login-page">
                    <div className="style-login">
                        <div className="box-flex-1">
                            <div className="box-logo">
                                <img className="logo-img" src={'/assets/images/icon/login.png'} alt="" />
                            </div>
                        </div>
                        <div className="box-flex-2">
                            <div className="card-search" >
                                <span className="card-text">ค้นหาใบสั่งงานมัคคุเทศก์</span>
                                <div className="style-card-search">
                                    <AvForm className="form-horizontal" onValidSubmit={this.handleValidSubmit}>

                                        <div className="form-group normal-text">
                                            <AvField name="joborder_code" label="" value="" className="form-control" placeholder="กรอกหมายเลขใบสั่งงานมัคคุเทศก์" type="text" required />
                                        </div>
                                        <div className="">
                                            <button className="btn btn-primary btn-block waves-effect waves-light" style={{ marginTop: 10 }} type="submit"><span className="normal-text-18">ค้นหา</span></button>
                                        </div>
                                    </AvForm>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </React.Fragment >
        );
    }
}

export default withRouter((withTranslation()(JobOrderSearch)));

