import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Col, Row, Card, CardBody, CardTitle, CardSubtitle, CardImg, CardText, CardHeader, CardImgOverlay, CardFooter, CardDeck, CardColumns, Container, Button, Carousel, CarouselItem, CarouselControl, CarouselIndicators } from "reactstrap";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
// import check from '../../../assets/images/icon/check.svg';
// import choss from "../../../assets/images/icon/c.png";

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

export default class Cardregion extends Component {
    constructor(props) {
        super(props);
        this.state = {

            showtext: {
                index: -1,
                text: 'เลือกแล้ว !!'
            },
            showimage: {
                index: -1,
                image: '/assets/images/icon/c.png'
            }

        };
    }


    render() {
        const { data } = this.props;



        return (
            <Swiper
                breakpoints={{
                    // when window width is >= 640px
                    200: {
                        width: 500,
                        slidesPerView: 1,

                    },
                    // when window width is >= 640px
                    640: {
                        width: 640,
                        slidesPerView: 1,

                    },
                    // when window width is >= 768px
                    768: {
                        width: 768,
                        slidesPerView: 2,
                    },
                    960: {
                        width: 820,
                        slidesPerView: 2,
                    },
                    2200: {
                        width: 850,
                        slidesPerView: 2,
                    },
                    2300: {
                        width: 900,
                        slidesPerView: 2,
                    },
                    2500: {
                        width: 1000,
                        slidesPerView: 2,
                    },
                    2600: {
                        width: 1000,
                        slidesPerView: 2,
                    },
                    2800: {
                        width: 1100,
                        slidesPerView: 2,
                    },
                    3000: {
                        width: 1200,
                        slidesPerView: 2,
                    },
                }}
                id="main"
                width="480"
                loop={true}
                spaceBetween={5}
                slidesPerView={'auto'}
                navigation
                // pagination={{ clickable: true }}
                // scrollbar={{ draggable: true }}
                onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log('slide change')}
                spaceBetween={5}
                slidesPerView={'auto'}
                onClick={(swiper, event) => {
                    if (swiper.clickedSlide == undefined) {
                        console.log('clickedSlide undefined')
                        return
                    }
                    else {
                        if (swiper.clickedSlide.innerText.trim() == 'ภาคเหนือ') {
                            this.props.cardSelected('ภาคเหนือ')
                            this.setState({
                                showtext: {
                                    ...this.state.showtext,
                                    index: 0
                                },
                                showimage: {
                                    ...this.state.showimage,
                                    index: 0
                                }
                            })
                        }
                        if (swiper.clickedSlide.innerText.trim() == 'ภาคตะวันออกเฉียงเหนือ') {
                            this.props.cardSelected('ภาคตะวันออกเฉียงเหนือ')
                            this.setState({
                                showtext: {
                                    ...this.state.showtext,
                                    index: 1
                                },
                                showimage: {
                                    ...this.state.showimage,
                                    index: 1
                                }
                            })

                        }
                        if (swiper.clickedSlide.innerText.trim() == 'ภาคกลาง') {
                            this.props.cardSelected('ภาคกลาง')
                            this.setState({
                                showtext: {
                                    ...this.state.showtext,
                                    index: 2
                                },
                                showimage: {
                                    ...this.state.showimage,
                                    index: 2
                                }
                            })
                        }
                        if (swiper.clickedSlide.innerText.trim() == 'ภาคใต้') {
                            this.props.cardSelected('ภาคใต้')
                            this.setState({
                                showtext: {
                                    ...this.state.showtext,
                                    index: 3
                                },
                                showimage: {
                                    ...this.state.showimage,
                                    index: 3
                                }
                            })
                        }
                        if (swiper.clickedSlide.innerText.trim() == 'กรุงเทพและปริมณทล') {
                            this.props.cardSelected('กรุงเทพและปริมณทล')
                            this.setState({
                                showtext: {
                                    ...this.state.showtext,
                                    index: 4
                                },
                                showimage: {
                                    ...this.state.showimage,
                                    index: 4
                                }
                            })
                        }
                    }

                }}
            >
                {data.map((Item, index) =>

                    <SwiperSlide key={Item.id} style={{ marginTop: '6rem' }}>
                        <Row style={{ alignSelf: 'center', justifyContent: 'center' }}>
                            <label className="card-radio-label mb-2" >
                                <input type="checkbox" name="currency" id="buycurrencyoption2" className="card-radio-input" />
                                <Col lg={12} mg={12} sm={12} className="card-radio" key={index} style={{ width: '18rem', height: '21rem', borderRadius: 10, borderWidth: 5 }}  >
                                    {/* <div style={{ right: 20, top: 20, position: 'absolute' }}>
                                        {this.state.showimage.index == index ? <img src={this.state.showimage.image} alt="" /> : null}
                                    </div> */}
                                    <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
                                        <CardImg top className="img-fluid" src={Item.src} style={{ width: '10rem', height: '11rem' }} />
                                        {/* <img src={Item.src} alt="" style={{ width: '10rem', height: '11rem' }} /> */}
                                        <CardImgOverlay >
                                            <div style={{ paddingTop: 53 }}>
                                                {/* {this.state.showtext.index == index ? <p style={{ color: '#F4D006', fontWeight: 'bold', transform: 'rotate(-50deg)', fontSize: '5rem' }} >{this.state.showtext.text}</p> : null} */}
                                                {this.state.showimage.index == index ? <img src={this.state.showimage.image} alt="" style={{ width: '90%', }} /> : null}
                                            </div>
                                        </CardImgOverlay>

                                    </div>

                                    <div style={{ textAlign: "center" }}  >
                                        <h3 style={{ color: "black", fontWeight: 'bold' }}>{Item.title}</h3>
                                    </div>

                                </Col>
                                {/* <div style={{ textAlign: 'center', paddingTop: 20 }}>
                                    {this.state.showtext.index == index ? <h3 style={{ color: '#224AAC', fontWeight: 'bold' }}>{this.state.showtext.text}</h3> : null}
                                </div> */}
                            </label>
                        </Row>
                    </SwiperSlide>

                )
                }

            </Swiper>

        );
    }
}
