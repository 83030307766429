import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Card, CardBody, Badge, Col, Row } from "reactstrap";

//Import Images
// import avatar4 from "../../assets/images/users/avatar-4.jpg";
// import avatar5 from "../../assets/images/users/avatar-5.jpg";
import img1 from "../../../../assets/images/small/img-1.jpg";
import { IMAGE_ENDPOINT } from '../../../../assets/api'


class CardTaskBox extends Component {

    constructor(props) {
        super(props)
        this.state = {
            windowWidth: window.innerWidth,
        }
        this.handleResize = this.handleResize.bind(this);
    }
    handleResize = (e) => {
        this.setState({ windowWidth: window.innerWidth });
    };
    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
    }
    render() {
        const { windowWidth } = this.state;
        console.log(this.props)
        return (

            <React.Fragment>
                <Card style={{ borderRadius: 5, width: 353, minWidth: 415, margin: 17 }}>
                    <CardBody>
                        <Row>
                            <Col sm={3} md={3} lg={3} style={{ textAlign: 'center', alignSelf: 'center' }}>
                                <img className="rounded-circle avatar-lg" style={{ height: 60, width: 60 }} alt="Skote" src={IMAGE_ENDPOINT + this.props.data['photo']} />
                            </Col>
                            <Col sm={9} md={9} lg={9}>
                                <Row>
                                    {this.props.data['name'].length < 17 ?
                                        <h4 style={{ color: 'black', fontWeight: 'bold' }}>{this.props.data['name']}</h4>
                                        :
                                        <h4 style={{ color: 'black', fontWeight: 'bold' }}>{this.props.data['name'].substring(0, 15)}...</h4>
                                    }
                                </Row>
                                <Row>
                                    <i class="fas fa-map-marker-alt text-primary"></i>
                                    <p style={{ fontSize: 10, marginLeft: 5 }}>{this.props.data['category_name'].substring(0, 40)}...</p>
                                </Row>
                                <Row>
                                    <i class="fas fa-map-marker-alt text-primary"></i>
                                    <p style={{ marginLeft: 5, color: 'black', fontSize: 10, fontWeight: 'bold' }}>{this.props.data['address'].substring(0, 40)}...</p>
                                </Row>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                {/* <Card className="task-box">
                    <CardBody className="borad-width">
                        <Row>
                            <Col sm={3} md={3} lg={3} style={{ alignSelf: 'center', textAlign: 'center' }}>
                                <img className="rounded-circle avatar-lg" style={{ height: 60, width: 60 }} alt="Skote" src={IMAGE_ENDPOINT + this.props.data['photo']} />
                            </Col>
                            <Col sm={9} md={9} lg={9}>
                                <Row>
                                    <Col sm={12} md={12} lg={12}>
                                        {this.props.data['name'].length < 17 ?
                                            <h1 className="font-size-15"><Link to="#" className="text-dark">{this.props.data['name']}</Link></h1>
                                            :
                                            <h1 className="font-size-15"><Link to="#" className="text-dark">{this.props.data['name'].substring(0, 15)}...</Link></h1>}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={12} lg={12}>
                                        <Row>
                                            <Col sm={12} md={12} lg={12}>
                                                <Row>
                                                    <i class="fas fa-map-marker-alt text-primary" onClick={event => window.location.href = '/Trello'}></i>   <p style={{ fontSize: 10, marginLeft: 5 }}>{this.props.data['category_name'].substring(0, 40)}...</p>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <i class="fas fa-map-marker-alt text-primary"></i>   <p style={{ fontSize: 10, marginLeft: 5 }}>{this.props.data['address'].substring(0, 40)}...</p>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </CardBody>
                </Card> */}
            </React.Fragment >
        );
    }
}

export default CardTaskBox;