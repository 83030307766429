import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { API_ENDPOINT } from "../../../assets/api";
import { Container, Row, Col, Input, Button, Card, CardBody, NavItem, NavLink, Table, Label, Badge, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip, Pagination, PaginationItem, PaginationLink } from "reactstrap";

import DataGrid, {
    Column,
    SearchPanel,
    FilterRow,
    HeaderFilter,
    GroupPanel,
    Pager, Paging,
    FilterPanel,
    FilterBuilderPopup,
    Scrolling,
    Export
} from 'devextreme-react/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { exportDataGridToPdf } from 'devextreme/excel_exporter';
import { Link } from "react-router-dom";

import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import moment from 'moment'
const allowedPageSizes = [5, 10];
export default class TableJob extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Orders: [


            ],
            // modal: false,
        }
        // this.togglemodal.bind(this);
        this.auth = JSON.parse(localStorage.getItem('authToken'))
        this.getJoborder = this.getJoborder.bind(this);
        this.Operation = this.Operation.bind(this);
        //this.getTrips = this.getTrips.bind(this);
        this.onExporting = this.onExporting.bind(this);
        this.renderMyCommand = this.renderMyCommand.bind(this);
    }
    // togglemodal = () => {
    //     this.setState(prevState => ({
    //         modal: !prevState.modal
    //     }));
    // }




    async getJoborder() {
        var response = await fetch(API_ENDPOINT + 'api/v2/web/joborder/get?take=50&skip=0', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: this.auth.data.type + ' ' + this.auth.data.token,

            }
        });
        var responseJson = await response.json();
        console.log(responseJson)
        this.setState({
            Orders: responseJson,
            isLoading: false
        })
    }


    componentDidMount() {
        this.getJoborder()
        this.renderMyCommand()
    }

    guide_status_msg(data) {
        switch (data.data.guide_status_msg) {
            case 'ready': return (<Badge className={"font-size-16 badge-soft-success"} color={'success'} pill>พร้อมทำงาน</Badge>)
            case 'pending': return (<Badge className={"font-size-16 badge-soft-warning"} color={'warning'} pill>รอการตอบรับ</Badge>)
            // case 3: return (<Badge className={"font-size-12 badge-soft-danger"} color={'danger'} pill>ลบ</Badge>)
        }
    }

    start_date_render(data) {
        return (<div>{moment(new Date(data.data.joborder_start_date)).format('YYYY-MM-DD HH:mm:ss')}</div>)
    }

    stop_date_render(data) {
        return (<div>{moment(new Date(data.data.joborder_stop_date)).format('YYYY-MM-DD HH:mm:ss')}</div>)
    }

    // async getTrips(id, data) {
    //     console.log(id, data)
    //     console.log('inside gettrips')
    //     // var response = await fetch(API_ENDPOINT + 'api/v2/web/tour/get/' + id, {
    //     //     method: 'GET',
    //     //     headers: {
    //     //         Accept: 'application/json',
    //     //         'Content-Type': 'application/json',
    //     //         Authorization: this.auth.data.type + ' ' + this.auth.data.token,

    //     //     }
    //     // });
    //     var response = await fetch(API_ENDPOINT + 'api/v2/web/joborder/tour/get/' + id, {
    //         method: 'GET',
    //         headers: {
    //             Accept: 'application/json',
    //             'Content-Type': 'application/json',
    //             Authorization: this.auth.data.type + ' ' + this.auth.data.token,

    //         }
    //     });
    //     var responseJson = await response.json();
    //     console.log(responseJson)
    //     console.log(data.data)
    //     if (responseJson != undefined) {
    //         this.props.history.push({
    //             pathname: '/Joborderaddjb',
    //             tripInfo: responseJson,
    //             tripData: data.data,
    //             action: 'edit'

    //         })
    //     }
    //     else {
    //         return
    //     }

    // }
    renderMyCommand(cellData) {
        console.log(cellData)
        return (

            <Link to={{
                pathname: '/joborder/progress',
                joborder_id: cellData.data.id,

            }}>
                <Button type="button" color="primary" className="btn-sm btn-rounded">
                    View Details
                </Button>

            </Link>


        )
    }
    Operation(data) {
        console.log(data)
        return (
            <div style={{ alignSelf: 'center', textAlign: 'center' }}>
                <i onClick={() => {
                    this.props.history.push({
                        pathname: '/JoborderEdit',
                        joborder_id: data.data.id,
                    })
                }} style={{ color: 'gray', cursor: 'pointer' }} className="mdi mdi-pencil font-size-18"></i>
                {/* <i onClick={() => {
                    this.props.history.push({
                        pathname: '/joborder/progress',
                        joborder_id: data.data.id,
                    })
                }} style={{ color: 'gray', cursor: 'pointer' }} className="mdi mdi-eye font-size-18"></i> */}
                { data.data.status != 3 ?
                    <i onClick={() => { this.set_delete_users(data.data.id) }} style={{ color: 'gray', cursor: 'pointer' }} className="mdi mdi-delete font-size-18"></i>
                    :
                    ''
                }
            </div>


        )
    }



    render() {
        return (
            <div>
                <DataGrid
                    onExporting={this.onExporting}
                    dataSource={this.state.Orders}
                    showBorders={true}
                    showColumnLines={true}
                    rowAlternationEnabled={true}
                    // mode={"offline"}
                    //serversideSource={'https://js.devexpress.com/Demos/WidgetsGalleryDataService/api/orders'}
                    // tableId={0}
                    // user_id={0}
                    selectedCallback={this.selectedCallback}
                    initialCallback={this.tableInitial}
                    selection={{ mode: 'single' }}
                    columnMinWidth={50}
                    columnAutoWidth={true}
                    allowColumnResizing={true}

                >
                    <Pager
                        allowedPageSizes={allowedPageSizes}
                        showPageSizeSelector={true}
                        showNavigationButtons={true}
                    />
                    <Paging defaultPageSize={10} />
                    <GroupPanel visible={true} />
                    <FilterRow visible={this.state.showFilterRow}
                        applyFilter={this.state.currentFilter} />
                    <HeaderFilter visible={this.state.showHeaderFilter} />
                    <SearchPanel visible={true}
                        width={240}
                        placeholder="Search..." />
                    <Column dataField="id"
                        width={50} />

                    <Column caption={"Tour Code"} dataField="tourCode" />
                    {/* <Column dataField="arrival_date" />
                    <Column dataField="departure_date" />
                    <Column dataField="flightCode" />
                    <Column dataField="flightFrom" /> */}
                    <Column caption={"Job Start Date"} dataField="joborder_start_date" cellRender={this.start_date_render} />
                    <Column caption={"Job End Date"} dataField="joborder_stop_date" cellRender={this.stop_date_render} />
                    <Column caption={"Agency License"} dataField="license" />
                    <Column caption={"Agency Name"} dataField="agencyName" />
                    <Column dataField="Job Preparation status"
                        type="buttons"
                        cellRender={this.guide_status_msg}
                    />
                    <Column dataField="Detail"
                        type="buttons"
                        cellRender={this.renderMyCommand}>
                    </Column>
                    <Column dataField="Operation"
                        type="buttons"
                        cellRender={this.Operation}>

                    </Column>
                    <Export enabled={true} allowExportSelectedData={true} />

                </DataGrid>
            </div>
        )
    }
    onExporting(e) {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');

        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
            autoFilterEnabled: true
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
            });
        });
        e.cancel = true;
    }
}
