import React, { Component } from 'react'
import Report from './Report';

export default class HeatMap extends Component {
    /*static propTypes = {
         prop: PropTypes
     }*/
    constructor(props) {
        super(props);
        this.state = {
          
        }
    };



    render() {
        return (
            <React.Fragment>
               <Report></Report>
            </React.Fragment>
        )
    }
}