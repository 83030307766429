import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from './actionTypes';
import { loginSuccess, logoutUserSuccess, apiError, saveCapacity } from './actions';

import { postLogin, getAccount } from '../../../helpers/api';

function* loginUser({ payload: { user, history } }) {
    try {
        const token_response = yield call(postLogin, '/login', { username: user.username, password: user.password });
        if (token_response.status != 'success') return yield put(apiError(token_response.message));
        console.log('?')
        const user_response = yield call(getAccount, '/api/v2/web/account', { type: token_response.data.type, token: token_response.data.token });
        if (user_response.website_enable != 1) return yield put(apiError('This account not authorize on this app.'));
        var capacity = []
        for (var i = 0; i < user_response.capacity.length; i++) {
            capacity.push(user_response.capacity[i].capacity)
        }
        yield put(saveCapacity(capacity));
        localStorage.setItem("authToken", JSON.stringify(token_response));
        localStorage.setItem("authUser", JSON.stringify(user_response));
        localStorage.setItem("authCapacity", JSON.stringify(capacity));
        history.push('/Trip');

    } catch (error) {
        yield put(apiError(error));
    }
}

function* logoutUser({ payload: { history } }) {
    try {
        localStorage.removeItem("authToken");
        localStorage.removeItem("authUser");
        localStorage.removeItem("authCapacity");
        history.push('/login');
    } catch (error) {
        yield put(apiError(error));
    }
}


export function* watchUserLogin() {
    yield takeEvery(LOGIN_USER, loginUser)
}

export function* watchUserLogout() {
    yield takeEvery(LOGOUT_USER, logoutUser)
}

function* authSaga() {
    yield all([
        fork(watchUserLogin),
        fork(watchUserLogout),
    ]);
}

export default authSaga;