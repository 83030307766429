
import React, { Component } from "react";
import { Row, Col, Alert, Card, CardBody, Container } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// import images
// import profile from "../../assets/images/profile-img.png";
// import logo from "../../assets/images/logo.svg";
// import imglogin from "../../assets/images/small/bgloginnew.svg"
// import imgkrom from "../../assets/images/small/logokrom.png"
import imgcard from "../../assets/images/small/img-10.png"
import { API_ENDPOINT } from "../../assets/api";
import queryString from 'query-string';
import { Thumbs } from "swiper";
class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            code: '',
            username: '',
            resetError: '',
            resetSuccess: ''
        };

        // handleValidSubmit
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }

    // handleValidSubmit
    async handleValidSubmit(event, values) {
        let password = values.password
        let password_again = values.password_again
        if (password != password_again) return this.setState({
            resetError: 'Password not match',
            resetSuccess: ''
        })
        var response = await fetch(API_ENDPOINT + 'password/reset', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                code: this.state.code,
                password: password
            })
        });
        var responseJson = await response.json();
        console.log(responseJson)
        if (responseJson.result == true) {
            this.setState({
                resetSuccess: 'Please check your email',
                resetError: ''
            })
        } else {
            this.setState({
                resetError: responseJson.msg,
                resetSuccess: ''
            })
        }
    }

    componentDidMount() {
        let params = queryString.parse(this.props.location.search)
        console.log(params)
        if ('code' in params == false) {
            return window.location = "/"
        }
        this.setState({
            code: params.code
        })
    }
    render() {
        return (
            <React.Fragment>
                {/* <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark"><i className="bx bx-home h2"></i></Link>
        </div> */}
                <div className="container-fluid">
                    <div className="bg_image">
                        <Row>
                            <Col lg={1}>
                            </Col>
                            <Col lg={6} style={{ marginTop: "25vh" }}>
                                <img src={'/assets/images/logo/logokrom.png'} />
                            </Col>
                            <Col lg={5}>
                                <div className="account-pages my-5 pt-sm-5">
                                    <Container>

                                        <Col md={12} lg={12} xl={9}>
                                            <Card className="overflow-hidden">
                                                <div className="btn-primary">
                                                    <Row>
                                                        <Col className="col-7">
                                                            <div className="text-light p-4">
                                                                <h5 className="text-light">Welcome Back !</h5>
                                                                <p className="text-light">Reset Password.</p>
                                                            </div>
                                                        </Col>
                                                        <Col className="col-5 align-self-end">
                                                            <img src={imgcard} alt="" className="img-fluid" />
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <CardBody className="pt-0">
                                                    <div>
                                                        <Link to="/">
                                                            <div className="avatar-md profile-user-wid mb-4">
                                                                <span className="avatar-title rounded-circle bg-light">
                                                                    <img src={'/assets/images/logo/logo.svg'} alt="" className="rounded-circle" height="34" />
                                                                </span>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div className="p-2">

                                                        {this.state.resetError ? (
                                                            <Alert color="danger" style={{ marginTop: "13px" }}>
                                                                {this.state.resetError}
                                                            </Alert>
                                                        ) : null}
                                                        {this.state.resetSuccess ? (
                                                            <Alert color="success" style={{ marginTop: "13px" }}>
                                                                {this.state.resetSuccess}
                                                            </Alert>
                                                        ) : null}

                                                        <AvForm
                                                            className="form-horizontal mt-4"
                                                            onValidSubmit={this.handleValidSubmit}
                                                        >

                                                            <div className="form-group">
                                                                <AvField
                                                                    name="password"
                                                                    label="Password"
                                                                    className="form-control"
                                                                    placeholder="Enter Password"
                                                                    type="password"
                                                                    required
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <AvField
                                                                    name="password_again"
                                                                    label="Password Again"
                                                                    className="form-control"
                                                                    placeholder="Enter Password"
                                                                    type="password"
                                                                    required
                                                                />
                                                            </div>
                                                            <Row className="form-group">
                                                                <Col className="text-right">
                                                                    <button
                                                                        className="btn btn-primary w-md waves-effect waves-light"
                                                                        type="submit"
                                                                    >
                                                                        Reset
                                                                    </button>
                                                                    <div className="mt-5 text-center">
                                                                        <p>
                                                                            Go back to{" "}
                                                                            <Link
                                                                                to="login"
                                                                                className="font-weight-medium text-primary"
                                                                            >
                                                                                Login
                                                                            </Link>{" "}
                                                                        </p>

                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </AvForm>
                                                    </div>
                                                </CardBody>
                                            </Card>

                                        </Col>

                                    </Container>
                                </div>
                            </Col>

                        </Row>

                    </div>

                </div>

            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { forgetError, forgetSuccessMsg } = state.ForgetPassword;
    return { forgetError, forgetSuccessMsg };
};

export default ResetPassword
