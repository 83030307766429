import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, TabContent, TabPane, NavItem, NavLink, Label, Form, FormGroup, Button } from "reactstrap";
import classnames from 'classnames';

//Date Picker
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

import "./datatables.scss";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Tablejoborder from "./Tablejoborder";

import TableJob from "./TableJob";
class Menubarjob extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // startDate: new Date(),
      // activeTab: '1',
    }
    // this.toggleTab = this.toggleTab.bind(this);
    // this.handleChange.bind(this);
    console.log(this.props)
  }

  // handleChange = date => {
  //   this.setState({
  //     startDate: date
  //   });
  // };

  // toggleTab(tab) {
  //   if (this.state.activeTab !== tab) {
  //     this.setState({
  //       activeTab: tab
  //     });
  //   }
  // }

  render() {

    return (
      <React.Fragment>






        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <TableJob history={this.props.history} />
              </CardBody>
            </Card>
          </Col>
        </Row>


      </React.Fragment>
    );
  }
}

export default Menubarjob;