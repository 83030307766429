import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { API_ENDPOINT } from "../../../../assets/api";
import { Container, Row, Col, Input, Button, Card, CardBody, NavItem, NavLink, Table, Label, Badge, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip, Pagination, PaginationItem, PaginationLink } from "reactstrap";
import DataGrid, {
    Column,
    SearchPanel,
    FilterRow,
    HeaderFilter,
    GroupPanel,
    Pager, Paging,
    FilterPanel,
    FilterBuilderPopup,
    Scrolling
} from 'devextreme-react/data-grid';
import Swal from 'sweetalert2'
const allowedPageSizes = [5, 10];
export default class TableUsers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: "",

            modal: false,
            isLoading: true,


        }
        this.togglemodal.bind(this);
        this.getUser.bind(this);
        this.set_delete_users = this.set_delete_users.bind(this);
        this.auth = JSON.parse(localStorage.getItem('authToken'))
        console.log(props)
    }
    togglemodal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    async set_delete_users(users_id) {
        Swal.fire({
            title: 'ต้องการลบ ?',
            text: "จะไม่สามารถยกเลิกการทำงานได้!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'ยืนยัน!',
            cancelButtonText: 'ยกเลิก',
        }).then(async (result) => {
            if (result.isConfirmed) {
                console.log(users_id)
                var token = localStorage.getItem('authToken');
                token = JSON.parse(token)
                var response = await fetch(API_ENDPOINT + 'api/v2/web/users/delete', {
                    method: 'POST', // *GET, POST, PUT, DELETE, etc.
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': token.data.type + ' ' + token.data.token
                    },
                    body: JSON.stringify({
                        users_id: users_id
                    })
                });
                response = await response.json()
                if (response.status == 'success') {
                    Swal.fire(
                        'สำเร็จ!',
                        'เปลี่ยนสถานะผู้ใช้งานแล้ว',
                        'success'
                    ).then(() => {
                        window.location.reload()
                    })
                } else {
                    Swal.fire(
                        'ผิดพลาด!',
                        'ไม่สามารถลบได้ ติดต่อผู้ดูแลระบบ',
                        'error'
                    )
                }

            }
        })

    }

    async getUser() {
        var response = await fetch(API_ENDPOINT + 'api/v2/web/users/get', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: this.auth.data.type + ' ' + this.auth.data.token,

            }
        });
        var responseJson = await response.json();
        console.log(responseJson)
        this.setState({
            data: responseJson,
            isLoading: false
        })
    }

    componentDidMount() {
        this.getUser()
    }

    determine_status(data) {
        switch (data.data.status) {
            case 1: return (<Badge className={"font-size-12 badge-soft-success"} color={'success'} pill>ปกติ</Badge>)
            case 2: return (<Badge className={"font-size-12 badge-soft-warning"} color={'warning'} pill>ระงับใช้งาน</Badge>)
            case 3: return (<Badge className={"font-size-12 badge-soft-danger"} color={'danger'} pill>ลบ</Badge>)
        }


    }

    Operation(data) {
        return (
            <div>
                <i onClick={() => {
                    this.props.history.push({
                        pathname: '/Userdetail',
                        userDetail: data.data,
                        userData: this.auth,
                        mode: 'write'
                    })
                }} style={{ color: 'orange', cursor: 'pointer' }} className="mdi mdi-pencil font-size-18 mr-3"></i>
                {data.data.status != 3 ? <i onClick={() => { this.set_delete_users(data.data.id) }} style={{ color: 'red', cursor: 'pointer' }} className="mdi mdi-delete font-size-18 mr-3"></i> : ''}
            </div>


        )
    }


    render() {
        return (

            <div>
                <DataGrid
                    onExporting={this.onExporting}
                    dataSource={this.state.Orders}
                    showBorders={true}
                    showColumnLines={true}
                    rowAlternationEnabled={true}
                    // mode={"offline"}
                    //serversideSource={'https://js.devexpress.com/Demos/WidgetsGalleryDataService/api/orders'}
                    // tableId={0}
                    // user_id={0}
                    selectedCallback={this.selectedCallback}
                    initialCallback={this.tableInitial}
                    selection={{ mode: 'single' }}
                    columnMinWidth={50}
                    columnAutoWidth={true}
                    allowColumnResizing={true}

                >
                    <Pager
                        allowedPageSizes={allowedPageSizes}
                        showPageSizeSelector={true}
                        showNavigationButtons={true}
             
                    />
                    <Column dataField="id" />
                    <Column dataField="username"
                    />

                    <Column dataField="lname" />
                    <Column dataField="email" />
                    <Column dataField="status" />
                    <Column dataField="Detail"
                        type="buttons"

                        cellRender={this.determine_status}>


                    </Column>
                    <Column dataField="Operation"
                        type="buttons"
                        cellRender={this.Operation}>



                    </Column>
                    {/* <Paging defaultPageSize={10} />
                    <GroupPanel visible={true} />
                    <FilterRow visible={this.state.showFilterRow}
                        applyFilter={this.state.currentFilter} />
                    <HeaderFilter visible={this.state.showHeaderFilter} />
                    <SearchPanel visible={true}
                        width={240}
                        placeholder="Search..." />
                    <Column dataField={"photo"}
                        width={120}
                        cellRender={this.calculateCellphoto}
                        alignment='center'
                    />
                    <Column dataField={"name"}
                        calculateCellValue={this.calculateCellValue}
                    />
                    <Column dataField="address" />

                    <Column dataField="Detail"
                        type="buttons"

                        cellRender={this.renderMyCommand}>


                    </Column> */}

                </DataGrid>
            </div>
        )
    }
}
