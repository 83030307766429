import React, { Component, useEffect } from 'react';
import 'devextreme/data/odata/store';
import DataGrid, { Column, Paging, Pager, FilterRow, HeaderFilter, SearchPanel, Export, RemoteOperations, Grouping, GroupPanel } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import 'devextreme/dist/css/dx.light.css';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import { API_DEVEXPRESS_ENDPOINT } from '../../../assets/api';
import { Link } from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Input,
    Button,
    Card,
    CardBody,
    NavItem,
    NavLink,
    Table,
    Label,
    Badge,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    UncontrolledTooltip,
    Pagination,
    PaginationItem,
    PaginationLink,
    FormGroup,
} from "reactstrap";
const UsersGrid = (props) => {

    useEffect(() => {
    }, [props]);

    const store = new CustomStore({
        key: 'id',
        load: function (loadOptions) {
            let isNotEmpty = (value) => {
                return value !== undefined && value !== null && value !== '';
            }
            let params = '?';
            [
                'skip',
                'take',
                'requireTotalCount',
                'requireGroupCount',
                'sort',
                'filter',
                'totalSummary',
                'group',
                'groupSummary'
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i])) { params += `${i}=${JSON.stringify(loadOptions[i])}&`; }
            });
            params = params.slice(0, -1);
            let url = `${API_DEVEXPRESS_ENDPOINT}devexpress/users${params}&expired=` + props.filter.expired
            if (props.filter.type !== '') {
                url = url + "&&user_type=" + props.filter.type
            }
            if (props.filter.name !== "" || props.filter.license !== "") {
                url = url + "&&name=" + props.filter.name + '&&license=' + props.filter.license + '&&user_type=' + props.filter.type
            }
            return fetch(url)
                .then(response => response.json())
                .then((data) => {
                    return {
                        data: data.data,
                        totalCount: data.totalCount,
                        summary: data.summary,
                        groupCount: data.groupCount
                    };
                })
                .catch(() => { throw 'Data Loading Error'; });
        }
    });

    const isNotEmpty = (value) => {
        return value !== undefined && value !== null && value !== '';
    }

    const onExporting = (e) => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');

        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
            autoFilterEnabled: true
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
            });
        });
        e.cancel = true;
    }

    const determine_status = (data) => {
        switch (data.data.status) {
            case 1:
                return (
                    <Badge
                        className={"font-size-12 badge-soft-success"}
                        color={"success"}
                        pill
                    >
                        ปกติ
                    </Badge>
                );
            case 2:
                return (
                    <Badge
                        className={"font-size-12 badge-soft-warning"}
                        color={"warning"}
                        pill
                    >
                        ระงับใช้งาน
                    </Badge>
                );
            case 3:
                return (
                    <Badge
                        className={"font-size-12 badge-soft-danger"}
                        color={"danger"}
                        pill
                    >
                        ลบ
                    </Badge>
                );
        }
    }

    const Operation = (data) => {
        if ([1, 4].includes(data.data.type_id)) {
            return (
                <div style={{ alignSelf: "center", textAlign: "center" }}>
                    <i
                        onClick={() => {
                            window.open("/Userdetail/" + data.data.id+"?mode=write");
                        }}
                        style={{ color: "gray", cursor: "pointer" }}
                        className="mdi mdi-pencil font-size-18 "
                    ></i>
    
                    {data.data.status != 3 ? (
                        <i
                            onClick={() => {
                                this.set_delete_users(data.data.id);
                            }}
                            style={{ color: "gray", cursor: "pointer" }}
                            className="mdi mdi-delete font-size-18 "
                        ></i>
                    ) : (
                        ""
                    )}
                </div>
            );
        } else {
            return (
                <div />
            );
        }
        
    }

    const renderMyCommand = (data) => {
        return (
            <Link
                to={{
                    pathname: "/Userdetail/" + data.data.id + "?" + "mode=read",
                    // userDetail: data.data,
                    // userData: this.auth,
                    // mode: 'read'
                }}
            >
                <Button type="button" color="primary" className="btn-sm btn-rounded">
                    View Details
                </Button>
            </Link>
        );
    }

    const expired_date = (cellData) => {
        console.log(cellData)
        if (cellData.data.type_id === 2) {
            return (
                <div>
                    {cellData.data.guide_license_expired !== '' ?cellData.data.guide_license_expired : 'ไม่พบข้อมูล'}
                </div>
            );
        }
        if (cellData.data.type_id === 3) {
            return (
                <div>
                    {cellData.data.agency_license_expired !== '' ?cellData.data.agency_license_expired : 'ไม่พบข้อมูล'}
                </div>
            );
        }
        else {
            return (
                <div>
                    -
                </div>
            );
        }

    }

    return (
        <div>
            <DataGrid
                dataSource={store}
                showBorders={true}
                onExporting={onExporting}
            >
                <RemoteOperations groupPaging={true} />
                <Grouping autoExpandAll={false} />
                <GroupPanel visible={true} />
                {/* <SearchPanel visible={true}
                    width={240}
                    placeholder="Search..." /> */}
                <Column dataField="id" caption="id" width={50} />
                <Column dataField="username" caption="username" />
                <Column dataField="fname" caption="firstname" />
                <Column dataField="lname" caption="lastname" />
                <Column dataField="email" caption="email" />
                <Column dataField="type_name" caption="type" />
                <Column
                    dataField="expired_date"
                    type="buttons"
                    cellRender={expired_date}
                    caption="expired date" 
                ></Column>
                <Column
                    dataField="Status"
                    type="buttons"
                    cellRender={determine_status}
                    caption="status"
                ></Column>
                <Column
                    dataField="Detail"
                    type="buttons"
                    cellRender={renderMyCommand}
                    caption="detail"
                ></Column>
                <Column
                    dataField="Operation"
                    type="buttons"
                    cellRender={Operation}
                    caption="manage"
                ></Column>

                <Paging defaultPageSize={20} />
                <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[5, 10, 20]}
                    visible={true}
                    showInfo={true}
                />
                <Export enabled={true} allowExportSelectedData={false} />
            </DataGrid>
        </div>
    )
}

export default UsersGrid;