import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { Container, Row, Col, Card, CardBody, TabContent, TabPane, NavItem, NavLink, Label, Form, FormGroup, Button, CardImg, Input, CardTitle, CardSubtitle, Table } from "reactstrap";
import profile from '../../assets/images/small/img-4.jpg'
import classnames from 'classnames';
import { withRouter } from "react-router-dom";

import { API_ENDPOINT, API_UPLOAD_ENDPOINT } from '../../assets/api'

import Swal from 'sweetalert2'

export default withRouter (class Userdetail extends Component {
    constructor(props) {
        super(props);
        const id = this.props.match.params.id
        const mode = new URLSearchParams(this.props.location.search).get("mode")
        console.log(this.props.location.mode)
        try {
            this.status_select = [
                {
                    value: 1,
                    text: 'เปิดใช้งาน ( Active )'
                },
                {
                    value: 2,
                    text: 'ระงับใช้งาน ( Suspend )'
                },
                {
                    value: 3,
                    text: 'ลบ ( Delete )'
                },
            ]
            this.state = {
                activeTab: '1',
                // userDetail: this.props.location.userDetail,

                edit_type: '',
                edit_mode: mode,
                edit_ready: false,
                raw: {}
            }
        } catch (err) {
            window.location = "/"
        }
        this.toggleTab = this.toggleTab.bind(this);
        this.get_users(id);
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    async get_users(id) {
        var token = localStorage.getItem('authToken');
        token = JSON.parse(token)
        var response = await fetch(API_ENDPOINT + 'api/v2/web/users/get/' + id, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.data.type + ' ' + token.data.token
            },
        });
        response = await response.json()
        console.log(response)
        if (response.agency_id == null && response.guide_id == null) {
            var edit_type = 'admin';
        } else if (response.agency_id == null) {
            var edit_type = 'guide';
        } else {
            var edit_type = 'agency';
        }

        this.setState({
            raw: response,
            edit_ready: true,
            edit_type: edit_type,
            edit_information: {
                photo: response.photo,
                username: response.username,
                email: response.email,
                firstname: response.fname,
                lastname: response.lname,
                status: response.status,
                identication_card: response.identication_card
            },
            edit_guide: {
                id: response.guide_id,
                license: response.guide_license,
                address: response.guide_address
            },
            edit_agency: {
                id: response.agency_id,
                name: response.agency_name,
                license: response.agency_license,
                address: response.agency_address
            },
        }, () => {
            console.log(this.state)
        })
    }

    async sweet_error(msg) {
        return Swal.fire({
            icon: 'error',
            title: 'ผิดพลาด',
            text: msg,
        })
    }
    async sweet_success(msg) {
        return Swal.fire({
            icon: 'success',
            title: 'สำเร็จ',
            text: msg,
        })
    }

    async edit_users_information() {
        var users_id = this.state.raw.id
        var object = {
            username: this.state.edit_information.username,
            firstname: this.state.edit_information.firstname,
            lastname: this.state.edit_information.lastname,
            email: this.state.edit_information.email,
            status: this.state.edit_information.status,
            identication_card: this.state.edit_information.identication_card
        }
        var token = localStorage.getItem('authToken');
        token = JSON.parse(token)
        var response = await fetch(API_ENDPOINT + 'api/v2/web/users/update/information', {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.data.type + ' ' + token.data.token
            },
            body: JSON.stringify({
                users_id: users_id,
                information: object
            })
        });
        response = await response.json()
        if (response.status == 'success') {
            return this.sweet_success('แก้ไขข้อมูลสำเร็จแล้ว ')
        } else {
            return this.sweet_error('แก้ไขข้อมูลบล้มเหลว ')
        }
    }

    async edit_guide_information() {
        var guide_id = this.state.edit_guide.id
        var object = {
            license: this.state.edit_guide.license,
            address: this.state.edit_guide.address
        }
        var token = localStorage.getItem('authToken');
        token = JSON.parse(token)
        var response = await fetch(API_ENDPOINT + 'api/v2/web/users/update/guide', {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.data.type + ' ' + token.data.token
            },
            body: JSON.stringify({
                guide_id: guide_id,
                guide: object
            })
        });
        response = await response.json()
        if (response.status == 'success') {
            return this.sweet_success('แก้ไขข้อมูลสำเร็จแล้ว ')
        } else {
            return this.sweet_error('แก้ไขข้อมูลบล้มเหลว ')
        }
    }

    async edit_agency_information() {
        var agency_id = this.state.edit_agency.id
        var object = {
            name: this.state.edit_agency.name,
            license: this.state.edit_agency.license,
            address: this.state.edit_agency.address
        }
        var token = localStorage.getItem('authToken');
        token = JSON.parse(token)
        var response = await fetch(API_ENDPOINT + 'api/v2/web/users/update/agency', {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.data.type + ' ' + token.data.token
            },
            body: JSON.stringify({
                agency_id: agency_id,
                agency: object
            })
        });
        response = await response.json()
        if (response.status == 'success') {
            return this.sweet_success('แก้ไขข้อมูลสำเร็จแล้ว ')
        } else {
            return this.sweet_error('แก้ไขข้อมูลบล้มเหลว ')
        }
    }

    // async editUser() {
    //     var response = await fetch(API_ENDPOINT + 'api/v2/web/users/edit', {
    //         method: 'POST',
    //         headers: {
    //             Accept: 'application/json',
    //             'Content-Type': 'application/json',
    //             Authorization: this.auth.data.type + ' ' + this.auth.data.token,

    //         },
    //         body: JSON.stringify({
    //             "users_id": this.state.userDetail.id,
    //             "edit_object": this.state.userDetail + this.state.userDetail.type_alias
    //         })
    //     });
    //     var responseJson = await response.json();
    //     console.log(JSON.stringify(responseJson))
    // }

    async change_avatar(e) {
        Swal.fire({
            title: 'ยืนยันหรือไม่ ?',
            text: "ต้องการเปลี่ยนรูปประจำตัวใช่หรือไม่",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'ยกเลิก',
            confirmButtonText: 'ยืนยัน!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                var formData = new FormData()
                formData.append('profile_pics', e.target.files[0], e.target.files[0].name);
                formData.append('users_id', this.state.raw.id);
                var auth = JSON.parse(await localStorage.getItem('authToken'))
                var url = API_ENDPOINT + "api/v2/web/users/change_image"
                var dev_url = "https://joborder-api.dot.go.th/api/v2/web/profile/change_image"
                var changephoto_response = await fetch(url, {
                    method: 'POSt',
                    headers: {
                        Authorization: auth.data.type + ' ' + auth.data.token,
                    },
                    body: formData
                })
                var changephoto_object = await changephoto_response.json()
                if (changephoto_object.status == 'success') {
                    Swal.fire(
                        'สำเร็จ !',
                        'ระบบทำการเปลี่ยนรูปแล้ว',
                        'success'
                    ).then(async () => {
                        window.location.reload()
                    })

                } else {
                    Swal.fire(
                        'ล้มเหลว !',
                        'กรุณาตรวจสอบ รหัสผ่านให้เหมือนกัน',
                        'error'
                    )
                    return
                }
            }
        })
    }

    componentDidMount() {
    }

    render() {
        if (this.state.edit_ready == false) {
            return (
                <React.Fragment><div>Loading...</div></React.Fragment>
            )
        }
        return (
            <React.Fragment>
                <div className="page-content">
                    <div className="container-fluid" style={{ position: 'sticky' }}>
                        <Breadcrumbs title="User detail" breadcrumbItem="User" />
                        <div>
                            <Card>
                                <CardBody style={{ borderTop: "solid", borderTopColor: "#F4D006", borderWidth: 10 }}>
                                    <Row>
                                        <Col lg={2}>
                                            <div style={{ textAlign: "center" }}>
                                                <img onClick={() => {
                                                        document.getElementById('photo_input').click()
                                                }} src={API_ENDPOINT+'/profile/photo/' + this.state.raw.id} alt="" className="avatar-md rounded-circle" />
                                            </div>
                                            <input id="photo_input" style={{ display: 'none' }} onChange={(e) => { this.change_avatar(e) }} type="file"></input>
                                        </Col>
                                        <Col lg={8}>
                                            <Row>
                                                <Col>
                                                    <h4>{this.state.edit_information.firstname} {this.state.edit_information.lastname}</h4>
                                                </Col>

                                            </Row>
                                            {/* <Row  >
                                                <Col >
                                                    <p>เปลี่ยนรูปโปรไฟล์ {this.props.location.mode}</p>
                                                </Col>
                                            </Row> */}
                                        </Col>
                                        {/* <Col lg={2} style={{ alignSelf: "center" }} >
                                            <div style={{ textAlign: "right", }}>
                                                <h6 style={{ color: "#224AAC" }}>เจ้าหน้าที่กรมการท่องเที่ยว LV.0</h6>
                                            </div>
                                        </Col > */}
                                    </Row>
                                </CardBody>
                            </Card>
                        </div>
                        <div>
                            <Card>
                                <CardBody>
                                    <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                                        <NavItem>
                                            <NavLink className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggleTab('1'); }} >
                                                ตั้งค่าผู้ใช้งาน
                                            </NavLink>
                                        </NavItem>

                                        {this.state.edit_type == 'guide' && (
                                            <NavItem>
                                                <NavLink className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggleTab('2'); }} >
                                                    ข้อมูลมัคคุเทศน์
                                             </NavLink>
                                            </NavItem>
                                        )}
                                        {this.state.edit_type == 'agency' && (
                                            <NavItem>
                                                <NavLink className={classnames({ active: this.state.activeTab === '3' })} onClick={() => { this.toggleTab('3'); }} >
                                                    ข้อมูลบริษัทนำเที่ยว
                                                </NavLink>
                                            </NavItem>
                                        )}
                                    </ul>
                                    <TabContent activeTab={this.state.activeTab} className="p-3">
                                        <TabPane tabId="1" id="all-order">
                                            <Form>
                                                <Row>
                                                    <Col lg="6">

                                                        <FormGroup>
                                                            <Label for="kycfirstname-input">ชื่อผู้ใช้ ( แก้ไขไม่ได้ )</Label>
                                                            <Input type="text" value={this.state.edit_information.username} readOnly className="form-control" id="kycfirstname-input" placeholder="" />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <Label for="kyclastname-input">สถานะ</Label>
                                                            <select value={this.state.edit_information.status} onChange={(e) => {
                                                                let edit_information = this.state.edit_information;
                                                                edit_information.status = e.target.value;
                                                                this.setState({
                                                                    edit_information: edit_information
                                                                })
                                                            }} className="custom-select">
                                                                <option value="0" defaultValue>กรุณาเลือกสถานะ</option>
                                                                {this.status_select.map((element, i) => {
                                                                    return (<option key={i} value={element.value}>{element.text}</option>)
                                                                })}
                                                            </select>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="6">

                                                        <FormGroup>
                                                            <Label for="kycfirstname-input">ชิ่อจริง</Label>
                                                            <Input type="text" value={this.state.edit_information.firstname} onChange={(e) => {
                                                                let edit_information = this.state.edit_information;
                                                                edit_information.firstname = e.target.value;
                                                                this.setState({
                                                                    edit_information: edit_information
                                                                })
                                                            }} className="form-control" id="kycfirstname-input" placeholder="" />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <Label for="kycfirstname-input">นามสกุล</Label>
                                                            <Input type="text" value={this.state.edit_information.lastname} onChange={(e) => {
                                                                let edit_information = this.state.edit_information;
                                                                edit_information.lastname = e.target.value;
                                                                this.setState({
                                                                    edit_information: edit_information
                                                                })
                                                            }} className="form-control" id="kycfirstname-input" placeholder="" />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="6">

                                                        <FormGroup>
                                                            <Label for="kycfirstname-input">อีเมล์</Label>
                                                            <Input type="text" value={this.state.edit_information.email} onChange={(e) => {
                                                                let edit_information = this.state.edit_information;
                                                                edit_information.email = e.target.value;
                                                                this.setState({
                                                                    edit_information: edit_information
                                                                })
                                                            }} className="form-control" id="kycfirstname-input" placeholder="" />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <Label for="kycfirstname-input">บัตรประชาชน</Label>
                                                            <Input type="text" value={this.state.edit_information.identication_card} onChange={(e) => {
                                                                let edit_information = this.state.edit_information;
                                                                edit_information.identication_card = e.target.value;
                                                                this.setState({
                                                                    edit_information: edit_information
                                                                })
                                                            }} className="form-control" id="kycfirstname-input" placeholder="" />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col lg={8} ></Col>
                                                    <Col lg={2} style={{ textAlign: "right" }}>

                                                        {this.state.edit_mode == 'write' && (
                                                            <Button
                                                                onClick={() => { this.edit_users_information() }}
                                                                color="primary"
                                                                className="mt-3"
                                                                style={{ width: "100%" }}
                                                                block
                                                            > บันทึก
                                                            </Button>
                                                        )}
                                                    </Col>
                                                    <Col lg={2}>
                                                        <Button
                                                            onClick={() => { this.props.history.goBack() }}
                                                            color="secondary"
                                                            className="mt-3"
                                                            style={{ width: "100%" }}
                                                            block
                                                        > กลับ </Button>
                                                    </Col>
                                                </Row>
                                            </Form>

                                        </TabPane>
                                        {this.state.edit_type == 'guide' && (
                                            <TabPane tabId="2" id="guide">
                                                <Form>
                                                    <Row>
                                                        <Col lg="6">
                                                            <FormGroup>
                                                                <Label for="kycfirstname-input">เลขที่ใบอนุญาต</Label>
                                                                <Input value={this.state.edit_guide.license} onChange={(e) => {
                                                                    let edit_guide = this.state.edit_guide;
                                                                    edit_guide.license = e.target.value;
                                                                    this.setState({
                                                                        edit_guide: edit_guide
                                                                    })
                                                                }} type="text" className="form-control" placeholder="" />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg="12">
                                                            <FormGroup>
                                                                <Label for="kycfirstname-input">ที่อยู่มัคคุเทศน์</Label>

                                                                <textarea rows={4} value={this.state.edit_guide.address} onChange={(e) => {
                                                                    let edit_guide = this.state.edit_guide;
                                                                    edit_guide.address = e.target.value;
                                                                    this.setState({
                                                                        edit_guide: edit_guide
                                                                    })
                                                                }} className="form-control" placeholder="" ></textarea>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg={8} ></Col>
                                                        <Col lg={2} style={{ textAlign: "right" }}>

                                                            {this.state.edit_mode == 'write' && (
                                                                <Button
                                                                    onClick={() => { this.edit_guide_information() }}
                                                                    color="primary"
                                                                    className="mt-3"
                                                                    style={{ width: "100%" }}
                                                                    block
                                                                > บันทึก
                                                                </Button>
                                                            )}
                                                        </Col>
                                                        <Col lg={2}>
                                                            <Button
                                                                onClick={() => { this.props.history.goBack() }}
                                                                color="secondary"
                                                                className="mt-3"
                                                                style={{ width: "100%" }}
                                                                block
                                                            > กลับ </Button>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </TabPane>
                                        )}
                                        {this.state.edit_type == 'agency' && (
                                            <TabPane tabId="3" id="agency">
                                                <Form>
                                                    <Row>
                                                        <Col lg="6">
                                                            <FormGroup>
                                                                <Label for="kycfirstname-input">ชื่อบริษัทนำเที่ยว</Label>
                                                                <Input value={this.state.edit_agency.name} onChange={(e) => {
                                                                    let edit_agency = this.state.edit_agency;
                                                                    edit_agency.name = e.target.value;
                                                                    this.setState({
                                                                        edit_agency: edit_agency
                                                                    })
                                                                }} type="text" className="form-control" placeholder="" />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col lg="6">
                                                            <FormGroup>
                                                                <Label for="kycfirstname-input">เลขที่ใบอนุญาต</Label>
                                                                <Input value={this.state.edit_agency.license} onChange={(e) => {
                                                                    let edit_agency = this.state.edit_agency;
                                                                    edit_agency.license = e.target.value;
                                                                    this.setState({
                                                                        edit_agency: edit_agency
                                                                    })
                                                                }} type="text" className="form-control" placeholder="" />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg="12">
                                                            <FormGroup>
                                                                <Label for="kycfirstname-input">ที่อยู่มัคคุเทศน์</Label>
                                                                <textarea rows={4} value={this.state.edit_agency.address} onChange={(e) => {
                                                                    let edit_agency = this.state.edit_agency;
                                                                    edit_agency.address = e.target.value;
                                                                    this.setState({
                                                                        edit_agency: edit_agency
                                                                    })
                                                                }} className="form-control" placeholder="" ></textarea>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg={8} ></Col>
                                                        <Col lg={2} style={{ textAlign: "right" }}>

                                                            {this.state.edit_mode == 'write' && (
                                                                <Button
                                                                    onClick={() => { this.edit_agency_information() }}
                                                                    color="primary"
                                                                    className="mt-3"
                                                                    style={{ width: "100%" }}
                                                                    block
                                                                > บันทึก
                                                                </Button>
                                                            )}
                                                        </Col>
                                                        <Col lg={2}>
                                                            <Button
                                                                onClick={() => { this.props.history.goBack() }}
                                                                color="secondary"
                                                                className="mt-3"
                                                                style={{ width: "100%" }}
                                                                block
                                                            > กลับ </Button>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </TabPane>
                                        )}
                                    </TabContent>

                                </CardBody>
                            </Card>
                        </div>


                    </div>

                </div>
            </React.Fragment >
        )
    }
})
