import React, { Component, useEffect, useState } from "react";
import "devextreme/data/odata/store";
import DataGrid, {
  Column,
  Paging,
  Pager,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Export,
  RemoteOperations,
  Grouping,
  GroupPanel,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import "devextreme/dist/css/dx.light.css";
import { exportDataGrid } from "devextreme/excel_exporter";
import ExcelJS from "exceljs";
import saveAs from "file-saver";
import { API_DEVEXPRESS_ENDPOINT } from "../../../assets/api";

import { IMAGE_ENDPOINT } from "../../../assets/api";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Input,
  Button,
  Card,
  CardBody,
  NavItem,
  NavLink,
  Table,
  Label,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
  Pagination,
  PaginationItem,
  PaginationLink,
  FormGroup,
} from "reactstrap";
const UsersGrid = (props) => {
  useEffect(() => {
    console.log(props.user);
  }, []);

  const store = new CustomStore({
    key: "id",
    load: function (loadOptions) {
      let isNotEmpty = (value) => {
        return value !== undefined && value !== null && value !== "";
      };
      let params = "?";
      [
        "skip",
        "take",
        "requireTotalCount",
        "requireGroupCount",
        "sort",
        "filter",
        "totalSummary",
        "group",
        "groupSummary",
      ].forEach(function (i) {
        if (i in loadOptions && isNotEmpty(loadOptions[i])) {
          params += `${i}=${JSON.stringify(loadOptions[i])}&`;
        }
      });
      params = params.slice(0, -1);
      let url = `${API_DEVEXPRESS_ENDPOINT}devexpress/placeslist${params}&lang=th`;
      if (props.filter.name !== "") {
        url = url + "&name=" + props.filter.name;
      }
      if (props.filter.province !== "") {
        url = url + "&province=" + props.filter.province;
      }
      if (props.filter.district !== "") {
        url = url + "&district=" + props.filter.district;
      }
      if (props.filter.sub_district !== "") {
        url = url + "&sub_district=" + props.filter.sub_district;
      }
      if (props.filter.status !== "") {
        url = url + "&status=" + props.filter.status;
      }
      return fetch(url)
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          return {
            data: data.data,
            totalCount: data.totalCount,
            summary: data.summary,
            groupCount: data.groupCount,
          };
        })
        .catch(() => {
          throw "Data Loading Error";
        });
    },
  });

  const isNotEmpty = (value) => {
    return value !== undefined && value !== null && value !== "";
  };

  const onExporting = (e) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Main sheet");

    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          "DataGrid.xlsx"
        );
      });
    });
    e.cancel = true;
  };

  const calculateCellValue = (Orders) => {
    return [Orders.name];
  };
  const calculateCellphoto = (cellData) => {
    return (
      <img
        style={{
          borderRadius: "50%",
          width: 50,
          height: 50,
          verticalAlign: "middle",
        }}
        src={IMAGE_ENDPOINT + String(cellData.value).split(";")[0]}
      ></img>
    );
  };
  const renderMyCommand = (cellData) => {
    return (
      <div>
        <Link
          to={{
            pathname: "/places/detail",
            placeDetail: cellData.data,
          }}
        >
          <Button type="button" color="primary" className="btn-sm btn-rounded">
            View Details
          </Button>
        </Link>
      </div>
    );
  };

  const renderEdit = (cellData) => {
    // if (
    //   (props.user.type.toString() === "3" &&
    //     cellData.data.created_by.toString() === props.user.id.toString()) ||
    //   props.user.type.toString() != "3"
    // ) {
    if (props.user.type.toString() != "3") {
      return (
        <div>
          <Link
            to={{
              pathname: "/places/edit/" + Number(cellData.data.id),
            }}
          >
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
            >
              Edit
            </Button>
          </Link>
        </div>
      );
    }
    return;
  };

  const status_render = (data) => {
    switch (data.data.status) {
      case "approved":
        return (
          <Badge
            className={"font-size-16 badge-soft-success"}
            color={"success"}
            pill
          >
            อนุมัติ
          </Badge>
        );
      case "new":
        return (
          <Badge
            className={"font-size-16 badge-soft-warning"}
            color={"warning"}
            pill
          >
            ใหม่
          </Badge>
        );
    }
  };

  return (
    <div>
      <DataGrid
        dataSource={store}
        showBorders={true}
        onExporting={(e) => {
          onExporting(e);
        }}
      >
        <RemoteOperations groupPaging={true} />
        <Grouping autoExpandAll={false} />
        <GroupPanel visible={false} />
        {/* <SearchPanel visible={true}
                    width={240}
                    placeholder="Search..." /> */}
        <Column
          dataField={"photo"}
          width={120}
          cellRender={calculateCellphoto}
          alignment="center"
        />
        <Column dataField={"name"} calculateCellValue={calculateCellValue} />
        <Column dataField="province_name" />
        <Column dataField="district_name" />
        <Column dataField="sub_district_name" />
        <Column dataField="status" type="buttons" cellRender={status_render} />
        <Column
          dataField="Detail"
          type="buttons"
          cellRender={renderMyCommand}
        ></Column>
        <Column
          dataField="edit"
          type="buttons"
          cellRender={renderEdit}
        ></Column>

        <Paging defaultPageSize={20} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={[5, 10, 20]}
          visible={true}
          showInfo={true}
        />
        <Export enabled={true} allowExportSelectedData={false} />
      </DataGrid>
    </div>
  );
};

export default UsersGrid;
