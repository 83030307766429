import React, { Component } from "react";
import PropTypes from "prop-types";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardImg,
  CardText,
  CardHeader,
  CardImgOverlay,
  CardFooter,
  CardDeck,
  CardColumns,
  Container,
  Button,
  Collapse,
  Media,
  Modal,
  FormGroup,
  Label,
  Progress,
} from "reactstrap";
import img6 from "../../assets/images/small/img-6.jpg";
import { Link } from "react-router-dom";
// import Modaladd from "./components/kabannew/Modal-add"
// import ModalGuide from "./components/Tasks/Modal-Guide"
// import modalsave from "../../assets/images/small/modalsave.svg";
import { AvForm, AvField } from "availity-reactstrap-validation";
import SweetAlert from "react-bootstrap-sweetalert";
import App from "./components/kabannew/kabannew";
import { connect } from "react-redux";
import { API_ENDPOINT } from "../../assets/api";
import Swal from "sweetalert2";
import "./components/custom.scss";
import { withTranslation } from "react-i18next";
import "./Trip.scss";

class Groupsave extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
      modal: false,
      modalType: "undefine",

      confirm_both: false,
      success_dlg: false,
      success_msg: false,
      modal_xlarge: false,
      modal_center: false,
      toggleSwitch: true,
      modal_standard: false,
      isLoading: true,
      tripAdded: [],
      tripName: "",
      tripType: "สาธารณะ",
      description: "",

      visible: "1",
    };
    this.auth = JSON.parse(localStorage.getItem("authToken"));
    this.tog_standard = this.tog_standard.bind(this);

    this.avfieldHandle = this.avfieldHandle.bind(this);

    this.modalCallback = this.modalCallback.bind(this);
    this.collapse = this.collapse.bind(this);

    this.callbackKaban = this.callbackKaban.bind(this);
    this.saveplace = this.saveplace.bind(this);
    this.checkBeforeSave = this.checkBeforeSave.bind(this);
    this.alert = this.alert.bind(this);
    this.deleteDay = this.deleteDay.bind(this);
    // console.log(props);

    this.handleChangeVisible = this.handleChangeVisible.bind(this);
  }

  handleChangeVisible(e) {
    console.log("Visible Selected!!"+e.target.value);
    this.setState({ visible: e.target.value });
  }

  alert(value) {
    if (value == "lenght") {
      return Swal.fire(
        "ผิดพลาด!",
        "กรุณาเลือกสถานที่ท่องเที่ยวให้ครบ ",
        "warning"
      );
    }
    if (value == "confirm") {
      return Swal.fire({
        title: "บันทึกแผ่นการท่องเที่ยวใหม่",
        text: "คุณต้องการบันทึกแผนการท่องเที่ยวนี้หรือไม่ ? ",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ต้องการ",
        cancelButtonText: "ไม่ต้องการ",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            "สำเร็จ",
            "เพิ่มแผนการท่องเที่ยวใหม่สำเร็จแล้ว ",
            "success"
          );
          this.saveplace();
          this.setState({ modal_standard: false });
        } else {
          return;
        }
      });
    }
    if (value == "success") {
      this.props.history.push("/Trip");
      return Swal.fire(
        "สำเร็จ",
        "เพิ่มแผนการท่องเที่ยวใหม่สำเร็จแล้ว ",
        "success"
      );
    }
    if (value == "day") {
      console.log(this.state.tripAdded);
      let arr = this.state.tripAdded;
      arr[0].tripRange.push({
        day: arr[0].tripRange.length + 1,
        isOpen: false,
      });
      this.setState({
        tripAdded: arr,
      });
    }
  }

  deleteDay(i) {
    console.log(i);
    Swal.fire({
      title: "แจ้งเตือน",
      text: "คุณต้องการลบวันท่องเที่ยววันที่ " + (i + 1) + " หรือไม่ ? ",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ต้องการ",
      cancelButtonText: "ไม่ต้องการ",
    }).then((result) => {
      if (result.isConfirmed) {
        let arr = this.state.tripAdded;
        arr[0].tripRange.splice(i, 1);
        console.log(arr);
        this.setState(
          {
            tripAdded: arr,
          },
          () => {
            for (var i = 0; i < arr[0].tripRange.length; i++) {
              console.log(arr[0].tripRange[i]);
              if (arr[0].tripRange[i].placesTmp == undefined) {
                arr[0].tripRange[i] = {
                  day: i + 1,
                  isOpen: arr[0].tripRange[i].isOpen,
                };
                console.log(arr[0].tripRange[i]);
                this.setState(
                  {
                    tripAdded: arr,
                  },
                  () => {
                    console.log(this.state.tripAdded);
                  }
                );
              } else {
                arr[0].tripRange[i] = {
                  day: i + 1,
                  isOpen: arr[0].tripRange[i].isOpen,
                  placesTmp: arr[0].tripRange[i].placesTmp,
                };
                this.setState(
                  {
                    tripAdded: arr,
                  },
                  () => {
                    console.log(this.state.tripAdded);
                  }
                );
              }
            }
          }
        );
      } else {
        return;
      }
    });
  }

  checkBeforeSave() {
    console.log("checkBeforeSave");
    let arr = this.state.tripAdded[0].tripRange;
    console.log(arr);
    if (arr.length > 0) {
      console.log("lenght");
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].placesTmp == undefined) {
          this.alert("lenght");
          return;
        }
      }
      this.tog_standard();
    } else {
      return;
    }
  }

  tog_standard() {
    this.setState((prevState) => ({
      modal_standard: !prevState.modal_standard,
    }));
    this.removeBodyCss();
  }

  removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  collapse(e) {
    let arr = this.state.tripAdded;
    if (arr[0].tripRange[e].placesTmp == undefined) {
      arr[0].tripRange[e] = {
        day: arr[0].tripRange[e].day,
        isOpen: !arr[0].tripRange[e].isOpen,
      };
      this.setState(
        {
          tripAdded: arr,
        },
        () => {
          console.log(this.state.tripAdded);
        }
      );
    } else {
      arr[0].tripRange[e] = {
        day: arr[0].tripRange[e].day,
        isOpen: !arr[0].tripRange[e].isOpen,
        placesTmp: arr[0].tripRange[e].placesTmp,
      };
      this.setState(
        {
          tripAdded: arr,
        },
        () => {
          console.log(this.state.tripAdded);
        }
      );
    }
  }

  modalCallback(title, value) {
    console.log(title);
    console.log(value);
    this.setState({
      modal: value,
      modalType: title,
    });
  }

  async saveplace() {
    let arr = {
      tour_name: this.state.tripName,
      tour_description: this.state.description,
      min_recommend: 10,
      max_recommend: 20,
      tour_detail: this.state.tripAdded[0].tripRange,
      description: this.state.description,
      visible: this.state.visible
    };

    console.log(arr.tour_detail);
    for (var detail of arr.tour_detail) {
      detail.places = [];
      var placeordering = 1;
      if ("placesTmp" in detail == false) {
        continue;
      }
      for (var place of detail.placesTmp) {
        var mornings = place.morning;
        for (var item of mornings.items) {
          detail.places.push({
            place_id: item.placesTmp.id,
            daypart: 1,
            placeordering: placeordering,
          });
          placeordering++;
        }

        var afternoons = place.afternoon;
        for (var item of afternoons.items) {
          detail.places.push({
            place_id: item.placesTmp.id,
            daypart: 2,
            placeordering: placeordering,
          });
          placeordering++;
        }

        var evenings = place.evening;
        for (var item of evenings.items) {
          detail.places.push({
            place_id: item.placesTmp.id,
            daypart: 3,
            placeordering: placeordering,
          });
          placeordering++;
        }
      }
    }
    var url = API_ENDPOINT + "api/v2/web/tour/save";
    var dev_url = "https://joborder-api.dot.go.th/api/v2/web/tour/save";
    var response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.auth.data.type + " " + this.auth.data.token,
      },
      body: JSON.stringify(arr), // body data type must match "Content-Type" header
    });
    var responseJson = await response.json();
    console.log(responseJson);
    if (responseJson.status == "success") {
      this.alert("success");

      this.setState(
        {
          modal_standard: false,
        },
        () => { }
      );
    } else {
      return;
    }
  }

  avfieldHandle(e) {
    this.setState({
      [e.target.id]: e.target.value,
    });
  }

  callbackKaban(e, d) {
    console.log(e, d);
    let arr = this.state.tripAdded;
    arr[0].tripRange[d] = {
      day: arr[0].tripRange[d].day,
      isOpen: arr[0].tripRange[d].isOpen,
      placesTmp: [e],
    };
    this.setState(
      {
        tripAdded: arr,
      },
      () => {
        console.log(this.state.tripAdded);
      }
    );
  }

  componentDidMount() {
    document.getElementById("Trip_navagation").classList.add("active");

    console.log("componentDidMount");
    let lcStorage = localStorage.getItem("state");
    console.log(lcStorage);
    if (typeof lcStorage == "string") {
      console.log("GOOD");
      this.setState(
        {
          tripAdded: JSON.parse(lcStorage),
          isLoading: false,
        },
        () => {
          console.log(this.state.tripAdded);
        }
      );
    } else {
      console.log("BAD");
    }
  }

  render() {
    if (this.state.isLoading == true) {
      return <React.Fragment></React.Fragment>;
    } else {
      return (
        <React.Fragment>
          <div className="page-content">
            <Container fluid>
              <Row style={{ display: 'flex', alignItems: 'center' }}>
                <Col xl={8} lg={6} md={6} sm={12} xs={12} style={{ padding: 0 }}>
                  <Breadcrumbs title="Trip" breadcrumbItem={this.props.t("Trip")} />
                </Col>
                <Col xl={2} lg={3} md={3} sm={12} xs={12} style={{ padding: '0px 0px 0px 20px', marginLeft: -5 }}>
                  <Link
                    to={{
                      pathname: "/Trip/Addtrip/Selectinterested",
                      tripAdded: this.state.tripAdded,
                    }}
                  >
                    <button type="button" className="btn btn-primary waves-effect waves-light"
                      style={{ width: "100%", backgroundColor: "#E7E7E7", borderColor: "#AAAAAA" }}>
                      <span className="font-style-16 cl-gray" >
                        {this.props.t("Back")}
                      </span>
                    </button>
                  </Link>
                </Col>
                <Col xl={2} lg={3} md={3} sm={12} xs={12} style={{ padding: '0px 0px 0px 20px', marginLeft: 5 }}>
                  <Link to={"#"}>
                    <button type="button" className="btn btn-primary waves-effect waves-light"
                      onClick={() => {
                        this.checkBeforeSave();
                      }}
                      data-toggle="modal"
                      data-target="#myModal"
                      style={{ width: "100%", backgroundColor: "#224AAC", borderColor: "#224AAC" }}>
                      <span className="font-style-16">
                        {this.props.t("Save Trip")}
                      </span>
                    </button>
                  </Link>
                </Col>
              </Row>
            </Container>
            {/* <Container fluid>
              <Breadcrumbs title="Trip" breadcrumbItem={this.props.t("Trip")} />
            </Container>
            <Card style={{ width: "100%", backgroundColor: "#F0F0F0" }}>
              <CardBody>
                <Container fluid>
                  <Row style={{ justifyContent: "flex-end" }}>
                    <Col xl={2} lg={3} md={6} sm={12} xs={12}>
                      <Link
                        to={{
                          pathname: "/Trip/Addtrip/Selectinterested",
                          tripAdded: this.state.tripAdded,
                        }}
                      >
                        <Button
                          style={{
                            width: "100%",
                            backgroundColor: "#E7E7E7",
                            borderColor: "#AAAAAA",
                          }}
                        >
                          <a style={{ color: "#8C8C8C" }}>
                            {this.props.t("Back")}
                          </a>
                        </Button>
                      </Link>
                    </Col>

                    <Col xl={2} lg={3} md={6} sm={12} xs={12}>
                      <Link to={"#"}>
                        <Button
                          className="mt-button-1 w-button-1"
                          onClick={() => {
                            this.checkBeforeSave();
                          }}
                          data-toggle="modal"
                          data-target="#myModal"
                          style={{
                            width: "100%",
                            backgroundColor: "#224AAC",
                            borderColor: "#224AAC",
                          }}
                        >
                          <a style={{ color: "#FFFFFF" }}>
                            {this.props.t("Save Trip")}
                          </a>
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                </Container>
              </CardBody>
            </Card> */}

            <Row
              style={{
                alignSelf: "center",
                justifyContent: "center",
                marginTop: '1rem'
              }}
            >
              {this.state.tripAdded[0].tripRange.map((e, i) => {
                return (
                  <Container fluid>
                    <Card
                      className="remove_shadow"
                      style={{
                        width: "100%",
                        borderWidth: 1,
                        borderColor: "lightgray",
                        borderRadius: 5,
                        cursor: "pointer",
                        shadowOpacity: 0,
                      }}
                    >
                      <CardBody>
                        {/* <div className="row" > */}
                        {/* <div className="row ml-4 w-kanban-bin" style={{ width: '95%', cursor: "pointer" }} > */}
                        <Row
                          onClick={() => {
                            this.collapse(i);
                          }}
                        >
                          <Col xs={10} lg={10}>
                            <div
                              style={{
                                cursor: "pointer",
                                paddingRight: 10,
                                alignSelf: "center",
                                fontSize: 20,
                                color: "black",
                              }}
                            >
                              <i
                                className="fas fa-angle-down"
                                style={{ marginRight: 10, cursor: "pointer" }}
                              ></i>{" "}
                              {this.props.t("Day")} {e.day}
                            </div>
                          </Col>

                          {/* <div style={{ alignSelf: 'center', paddingTop: 1 }} >
                                                                <p style={{ cursor: "pointer", color: "#000000", fontWeight: 'bold', fontSize: 20 }} >
                                                                    วันที่ {e.day}
                                                                </p>
                                                            </div> */}

                          {/* </div> */}
                          <Col
                            style={{ textAlign: "right", paddingTop: "0.3rem" }}
                            xs={2}
                            lg={2}
                          >
                            {this.state.tripAdded[0].tripRange.length > 1 ? (
                              <div
                                onClick={() => {
                                  this.deleteDay(i);
                                }}
                              >
                                <i
                                  class="fas fa-trash-alt"
                                  style={{
                                    color: "#e74242",
                                    cursor: "pointer",
                                  }}
                                ></i>
                              </div>
                            ) : null}
                          </Col>
                        </Row>
                        {/* </div> */}
                        <Collapse isOpen={e.isOpen}>
                          <App
                            modalCallback={this.modalCallback}
                            callbackKaban={this.callbackKaban}
                            day={i}
                          />
                        </Collapse>
                      </CardBody>
                    </Card>
                  </Container>
                );
              })}
              {/* 
                            {this.state.modalType != "มัคคุเทศก์" && this.state.modal == true ?
                                <Modaladd modalType={this.state.modalType} value={this.state.modal} modalCallback={this.modalCallback} ></Modaladd>
                                :
                                this.state.modalType == "มัคคุเทศก์" && this.state.modal == true ?
                                    <ModalGuide modalType={this.state.modalType} value={this.state.modal} modalCallback={this.modalCallback} ></ModalGuide> : null} */}
            </Row>
            <Container fluid>
              <Row
                style={{ alignSelf: "center", justifyContent: "center" }}
                onClick={() => {
                  this.alert("day");
                }}
              >
                <Card
                  style={{
                    width: "100%",
                    backgroundColor: "transparent",
                    borderWidth: 1,
                    borderColor: "lightgray",
                  }}
                >
                  <CardBody style={{ textAlign: "center", cursor: "pointer" }}>
                    <i
                      className="fas fa-plus"
                      style={{ marginRight: 10, cursor: "pointer" }}
                    ></i>
                    {this.props.t("Add days")}
                  </CardBody>
                </Card>
              </Row>
            </Container>
            <Modal
              isOpen={this.state.modal_standard}
              toggle={this.tog_standard}
            >
              <div className="modal-header">
                <h5
                  className="modal-title mt-0"
                  style={{ fontWeight: "bold" }}
                  id="myModalLabel"
                >
                  {this.props.t("Notification")}
                </h5>
                <button
                  type="button"
                  onClick={() => this.setState({ modal_standard: false })}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <Row>
                  <Col style={{ textAlign: "center", padding: "10px" }}>
                    <img
                      className="rounded mr-2"
                      alt="Skote"
                      width="100"
                      src={'/assets/images/icon/modalsave.svg'}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col style={{ textAlign: "center" }}>
                    <h5 style={{ fontWeight: "bold" }}>
                      {this.props.t("Save a new Trip")}
                    </h5>
                  </Col>
                </Row>
                <Row>
                  <Col xl="12">
                    <AvForm className="needs-validation">
                      <Row>
                        <Col lg={12}>
                          <Row>
                            <Col md="6" style={{ textAlign: "left" }}>
                              <label
                                style={{ fontWeight: "bold" }}
                                className="col-md-12 col-form-label"
                              >
                                {this.props.t("Set a name of your Trip")}
                              </label>
                            </Col>
                            <Col md="6">
                              <label
                                style={{ fontWeight: "bold" }}
                                className="col-md-12 col-form-label"
                              >
                                {this.props.t("Trip Status")}
                              </label>
                            </Col>
                          </Row>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Col lg={12}>
                              <AvField
                                name="tripName"
                                type="text"
                                errorMessage="โปรดใส่ชื่อแผนการท่องเที่ยวของคุณ"
                                className="form-control"
                                validate={{
                                  required: { value: this.state.tripName },
                                }}
                                id="tripName"
                                onChange={(e) => {
                                  this.avfieldHandle(e);
                                }}
                              />
                            </Col>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <div className="col-lg-12">
                              <select
                                className="custom-select"
                                style={{ color: "#28CA00" }}
                                value={this.state.visible} onChange={this.handleChangeVisible}
                              >
                                 <option value="1">{this.props.t("สาธารณะ")}</option> 
                                 <option value="2">{this.props.t("ไม่สาธารณะ")}</option> 
                                {/* <option defaultValue>สาธารณะ</option> */}
                                {/* <option value="1">One</option>
                                                                            <option value="2">Two</option>
                                                                            <option value="3">Three</option> */}
                              </select>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col lg={12}>
                          <Row>
                            <Col md="12" style={{ textAlign: "left" }}>
                              <label
                                style={{ fontWeight: "bold" }}
                                className="col-md-12 col-form-label"
                              >
                                {this.props.t("Description")}
                              </label>
                            </Col>

                          </Row>
                        </Col>
                        <Col md="12">
                          <FormGroup>
                            <Col lg={12}>
                              <AvField
                                name="description"
                                type="text"
                                errorMessage="โปรดใส่ชื่อแผนการท่องเที่ยวของคุณ"
                                className="form-control"
                                validate={{
                                  required: { value: this.state.description },
                                }}
                                id="description"
                                onChange={(e) => {
                                  this.avfieldHandle(e);
                                }}
                              />
                            </Col>
                          </FormGroup>
                        </Col>
                        {/* <Col md="12">
                                                    <FormGroup>
                                                        <Col lg={12}>
                                                            <AvField
                                                                name="discription"
                                                                type="text"
                                                                errorMessage="โปรดใส่รายระเอียดแผนการท่องเที่ยวของคุณ"
                                                                className="form-control"
                                                                validate={{ required: { value: this.state.discription } }}
                                                                id="discription"
                                                                onChange={(e) => { this.avfieldHandle(e) }}
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                </Col> */}
                      </Row>

                      <div className="modal-footer">
                        <button
                          type="reset"
                          className="btn btn-light waves-effect waves-light"
                          onClick={() =>
                            this.setState({ modal_standard: false })
                          }
                        >
                          {this.props.t("Cancel")}
                        </button>
                        <button
                          type="submit"
                          className="btn btn-primary waves-effect waves-light"
                          onClick={() => this.alert("confirm")}
                        >
                          {this.props.t("Confirm")}
                        </button>
                      </div>
                      {/* <Button color="primary" type="submit">Submit form</Button> */}
                    </AvForm>
                  </Col>
                </Row>
              </div>
            </Modal>
          </div>
        </React.Fragment>
      );
    }
  }
}

const mapStatetoProps = (state) => {
  return {
    ...state.Layout,
    login: state.Login,
  };
};
export default connect(mapStatetoProps, {})(withTranslation()(Groupsave));
