import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React, { Component } from "react";
import { Switch, BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";

import {
	saveCapacity
} from "./store/auth/login/actions";
// Import Routes
import { authProtectedRoutes, publicRoutes } from "./routes/";
import AppRoute from "./routes/route";

// layouts
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// fonts 
import './assets/fonts/Kanit-Regular.ttf';

// Import scss
import "./assets/scss/theme.scss";

import "./assets/scss/custom.scss";

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.getLayout = this.getLayout.bind(this);

		console.log(JSON.parse(localStorage.getItem("authCapacity")))
		this.props.saveCapacity(JSON.parse(localStorage.getItem("authCapacity")))

	}

	/**
   * Returns the layout
   */
	getLayout = () => {
		let layoutCls = HorizontalLayout;
		return layoutCls;
	};

	render() {
		const Layout = this.getLayout();
		return (
			<React.Fragment>

				<Router>
					<Switch>

						{publicRoutes.map((route, idx) => (
							<AppRoute
								path={route.path}
								layout={NonAuthLayout}
								component={route.component}
								key={idx}
								isAuthProtected={false}
							/>
						))}

						{authProtectedRoutes.map((route, idx) => {
							return (
								<AppRoute
									path={route.path}
									layout={Layout}
									component={route.component}
									key={idx}
									isAuthProtected={true}
								/>
							)
						})}
					</Switch>
				</Router>
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => {
	return {
		layout: state.Layout,
		login: state.Login
	};
};

export default connect(mapStateToProps, { saveCapacity })(App);
