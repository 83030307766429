import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { Col, Row, Card, CardBody, CardTitle, CardSubtitle, CardImg, CardText, CardHeader, CardImgOverlay, CardFooter, CardDeck, CardColumns, Container, Button, Collapse, Media } from "reactstrap";
import img6 from "../../assets/images/small/img-6.jpg";
import TasksKanbans from "./components/TasksTrip/tasks-kanbans"
import Modaladd from "./components/TasksTrip/Modal-add"
import { Link } from "react-router-dom";
export default class Grouptrips extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: "1",
            activeTab1: "5",
            activeTab2: "9",
            activeTab3: "13",
            activeTab4: "17",
            activeTab6: "21",
            customActiveTab: "1",
            activeTabJustify: "5",
            col: [],
            col1: true,
            col2: false,
            col3: false,
            col4: false,
            col6: false,
            col5: true,
            modal: false,
            modalType: 'undefine',
            tripInfo: this.props.tripInfo,
            tripDay: []
        };
        this.toggle = this.toggle.bind(this);
        this.toggle1 = this.toggle1.bind(this);

        this.t_col = this.t_col.bind(this);
        this.t_col1 = this.t_col1.bind(this);
        this.t_col2 = this.t_col2.bind(this);
        this.t_col3 = this.t_col3.bind(this);
        this.t_col4 = this.t_col4.bind(this);
        this.t_col5 = this.t_col5.bind(this);
        this.t_col6 = this.t_col6.bind(this);

        this.toggle2 = this.toggle2.bind(this);
        this.toggle3 = this.toggle3.bind(this);
        this.toggle4 = this.toggle4.bind(this);
        this.toggle6 = this.toggle6.bind(this);

        this.toggleCustomJustified = this.toggleCustomJustified.bind(this);
        this.toggleCustom = this.toggleCustom.bind(this);
        this.modalCallback = this.modalCallback.bind(this);
        this.settingData = this.settingData.bind(this);
    }

    componentDidMount() {
        console.log(this.state.tripInfo)
        this.settingData()
    }

    settingData() {
        var x = []
        for (var i = 0; i < this.state.tripInfo.length; i++) {
            x.push({ data: this.state.tripInfo[i], status: false })
            let arr = [
                { id: 1 },
                { id: 2 },
                { id: 3 }
            ]
            console.log(this.state.tripInfo[i].dayInfo)
            if (this.state.tripInfo[i].dayInfo.length < 3) {
                for (var j = 0; j < 3; j++) {
                    if (typeof this.state.tripInfo[i].dayInfo[j] == 'undefined') {
                        continue
                    }
                    else {
                        if (this.state.tripInfo[i].dayInfo[j].title == 'เช้า') {
                            arr[0] = this.state.tripInfo[i].dayInfo[j]
                        }
                        if (this.state.tripInfo[i].dayInfo[j].title == 'กลางวัน') {
                            arr[1] = this.state.tripInfo[i].dayInfo[j]
                        }
                        if (this.state.tripInfo[i].dayInfo[j].title == 'เย็น') {
                            arr[2] = this.state.tripInfo[i].dayInfo[j]
                        }
                        else {
                            continue
                        }
                    }

                }
            }
            console.log(arr)
        }
        this.setState({
            tripDay: x
        }, () => {
            console.log(this.state.tripDay)
        })
    }

    t_col(v) {
        console.log(v)
        let tripDay = [...this.state.tripDay];
        tripDay[v] = { ...tripDay[v], status: !tripDay[v].status };
        this.setState({
            tripDay
        }, () => {
            console.log(this.state.tripDay)
        })

    }

    t_col1() {
        this.setState({ col1: !this.state.col1 });
    }
    t_col2() {
        this.setState({ col2: !this.state.col2 });
    }
    t_col3() {
        this.setState({ col3: !this.state.col3 });
    }
    t_col4() {
        this.setState({ col4: !this.state.col4 });
    }
    t_col5() {
        this.setState({ col5: !this.state.col5 });
    }
    t_col6() {
        this.setState({ col6: !this.state.col6 });
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }
    toggle1(tab) {
        if (this.state.activeTab1 !== tab) {
            this.setState({
                activeTab1: tab
            });
        }
    }
    toggle2(tab) {
        if (this.state.activeTab2 !== tab) {
            this.setState({
                activeTab2: tab
            });
        }
    }
    toggle3(tab) {
        if (this.state.activeTab3 !== tab) {
            this.setState({
                activeTab3: tab
            });
        }
    }
    toggle4(tab) {
        if (this.state.activeTab4 !== tab) {
            this.setState({
                activeTab4: tab
            });
        }
    }
    toggle6(tab) {
        if (this.state.activeTab6 !== tab) {
            this.setState({
                activeTab6: tab
            });
        }
    }

    toggleCustomJustified(tab) {
        if (this.state.activeTabJustify !== tab) {
            this.setState({
                activeTabJustify: tab
            });
        }
    }

    toggleCustom(tab) {
        if (this.state.customActiveTab !== tab) {
            this.setState({
                customActiveTab: tab
            });
        }
    }

    modalCallback(title, value) {
        console.log(title)
        console.log(value)
        this.setState({
            modal: value,
            modalType: title
        })
    }

    render() {
        return (
            <React.Fragment>
                {this.state.tripDay.map((e, i) => {
                    return (
                        <Card key={i} style={{ borderWidth: 2, borderColor: 'lightgray', borderRadius: 5 }}>
                            <CardBody style={{ cursor: "pointer" }} onClick={() => { this.t_col(i) }}>
                                {/* <ul className="verti-timeline list-unstyled">
                                    <li className="event-list">
                                        <div className="event-timeline-dot">
                                            <i className="bx bx-right-arrow-circle font-size-18"></i>
                                        </div> */}
                                {/* <Media>
                                    <div className="mr-3">
                                    </div> */}
                                {/* <Media body> */}
                                <h4 className="m-0 font-14">
                                    <span
                                        className="font-style-26 cl-blue">
                                        วันที่ {i + 1}
                                    </span>
                                </h4>
                                <Collapse isOpen={e.status}>
                                    <div style={{ height: '100%' }}>
                                        <div style={{ flexDirection: 'row' }}>
                                            <div className="board-style">
                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                    <TasksKanbans modalCallback={this.modalCallback} data={e.data.dayInfo} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <App modalCallback={this.modalCallback} data={e.data.dayInfo} /> */}
                                </Collapse>
                                {/* </Media>
                                </Media> */}
                                {/* </li>
                                </ul> */}
                            </CardBody>
                        </Card>
                    )
                })}
                {this.state.modal == true ?
                    <Modaladd modalType={this.state.modalType} value={this.state.modal} modalCallback={this.modalCallback} ></Modaladd>
                    :
                    null}
            </React.Fragment>
        )
    }
}
