import React, { Component, useEffect, useState } from 'react';
import AnyChart from 'anychart-react'

const ParetoChart = (props) => {
    const [resultSearch, setResultSearch] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        if (props.report.search === "") {
            setResultSearch(props.report.view)
        } else {
            setResultSearch(props.report.search)
        }
        if (!isLoading) {
            console.log("report_result:", props)
        }
    }, [isLoading, props]);

    return (
        <div>
            <AnyChart
                width={'100%'}
                height={400}
                type='column'
                data={
                    props.report.pareto_data
                }
                title={'ผลลัพธ์การค้นหา : ' + resultSearch}
            />
        </div>
    )
}

export default ParetoChart;