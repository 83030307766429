import React, { Component } from "react";
//i18n
import { withTranslation } from 'react-i18next';
import { Button } from "reactstrap";
import './intro.css'
import DropdownIntro from "./DropdownIntro/DropdownIntro";
class NavbarIntro extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
    }


    render() {
        return (
            <React.Fragment>
                <div className="topnavbar">
                    {/* <div className="dropdown-intro">
                        <DropdownIntro />
                    </div> */}
                    <div className="logo-tourism">
                        <img src={'/assets/images/logo/logo.png'} alt={"logo"} />
                        <div className="text-logo">
                            <h6 className="font-style-24">ระบบใบสั่งงานมัคคุเทศก์ออนไลน์</h6>
                            <h6 className="font-style-18">Job Order Online</h6>
                        </div>
                    </div>
                    <div className="container-intro">
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default (withTranslation()(NavbarIntro));
