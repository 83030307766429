import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, Button, Nav, NavItem, NavLink, TabContent, TabPane, Table, Media, CardHeader } from "reactstrap";
import classnames from 'classnames';
import { Map, InfoWindow, Marker, GoogleApiWrapper, heatMapData, HeatMap, gradient, positions, data } from "google-maps-react";
import ReactReadMoreReadLess from "react-read-more-read-less";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import "./style.scss";

import "./image-gallery.scss";
//Import Star Ratings
import StarRatings from 'react-star-ratings';
import { connect } from "react-redux";
//Import Product Images
// import facebook from "../../assets/images/facebook.png"
// import IG from "../../assets/images/instagram.png"
// import shop from "../../assets/images/shop.png"

// import food1 from "../../assets/images/imgesfooddetail/food1.jpg"
// import food2 from "../../assets/images/imgesfooddetail/food2.jpg"
// import food3 from "../../assets/images/imgesfooddetail/food3.jpg"
// import food4 from "../../assets/images/imgesfooddetail/food4.jpg"

//Import Breadcrumb
import { IMAGE_ENDPOINT } from "../../assets/api";
import { API_ENDPOINT } from "../../assets/api";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { withTranslation } from 'react-i18next';

const LoadingContainer = props => <div>Loading...</div>;

class Placedetail extends Component {
    constructor(props) {
        super(props);
        this.state = {

            Orders: [],

            showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: {},

            activeTab: '1',
            isLoading: true
        }
        console.log(this.props)
        // this.toggleTab = this.toggleTab.bind(this);
        this.imageShow = this.imageShow.bind(this);
        this.onMarkerClick = this.onMarkerClick.bind(this);
        this.getPlacesById = this.getPlacesById.bind(this);
        this.auth = JSON.parse(localStorage.getItem('authToken'))
    }

    onMarkerClick(props, marker, e) {
        alert("You clicked in this marker");
    }

    // toggleTab(tab) {
    //     if (this.state.activeTab !== tab) {
    //         this.setState({
    //             activeTab: tab
    //         });
    //     }
    // }

    imageShow(img, id) {
        var expandImg = document.getElementById("expandedImg" + id);
        expandImg.src = img;
    }

    componentDidMount() {
        if (document.getElementById("Management_navagation")) {
            document.getElementById("Management_navagation").classList.add("active");
        }

        console.log('componentDidMount')
        let lcStorage = localStorage.getItem('statePlace')
        console.log(lcStorage)
        if (typeof lcStorage == 'string') {
            console.log('GOOD')
            this.setState({
                Orders: JSON.parse(lcStorage),
                isLoading: false
            }, () => {
                console.log(this.state.Orders)
            })

        }
        else {
            console.log('BAD')
            console.log(JSON.stringify(this.props.location.placeDetail))
            if (this.props.location.placeDetail == undefined) {
                var place_id = window.location.search.split('placeid=')[1]
                this.getPlacesById(place_id)
            }
            else {
                localStorage.setItem('statePlace', JSON.stringify(this.props.location.placeDetail))
                this.setState({
                    Orders: JSON.parse(localStorage.getItem('statePlace')),
                    isLoading: false
                }, () => {
                    console.log(this.state.Orders)
                })
            }

        }
    }

    async getPlacesById(place_id) {
        var response = await fetch(API_ENDPOINT + 'api/v2/web/place/get/' + place_id + '?lang=th', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: this.auth.data.type + ' ' + this.auth.data.token,

            }
        });
        var responseJson = await response.json();
        console.log(responseJson)
        this.setState({
            Orders: responseJson,
            isLoading: false
        })
        console.log(this.state.Orders)
    }

    componentWillUnmount() {
        localStorage.removeItem('statePlace');
    }

    render() {
        const images = [
            {
                original: IMAGE_ENDPOINT + String(this.state.Orders.photo).split(';')[0],

                thumbnail: IMAGE_ENDPOINT + String(this.state.Orders.photo).split(';')[0],
            },
            {
                original: IMAGE_ENDPOINT + String(this.state.Orders.photo).split(';')[1],
                thumbnail: IMAGE_ENDPOINT + String(this.state.Orders.photo).split(';')[1],
            },
            {
                original: IMAGE_ENDPOINT + String(this.state.Orders.photo).split(';')[2],
                thumbnail: IMAGE_ENDPOINT + String(this.state.Orders.photo).split(';')[2],
            },
            ,
            {
                original: IMAGE_ENDPOINT + String(this.state.Orders.photo).split(';')[3],
                thumbnail: IMAGE_ENDPOINT + String(this.state.Orders.photo).split(';')[3],
            },

        ];
        const { data } = this.props;
        var points = [
            // { lat: this.state.Orders[1].lat, lng: this.state.Orders[1].lng },
            // { lat: 42.03, lng: -77.02 },
            // { lat: 41.03, lng: -77.04 },
            // { lat: 42.05, lng: -77.02 }
        ]

        const Image = IMAGE_ENDPOINT + String(this.state.Orders.photo).split(';')[0]
        const myLatLng = { lat: this.state.Orders.latitude, lng: this.state.Orders.longitude };
        //const longText = this.state.Orders.name[0].detail;

        if (this.state.isLoading == true) {
            return (
                <React.Fragment></React.Fragment>
            )
        }
        else {
            let publicUrl = process.env.PUBLIC_URL + '/'
            return (

                <React.Fragment>
                    <div className="page-content">
                        <Container fluid>
                            <Row>
                                <Col xs="12">
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Breadcrumbs title="Placedetail" breadcrumbItem={this.props.t('Place Detail')} />
                                        <Link to={{ pathname: './component/TablePlace/TableplaceDv.js' }}>
                                            <button type="button" className="btn btn-primary waves-effect waves-light" style={{ width: 200, background: 'transparent', borderColor: '#c2c2c2' }}>
                                                <span className="font-style-16 cl-gray"><i className="fa fa-angle-left"></i><span style={{ padding: '0px 0px 0px 10px' }}>{this.props.t('Back')}</span></span>
                                            </button>
                                        </Link>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                        <Container fluid style={{ marginTop: '1.5rem' }}>
                            <Row>
                                <Col xl={8}>
                                    <div>
                                        <ImageGallery items={images}
                                            thumbnailPosition={'left'}
                                            className="image-gallery-image"
                                            infinite={false}
                                        />
                                    </div>
                                    <Card style={{ marginTop: '1.5rem' }}>
                                        <CardBody>
                                            <div style={{ padding: 0 }}>
                                                <p className="font-style-24 cl-black">
                                                    {this.state.Orders.name}
                                                </p>
                                                <img className="" src={'/assets/images/icon/mark.svg'}></img>
                                                <span className="font-style-16 cl-blue" style={{ paddingLeft: '0.7rem' }}>{this.state.Orders.address}</span>
                                            </div>
                                            <hr></hr>
                                            {/* <Row style={{ margin: 0 }}>
                                                <Col xl={4}>
                                                    <Row>
                                                        <img src={'/assets/images/icon/food.svg'} alt="" style={{ width: 40 }}></img>
                                                        <div style={{ display: 'block', paddingLeft: 10 }}>
                                                            <h6 className="font-style-14 cl-gray" >ประเภท :</h6>
                                                            <h6 className="font-style-16 cl-black">ร้านอาหาร</h6>
                                                        </div>
                                                    </Row>
                                                </Col>
                                                <Col xl={4}>
                                                    <Row>
                                                        <img src={'/assets/images/icon/time.svg'} alt="" style={{ width: 40 }}></img>
                                                        <div style={{ display: 'block', paddingLeft: 10 }}>
                                                            <h6 className="font-style-14 cl-gray" >เวลาทำการ :</h6>
                                                            <span className="font-style-16" style={{ color: '#53e349', marginRight: 5 }}>เปิดจนถึง</span>
                                                            <span className="font-style-16 cl-black">21:00 น.</span>
                                                        </div>
                                                    </Row>
                                                </Col>
                                                <Col xl={4}>
                                                    <Row>
                                                        <img src={'/assets/images/icon/people.svg'} alt="" style={{ width: 40 }}></img>
                                                        <div style={{ display: 'block', paddingLeft: 10 }}>
                                                            <h6 className="font-style-14 cl-gray" >จำนวนนักท่องเที่ยว :</h6>
                                                            <h6 className="font-style-16 cl-black">10 - 15 คน</h6>
                                                        </div>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <hr></hr> */}
                                            
                                            {/* <div>
                                                <p className="font-style-16 cl-black" style={{ fontWeight: 'bold' }}>รายละเอียด</p>
                                                <p className="font-style-16 cl-black">{this.state.Orders.detail}</p>
                                            </div>
                                            <hr></hr>
                                            <div>
                                                <p className="font-style-16 cl-black" style={{ fontWeight: 'bold' }}>ข้อมูลติดต่อ</p>
                                            </div>
                                            <Row style={{ margin: 0 }}>
                                                <Col xl={6}>
                                                    <Row>
                                                        <img src={'/assets/images/icon/mark-gray.svg'} alt="" style={{ width: 25 }}></img>
                                                        <div style={{ display: 'block', paddingLeft: 10 }}>
                                                            <h6 className="font-style-16 cl-gray">ที่อยู่ :</h6>
                                                        </div>
                                                        <div style={{ paddingLeft: '2.2rem' }}>
                                                            <h6 className="font-style-16 cl-black">{this.state.Orders.address}</h6>
                                                        </div>
                                                    </Row>
                                                </Col>
                                                <Col xl={6}>
                                                    <Row>
                                                        <img src={'/assets/images/icon/phone.svg'} alt="" style={{ width: 25 }}></img>
                                                        <div style={{ display: 'block', paddingLeft: 10 }}>
                                                            <h6 className="font-style-16 cl-gray">เบอร์ติดต่อ :</h6>
                                                        </div>
                                                        <div style={{ paddingLeft: '2.2rem' }}>
                                                            <h6 className="font-style-16 cl-black">-</h6>
                                                        </div>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row style={{ margin: '30px 0px' }}>
                                                <Col xl={6}>
                                                    <Row>
                                                        <img src={'/assets/images/icon/email.svg'} alt="" style={{ width: 25 }}></img>
                                                        <div style={{ display: 'block', paddingLeft: 10 }}>
                                                            <h6 className="font-style-16 cl-gray">อีเมล์ :</h6>
                                                        </div>
                                                        <div style={{ paddingLeft: '2.2rem' }}>
                                                            <h6 className="font-style-16 cl-black">-</h6>
                                                        </div>
                                                    </Row>
                                                </Col>
                                                <Col xl={6}>
                                                    <Row>
                                                        <img src={'/assets/images/icon/website.svg'} alt="" style={{ width: 25 }}></img>
                                                        <div style={{ display: 'block', paddingLeft: 10 }}>
                                                            <h6 className="font-style-16 cl-gray">เว็บไซต์ :</h6>
                                                        </div>
                                                        <div style={{ paddingLeft: '2.2rem' }}>
                                                            <h6 className="font-style-16 cl-black">-</h6>
                                                        </div>
                                                    </Row>
                                                </Col>
                                            </Row> */}

                                            {/* <hr></hr>
                                            <div>
                                                <p className="font-style-16 cl-black" style={{ fontWeight: 'bold' }}>ตำแหน่งแผนที่</p>
                                            </div>
                                            <div
                                                id="gmaps-markers"
                                                className="gmaps"
                                                style={{ position: "relative" }}
                                            >
                                                <Map
                                                    google={this.props.google}
                                                    style={{ width: "100%", height: "100%" }}
                                                    zoom={15}
                                                    initialCenter={myLatLng}
                                                    hea
                                                >
                                                    <Marker
                                                        title={"The marker`s title will appear as a tooltip."}
                                                        name={this.state.Orders.name[0].name}
                                                        position={myLatLng}
                                                    />
                                                    <Marker name={this.state.Orders.name[0].name} />
                                                    <InfoWindow>
                                                        <div>
                                                            <h1>{this.state.selectedPlace.name}</h1>
                                                        </div>
                                                    </InfoWindow>
                                                </Map>
                                            </div> */}
                                        </CardBody>
                                    </Card>
                                </Col>
                                {/* <Col xl={4}>
                                    <div style={{ display: 'block' }}>
                                        <p className="font-style-16 cl-black">สถานที่อื่นๆ ที่คุณอาจชื่นชอบ</p>
                                        <hr className="hr-style-1"></hr>
                                    </div>
                                    <div style={{ display: 'block' }}>
                                        <p className="font-style-16 cl-black">สร้างแผนการท่องเที่ยว</p>
                                        <hr className="hr-style-1"></hr>
                                    </div>
                                    <div style={{ position: 'relative' }}>
                                        <img className="img-trip" src={'/assets/images/others/ttd.png'} alt=""></img>
                                        <Row style={{ display: 'flex', justifyContent: 'center' }}>
                                            <button type="button" className="btn btn-primary waves-effect waves-light" style={{ width: 200, background: 'rgba(0, 0, 0, 0.32)', borderRadius: 5, marginTop: '11rem' }}>
                                                <span className="font-style-16">{this.props.t('สร้างแผนการท่องเที่ยว')}</span>
                                            </button>
                                        </Row>
                                    </div>
                                </Col> */}
                            </Row>
                        </Container>
                    </div >

                    {/* <Card>
                                        <CardHeader className="gradient">
                                            <Row>
                                                <Col xl={10} lg={10} md={9} sm={12} xs={12} >
                                                    <Row >
                                                        <h2 className="MT" style={{ color: 'white' }}>
                                                            {this.state.Orders.name}
                                                        </h2>
                                                    </Row>
                                                </Col>
                                                <Col xl={2} lg={2} md={3} sm={12} xs={12} >
                                                    <Row >
                                                        <h2 className="MT" style={{ color: 'white' }}>
                                                            สถานที่แนะนำ
                                                        </h2>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row>

                                            </Row>
                                        </CardHeader>
                                    </Card> */}

                    {/* <Col lg={12}>
                                                            <CardBody style={{ borderBottom: 'solid 1px rgba(0, 0, 0, 0.1)' }} >
                                                                <Row>
                                                                    <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                                                                        <div className="mb-2" style={{ fontSize: 24 }}><i class="fas fa-utensils text-primary" ></i></div>
                                                                    </Col>
                                                                    <Col xl={10} lg={10} md={10} sm={10} xs={10}>
                                                                        <Row>
                                                                            <h3 className="mb-2" style={{ paddingLeft: '1rem' }}> ประเภทร้านอาหาร </h3>
                                                                        </Row>

                                                                    </Col>
                                                                </Row>
                                                            </CardBody>

                                                            <CardBody style={{ borderBottom: 'solid 1px rgba(0, 0, 0, 0.1)' }}>
                                                                <Row>
                                                                    <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                                                                        <div className=" mb-2" style={{ fontSize: 24 }}><i class="far fa-clock text-primary" ></i></div>
                                                                    </Col>
                                                                    <Col xl={10} lg={10} md={10} sm={10} xs={10}>
                                                                        <Row>
                                                                            <h3 className=" mb-2" style={{ color: 'green', paddingLeft: '1rem' }}> เปิดจนถึง </h3>
                                                                            <h3 className=" mb-2" style={{ paddingLeft: '5%' }}> 21:00 น. </h3>
                                                                        </Row>
                                                                    </Col>

                                                                </Row>
                                                            </CardBody>

                                                            <CardBody style={{ borderBottom: 'solid 1px rgba(0, 0, 0, 0.1)' }}>
                                                                <Row>
                                                                    <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                                                                        <div className=" mb-2" style={{ fontSize: 24 }}><i class="fas fa-user-friends text-primary" ></i></div>
                                                                    </Col>
                                                                    <Col xl={10} lg={10} md={10} sm={10} xs={10}>
                                                                        <Row>
                                                                            <h3 className=" mb-2" style={{ paddingLeft: '1rem' }}> 10 - 15 คน </h3>
                                                                        </Row>

                                                                    </Col>
                                                                </Row>
                                                            </CardBody>
                                                        </Col> */}



                    {/* <Row>
                                        <Col xl={12}>
                                            <Card style={{ borderTop: 'solid 10px #224AAC' }}>
                                                <CardBody>
                                                    <Row>
                                                        <Col xl={1} lg={1} md={1} sm={1} xs={2} style={{ textAlign: 'right' }}>
                                                            <div className="mb-2" style={{ fontSize: 24, fontWeight: 'bold' }}><i class="far fa-file-alt text-primary" ></i></div>
                                                        </Col>
                                                        <Col xl={2} lg={2} md={3} sm={4} xs={5} style={{ marginLeft: -10 }}>
                                                            <h3 className="mb-2" style={{ fontWeight: 'bold' }}> {this.props.t('Detail')}</h3>
                                                        </Col>
                                                        <Col xl={9} lg={9} md={8} sm={7} xs={5}>
                                                            <hr style={{ borderTop: '2px solid rgba(0, 0, 0, 0.1)' }}></hr>
                                                        </Col>
                                                        <Row>
                                                            <Col xl={1} lg={1} md={1} sm={1} xs={1}></Col>
                                                            <Col xl={10} lg={10} md={10} sm={10} xs={10}>
                                                                <h5 className="mb-2" style={{ textAlign: 'justify' }}>
                                                                    <ReactReadMoreReadLess
                                                                        charLimit={1000}
                                                                        readMoreText={
                                                                            <Row>
                                                                                <Col xl={12} lg={12} md={12} sm={12} xs={12} style={{ textAlign: 'center' }}>
                                                                                    Read more ▼
                                                                                </Col>
                                                                            </Row>}
                                                                        readLessText={
                                                                            <Row>
                                                                                <Col xl={12} lg={12} md={12} sm={12} xs={12} style={{ textAlign: 'center' }}>
                                                                                    Read less ▲
                                                                                </Col>
                                                                            </Row>}
                                                                        readMoreClassName="read-more-less--more"
                                                                        readLessClassName="read-more-less--less"
                                                                    >
                                                                        {this.state.Orders.detail}
                                                                    </ReactReadMoreReadLess>
                                                                </h5>
                                                            </Col>
                                                            <Col xl={1} lg={1} md={1} sm={1} xs={1}></Col>
                                                        </Row>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>

                                    <Row style={{ marginTop: 5 }}>
                                        <Col xl={6}>
                                            <Card style={{ borderTop: 'solid 10px #224AAC', height: '100%' }}>
                                                <CardBody>
                                                    <Row>
                                                        <Col xl={5} lg={3} md={3} sm={4} xs={7}>
                                                            <h3 className=" mb-2" style={{ fontSize: 24, fontWeight: 'bold' }}> {this.props.t('Contact Information')}</h3>
                                                        </Col>
                                                        <Col xl={7} lg={9} md={9} sm={8} xs={5}>
                                                            <hr style={{ borderTop: '2px solid rgba(0, 0, 0, 0.1)' }}></hr>
                                                        </Col>
                                                    </Row>
                                                    <Row style={{ paddingTop: '3vh' }}>
                                                        <Col xl={1} lg={1} md={1} sm={1} xs={1}></Col>
                                                        <Col xl={1} lg={1} md={1} sm={1} xs={1}>
                                                            <div className=" mb-2" style={{ fontSize: 20 }}><i class="fas fa-map-marked-alt text-primary" ></i></div>
                                                        </Col>
                                                        <Col xl={10} lg={8} md={10} sm={10} xs={10}>
                                                            <h4 className=" mb-2" >  {this.state.Orders.address}</h4>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xl={1} lg={1} md={1} sm={1} xs={1}>
                                                        </Col>
                                                        <Col xl={10} lg={10} md={10} sm={10} xs={10} style={{ borderBottom: 'solid 1px rgba(0, 0, 0, 0.1)' }}>
                                                        </Col>
                                                        <Col xl={1} lg={1} md={1} sm={1} xs={1}>
                                                        </Col>
                                                    </Row>
                                                    <Row style={{ paddingTop: '2vh', paddingBottom: '2vh' }}>
                                                        <Col xl={1} lg={1} md={1} sm={1} xs={1}></Col>
                                                        <Col xl={1} lg={1} md={1} sm={1} xs={1}>
                                                            <div className=" mb-2" style={{ fontSize: 20 }}><i class="fas fa-phone-alt text-primary" ></i></div>
                                                        </Col>
                                                        <Col xl={10} lg={10} md={10} sm={10} xs={10} >
                                                            <Row>
                                                                <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                                                                    <div className=" mb-2" style={{ fontSize: 20 }}></div>
                                                                </Col>
                                                                <Col xl={4} lg={4} md={4} sm={4} xs={4}>
                                                                    <Button style={{ width: '100%', backgroundColor: '#E7E7E7', borderColor: '#AAAAAA', }}><a style={{ color: '#8C8C8C' }}>โทร</a></Button>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xl={1} lg={1} md={1} sm={1} xs={1}>
                                                        </Col>
                                                        <Col xl={10} lg={10} md={10} sm={10} xs={10} style={{ borderBottom: 'solid 1px rgba(0, 0, 0, 0.1)' }}>
                                                        </Col>
                                                        <Col xl={1} lg={1} md={1} sm={1} xs={1}>
                                                        </Col>
                                                    </Row>
                                                    <Row style={{ paddingTop: '5vh' }}>
                                                        <Col xl={4} lg={4} md={4} sm={4} xs={12} style={{ textAlign: 'center', borderRight: 'solid 1px rgba(0, 0, 0, 0.1)' }}>
                                                            <img src={publicUrl + "images/shop.png"} alt="" style={{ width: '100%', height: 'auto', maxWidth: 48 }} />
                                                            <h4 className=" mb-2" style={{ marginTop: 10 }}>RodDeeDed BankKhunShop</h4>
                                                        </Col>
                                                        <Col xl={4} lg={4} md={4} sm={4} xs={12} style={{ textAlign: 'center', borderRight: 'solid 1px rgba(0, 0, 0, 0.1)' }}>
                                                            <img src={publicUrl + "images/instagram.png"} alt="" style={{ width: '100%', height: 'auto', maxWidth: 48 }} />
                                                            <h4 className=" mb-2" style={{ marginTop: 10 }}>@RodDeeDed</h4>
                                                        </Col>
                                                        <Col xl={4} lg={4} md={4} sm={4} xs={12} style={{ textAlign: 'center' }}>
                                                            <img src={publicUrl + "images/facebook.png"} alt="" style={{ width: '100%', height: 'auto', maxWidth: 48 }} />
                                                            <h4 className=" mb-2" style={{ marginTop: 10 }}>RodDeeDed</h4>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col xl={6}>
                                            <Card style={{ borderTop: 'solid 10px #224AAC', height: '100%' }}>
                                                <CardBody>
                                                    <Row>
                                                        <Col xl={3} lg={3} md={3} sm={4} xs={7}>
                                                            <h3 className=" mb-2" style={{ fontSize: 24, fontWeight: 'bold' }}>{this.props.t('Map')}</h3>
                                                        </Col>
                                                        <Col xl={9} lg={9} md={9} sm={8} xs={5}>
                                                            <hr style={{ borderTop: '2px solid rgba(0, 0, 0, 0.1)' }}></hr>
                                                        </Col>
                                                    </Row>

                                                    <div
                                                        id="gmaps-markers"
                                                        className="gmaps"
                                                        style={{ position: "relative" }}
                                                    >
                                                        <Map
                                                            google={this.props.google}
                                                            style={{ width: "100%", height: "100%" }}
                                                            zoom={15}
                                                            initialCenter={myLatLng}
                                                            hea
                                                        >
                                                            <Marker
                                                                title={"The marker`s title will appear as a tooltip."}
                                                                name={this.state.Orders.name[0].name}
                                                                position={myLatLng}
                                                            />
                                                            <Marker name={this.state.Orders.name[0].name} />
                                                            <InfoWindow>
                                                                <div>
                                                                    <h1>{this.state.selectedPlace.name}</h1>
                                                                </div>
                                                            </InfoWindow>
                                                        </Map>
                                                    </div>
                                                </CardBody>
                                            </Card>

                                        </Col>
                                    </Row> */}
                    {/* <Row>
                                        <Col xl={8} lg={8} md={6} sm={4} xs={12}></Col>
                                        <Col xl={2} lg={2} md={3} sm={4} xs={12} style={{ textAlign: "right" }}>
                                            <Link to={'/Tableplacedv'}>
                                                <Button

                                                    color="primary"
                                                    className="mt-3"
                                                    style={{ width: "100%" }}
                                                    block>
                                                    บันทึก
                                                </Button>
                                            </Link>
                                        </Col>
                                        <Col xl={2} lg={2} md={3} sm={4} xs={12}>
                                            <Link to={'/Tableplacedv'}>
                                                <Button color="secondary"
                                                    className="mt-3"
                                                    style={{ width: "100%" }}
                                                    block
                                                >ยกเลิก </Button>
                                            </Link>
                                        </Col>
                                    </Row> */}

                </React.Fragment >
            );
        }

    }
}
export default connect(
    null,
    {}
)(
    GoogleApiWrapper({
        apiKey: "AIzaSyD0AIarEA25PY-NvHNsZDAVqM3qa8krHfs",
        libraries: ["visualization"],
        LoadingContainer: LoadingContainer,
        v: "3"
    })(withTranslation()(Placedetail))
);

// export default Placedetail;
























